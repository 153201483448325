import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT, { IObjectResult } from "../endpoint";
import {IOrderArtifact} from "../../models/orderArtifact";
import { IArtifact } from "newStandard/src/features/Editor/services/artifact/types";

function ArtifactService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/orderArtifacts`

    function uploadOrderArtifact(orderId: number, formData: FormData){
        return execute({
            endpoint: `${API_PATH}/${orderId}`,
            method: "POST",
            formData: formData
        })
    }

    function getOrderArtifacts(orderId: number){
        return execute({
            endpoint: `${API_PATH}/${orderId}`,
            method: "GET"
        })
    }

    function getPastOrderArtifacts(){
        return execute({
            endpoint: `${API_ENDPOINT}/pastOrderArtifacts`,
            method: "GET"
        })
    }

    function removeArtifact(orderId: number, artifactId: number){
        return execute({
            endpoint: `${API_PATH}/${orderId}/${artifactId}`,
            method: "DELETE"
        })
    }

    function generateQrCode(orderId: number, qrUrl: string){
        const formData = new FormData();
        formData.append("qrCodeUrl", qrUrl);

        return execute({
            endpoint: `${API_PATH}/generateQrCode/${orderId}`,
            method: "POST",
            formData: formData
        })
    }

    function updateArtifactType(orderArtifact: IOrderArtifact){
        return execute({
            endpoint: `${API_ENDPOINT}/orderartifacts-update-type`,
            method: "POST",
            data: orderArtifact
        })
    }

    function getOrderProofs(orderId: number): Promise<IObjectResult<IArtifact[]>> {
        return execute({
            endpoint: `${API_ENDPOINT}/orderproofs/${orderId}`,
            method: "GET"
        })
    }

    function copyOrderArtifact(orderId: number, artifactUri: string){
        const formData = new FormData();
        formData.append("artifactUri", artifactUri);
        return execute({
            endpoint: `${API_ENDPOINT}/order/${orderId}/orderartifacts:copy`,
            method: "POST",
            formData: formData
        })
    }

    return {removeArtifact, generateQrCode, uploadOrderArtifact, updateArtifactType, getOrderProofs, getOrderArtifacts, getPastOrderArtifacts, copyOrderArtifact}
}

export default ArtifactService