import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import ReviewProofs from 'newStandard/src/components/ReviewProofs';
import MDButton from 'material-ui/components/MDButton';
import MDCard from 'material-ui/components/MDCard';
import { useGlobal } from 'context/global-context';

export default function ReviewStep() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { template, changeStep } = useWorkflowContext();
  const [isProofApproved, setIsProofApproved] = useState<boolean>(false);
  const { navigationCallback, setNavigationCallback, templateFlow, setTemplateFlow } = useGlobal();

  const handleSave = () => {
    queryClient.invalidateQueries({ queryKey: ['templates'] });
    if (templateFlow.originalRoute) {
      setTemplateFlow({ ...templateFlow, order: { ...templateFlow.order, template } });
      navigate(templateFlow.originalRoute);
    } else if (navigationCallback) {
      navigationCallback();
      setNavigationCallback(undefined);
    } else navigate(template.isIntegrationOrderTemplate ? '/integrations/integration-orders' : '/my-templates');
  };

  return (
    <MDCard sx={{ p: 2 }}>
      <Stack spacing={2}>
        <ReviewProofs isProofApproved={isProofApproved} setIsProofApproved={setIsProofApproved} />
        <Box display={'flex'} justifyContent={'space-between'} mt={2}>
          <MDButton color="light" onClick={() => changeStep(2)} sx={{ width: 100 }}>
            Back
          </MDButton>
          <MDButton disabled={!isProofApproved} sx={{ width: 100 }} onClick={handleSave}>
            Save
          </MDButton>
        </Box>
      </Stack>
    </MDCard>
  );
}
