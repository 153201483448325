import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";
import {ProfileConfigurationType, ProfileDataGridConfiguration} from "models/profileConfiguration";

function ProfileConfigurationService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/profiles/configurations`

    function getProfileConfiguration(config: ProfileConfigurationType){
        return execute({
            endpoint: `${API_PATH}/${config}`,
            method: "GET"
        })
    }

    function postProfileConfiguration(config: ProfileDataGridConfiguration | ProfileDataGridConfiguration[]){
        return execute({
            endpoint: `${API_PATH}`,
            method: "POST",
            data: config
        })
    }

    return {getProfileConfiguration, postProfileConfiguration}
}

export default ProfileConfigurationService