import {SelectFrom} from "models/order-creation-flow/select-from";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "material-ui/components/MDButton";
import {CircularProgress, DialogContent, Stack} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import PremadeDesignService from "services/premade-design";
import PremadeDesignsBlock from "components/premade-designs-block";
import {PremadeDesign} from "models/premadeDesign";
import React, {useEffect, useState} from "react";
import MDBox from "material-ui/components/MDBox";
import { DesignCategory } from "models/designCategory";

interface NewOrderStep2ModalProps {
    show: boolean
    setShow: Function
    selectFrom: SelectFrom
    onNext: Function
    onBack: Function
    selectedPremadeDesign: PremadeDesign
    setSelectedPremadeDesign: Function
    onClose: Function
}

function NewOrderStep2PremadeDesignsModal({show, setShow, selectFrom, onNext, onBack, selectedPremadeDesign, setSelectedPremadeDesign, onClose}: NewOrderStep2ModalProps) {
    const {getPremadeDesigns} = PremadeDesignService()

    const [premadeDesigns, setPremadeDesigns] = useState<{
        allCategories: string[];
        designCategories: DesignCategory[];
    } | null>(null)

    const responsive = {
        all: {
            breakpoint: {max: 10000, min: 0},
            items: 5
        }
    };

    useEffect(() => {
        if(show){
            getPremadeDesigns().then((result) => {
                setPremadeDesigns(result)
            })
        }
        else{
            setPremadeDesigns(null)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return <Dialog open={show} onClose={() => onClose()} maxWidth={false}>
        <DialogContent sx={{width: "1000px"}}>
            <DialogTitle>
                {selectFrom === SelectFrom.MyTemplates && "Select one of your templates"}
                {selectFrom === SelectFrom.PremadeDesigns && "Select from our Free Card Designs"}
            </DialogTitle>

            {premadeDesigns?
                <PremadeDesignsBlock
                    data={premadeDesigns}
                    selectedPremadeDesign={selectedPremadeDesign}
                    designOnClick={(design: PremadeDesign) => {
                        setSelectedPremadeDesign(design)
                    }}
                    containerHeight={"72vh"}
                    responsiveSteps={responsive}
                />
                :
                <MDBox display={"flex"} justifyContent={"center"} width={"100%"}>
                    <CircularProgress color="primary"/>
                </MDBox>
            }

            <Stack
                direction="row"
                spacing={1}
                marginTop={2}
                justifyContent="flex-end"
            >
                <MDButton
                    color={"light"}
                    circular={true}
                    onClick={() => onBack()}
                >
                    Back
                </MDButton>

                <MDButton
                    onClick={() => onNext()}
                    circular={true}
                    disabled={!selectedPremadeDesign}
                >
                    Next
                </MDButton>
            </Stack>
        </DialogContent>
    </Dialog>
}

export default NewOrderStep2PremadeDesignsModal