import {useState} from "react";
import {Divider, Grid, Tooltip, Typography} from "@mui/material";
import {GridCellModesModel, GridColDef, useGridApiRef} from "@mui/x-data-grid-pro";
import {GridRowId} from '@mui/x-data-grid';
import {formatNumber, formatPrice} from "helpers/formatters";
import MDBox from "material-ui/components/MDBox";
import StyledDataGrid from "components/styled-data-grid";
import {useQuery} from "@tanstack/react-query";
import MDCard from "material-ui/components/MDCard";
import HomeSearchIcon from "assets/icons/home-search";
import EyeIcon from "assets/icons/eye";
import InfoModal from "components/info-modal";
import {GoogleMap, LoadScript, OverlayView, Polygon as MapsPolygon} from "@react-google-maps/api";
import colors from "material-ui/theme/base/colors";
import {getCenterOfPolygon} from "helpers/google-map-helper";
import {Coordinate} from "models/coordinate";
import MDTypography from "material-ui/components/MDTypography";
import CardsToSendIcon from "assets/icons/cards-to-send";
import MDIconButton from "material-ui/components/MDIconButton";
import AdminService from "services/admin";
import { HailTraceMap } from "models/hail-trace/ServiceAreaRecipientSearch";
import EditPencilIcon from "assets/icons/edit-pencil";
import {useNavigate} from "react-router-dom";
import MapIcon from '@mui/icons-material/Map';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import Info2Icon from "assets/icons/info-2";
import HailSizeIcon from "assets/icons/hail-size";
import { useGlobal } from "context/global-context";

interface HailTraceMapDetailProps {
    row: HailTraceMap
}

function HailTraceMapDetail({ row }: HailTraceMapDetailProps) {
    const navigate = useNavigate()
    const [hailTraceMap] = useState<HailTraceMap>(JSON.parse(JSON.stringify(row)))

    const [hasMapLoaded, setHasMapLoaded] = useState<boolean>(false)
    const [center, setCenter] = useState<Coordinate>()

    const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

      const mapOptions: google.maps.MapOptions = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeId: 'hybrid',
        disableDefaultUI: true
    }

    const mapContainerStyle = {
        width: '640px',
        height: '347px',
        borderRadius: "14px"
    }

    const recipientSearchColumns: GridColDef[] = [
        {
            headerName: "Name",
            field: "name",
            flex: 1
        },
        {
            headerName: "Customer Email",
            field: "customerEmail",
            width: 300,
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: "Status",
            field: "status",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: "Total Cards to Send",
            field: "totalDesiredQuantity",
            flex: 1,
            width: 200,
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => {
                return params.value ? formatNumber(params.value) : "-"
            }
        },
        // {
        //     headerName: "Total Available Cards",
        //     field: "totalAvailableQuantity",
        //     flex: 1,
        //     valueFormatter: (params) => {
        //         return params.value ? formatNumber(params.value) : "-"
        //     }
        // },
        {
            headerName: "Template",
            field: "template",
            width: 300,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => {
                return params.value.product
            }
        },
        {
            headerName: "Total Price",
            field: "totalPrice",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => {
                return params.value ? formatPrice(params.value) : "-"
            }
        }, 
        {
            type: "actions",
            field: "actions",
            align: "center",
            width: 50,
            getActions: (params) => {
                let buttons = []

                buttons.push(
                    <Tooltip title={"Download Original IntersectionMap"}>
                        <MDBox display={"flex"} onClick={async () => {
                              try {
                                const response = await fetch(params.row.mapUri);
                                if (response.ok) {
                                    const blob = await response.blob();
                                    const downloadUrl = window.URL.createObjectURL(new Blob([blob], {type: 'application/octet-stream'}));
                                    const link = document.createElement('a');
                                    link.href = downloadUrl;
                                    link.download = `${params.row.name}.html` || 'CustomerHailStormMap.html';
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                    window.URL.revokeObjectURL(downloadUrl);
                                } else {
                                    console.error('Failed to download the file.');
                                }
                            } catch (error) {
                                console.error('Error fetching the file: ', error);
                            }
                        }}>
                            <MapIcon color={"secondary"}/>
                        </MDBox>
                    </Tooltip>)
                      
                buttons.push(
                    <Tooltip title={"See Details"}>
                        <MDBox display={"flex"} onClick={() => {
                            navigate(`/admin/manage-storm-automation/${params.row.id}`)
                        }}>
                            <EditPencilIcon color={"secondary"}/>
                        </MDBox>
                    </Tooltip>
                )

                return buttons
            }
        }
    ]

    function onMapLoaded() {
        setHasMapLoaded(true)

        // Sets the center of the map to be the center of the first recipient search entry
        if (hailTraceMap.polygons.length) {
            let centerObject = hailTraceMap.polygons[0]

            setCenter(getCenterOfPolygon(centerObject.coordinates))
        }
    }

    return <MDBox>
        <MDBox p={3}>
            <MDCard border={true} borderColor={"dark"} boxShadow={false} borderRadiusSize={"xl"}>
                <MDBox p={2}>
                    <Grid container alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item flex={1}>
                            <Grid container alignItems={"center"} gap={"10px"}>
                                <Grid item>
                                    <Typography fontWeight={"bold"}>Date:</Typography>
                                </Grid>

                                <Grid item>
                                    <Grid container alignItems={"center"}>
                                        <Grid item>
                                            <Typography fontWeight={"bold"}>{hailTraceMap.stormDateDisplayValue}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider light={true} />

                    <Grid container gap={6} flexDirection={"row"} flexWrap={"nowrap"}>
                        <Grid item minWidth={"350px"}>
                            <MDBox mt={2}>
                            <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HailSizeIcon />
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{ fontSize: 16 }} fontWeight={"bold"}>Hail Trace Map Id: </Typography>
                                            </Grid>
                                            
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{ fontSize: 16 }} fontWeight={"normal"}>
                                            {hailTraceMap.id}
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Tooltip title={"Hail Trace Map Id"}>
                                            <MDBox display={"flex"}>
                                                <Info2Icon color={"light2"}/>
                                            </MDBox>
                                        </Tooltip>
                                    </Grid>
                              </Grid>
                              <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <EyeIcon />
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{ fontSize: 16 }} fontWeight={"bold"}>Impacted Clients: </Typography>
                                            </Grid>
                                            
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{ fontSize: 16 }} fontWeight={"normal"}>
                                            {hailTraceMap.numberOfImpactedClients ? formatNumber(hailTraceMap.numberOfImpactedClients) : "N/A"}
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Tooltip title={"Number of clients with service areas that overlap this storm's coordinates"}>
                                            <MDBox display={"flex"}>
                                                <Info2Icon color={"light2"}/>
                                            </MDBox>
                                        </Tooltip>
                                    </Grid>
                              </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HomeSearchIcon />
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{ fontSize: 16 }} fontWeight={"bold"}>Impacted Structures: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{ fontSize: 16 }} fontWeight={"normal"}>
                                            {hailTraceMap.numberOfImpactedStructures ? formatNumber(hailTraceMap.numberOfImpactedStructures) : "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={"Number of estimated structures affected by the storm. Returned by HailTrace API, only available when there are Meterologist validated results"}>
                                            <MDBox display={"flex"}>
                                                <Info2Icon color={"light2"}/>
                                            </MDBox>
                                        </Tooltip>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <CardsToSendIcon />
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{ fontSize: 16 }} fontWeight={"bold"}>
                                                    Outgoing Cards:
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{ fontSize: 16 }} fontWeight={"normal"}>
                                            {hailTraceMap.numberOfOutgoingCards ? formatNumber(hailTraceMap.numberOfOutgoingCards) : "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={"Number of total cards clients want sent for this storm. Includes sent orders and orders yet to be approved"}>
                                            <MDBox display={"flex"}>
                                                <Info2Icon color={"light2"}/>
                                            </MDBox>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </Grid>



                        <Grid item>
                            <LoadScript googleMapsApiKey="AIzaSyAnZBcPxYjFTJ2p5VykxLnOcqM0gY4uIY0" onLoad={onMapLoaded}>
                                {hasMapLoaded &&
                                    <GoogleMap
                                        mapContainerStyle={mapContainerStyle}
                                        options={mapOptions}
                                        center={center}
                                        zoom={10}
                                    >

                                        {hailTraceMap.polygons.map((polygon, index) => {
                                            return <MDBox key={index}>
                                                <OverlayView
                                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                    getPixelPositionOffset={(width, height) => ({
                                                        x: -(width / 2),
                                                        y: -(height / 2),
                                                    })}
                                                    position={getCenterOfPolygon(polygon.coordinates)}
                                                >
                                                    <Typography
                                                        whiteSpace={"nowrap"}
                                                        fontWeight={"bold"}
                                                        textAlign={"center"}
                                                        color={colors.white.main}
                                                        sx={{ textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black" }}
                                                    >
                                                        {polygon.name}
                                                    </Typography>
                                                </OverlayView>

                                                <MapsPolygon
                                                    key={polygon.id}
                                                    draggable={false}
                                                    editable={false}
                                                    path={polygon.coordinates}
                                                    options={{
                                                        fillColor: colors.info.main,
                                                        fillOpacity: 0.40,
                                                        strokeColor: colors.info.main,
                                                        strokeOpacity: 1,
                                                        strokeWeight: 3
                                                    }}
                                                />
                                            </MDBox>
                                        })}
                                    </GoogleMap>
                                }
                            </LoadScript>
                        </Grid>



                        {/* <Grid item maxWidth={"650px"}>
                            {hailTraceMap.template?
                                <Grid container gap={2} flexWrap={"nowrap"} sx={{maxWidth: "1000px"}}>
                                    <Grid item flex={1}>
                                        <MDTypography mb={1} fontWeight={"bold"} color={"secondary"} sx={{fontSize: 18}}>Card Front</MDTypography>

                                        <MDCard borderRadiusSize={"md"} boxShadow={false} border={true}>
                                            <img alt={""} style={{width: "100%", height: "100%"}} src={hailTraceMap.template.sceneFrontFull}/>
                                        </MDCard>
                                    </Grid>

                                    <Grid item flex={1}>
                                        <MDTypography mb={1} fontWeight={"bold"} color={"secondary"} sx={{fontSize: 18}}>Card Rear</MDTypography>

                                        <MDCard borderRadiusSize={"md"} boxShadow={false} border={true}>
                                            <img alt={""} style={{width: "100%", height: "100%"}} src={hailTraceMap.template.sceneBackFull}/>
                                        </MDCard>
                                    </Grid>
                                </Grid>
                                :
                                null
                            }
                        </Grid> */}
                    </Grid>

                    <MDBox mt={4}>
                    {hailTraceMap.recipientSearches && hailTraceMap.recipientSearches.length > 0
                        ?
                            <StyledDataGrid
                            rows={[...hailTraceMap.recipientSearches ?? []]}
                            columns={recipientSearchColumns}
                            disableRowSelectionOnClick={true}
                            pagination={false}
                            hideFooter={true}
                            noMinWidth={true}
                            cellModesModel={cellModesModel}
                            onCellModesModelChange={(model) => setCellModesModel(model)}
                            />
                        :
                            <Typography fontWeight={"bold"}>No overlapping customer service areas</Typography>
                    }
                    </MDBox>
                </MDBox>
            </MDCard>
        </MDBox>
    </MDBox>
}

function StormAutomationHistoryPage() {
    const { setShowLoader } = useGlobal()
    const { getHailTraceMaps, refreshHailTraceMap } = AdminService()

    const getHailTraceMapsQuery = useQuery({
        queryFn: () => {
            return getHailTraceMaps()
        },
        queryKey: ["hailTraceMaps"],
        refetchOnWindowFocus: false,
        refetchInterval: 30000
    })
 
    let apiRef = useGridApiRef()

    const [expandedRowId, setExpandedRowId] = useState<GridRowId | null>(null)

    const [showRefreshStormModal, setRefreshStormModal] = useState<boolean>(false)
    const [stormToRefresh, setStormToRefresh] = useState<HailTraceMap | null>(null)
    const [showStormRefreshedModal, setStormRefreshedModal] = useState<boolean>(false)

    const hailTraceMapColumns: GridColDef[] = [
        {
            headerName: "Date",
            field: "stormDateDisplayValue",
            flex: 1,
            minWidth: 50,
            align: "left",
            headerAlign: "left"
        },
        {
            headerName: "Impacted Clients",
            field: "numberOfImpactedClients",
            align: "center",
            headerAlign: "center",
            width: 300,
            valueFormatter: (params) => {
                return params.value > 0 ? formatNumber(params.value) : "-"
            }
        },
        {
            headerName: "Impacted Structures",
            field: "numberOfImpactedStructures",
            align: "center",
            headerAlign: "center",
            width: 300,
            valueFormatter: (params) => {
                return params.value > 0 ? formatNumber(params.value) : "-"
            }
        },
        {
            headerName: "Outgoing Cards",
            field: "numberOfOutgoingCards",
            align: "center",
            headerAlign: "center",
            width: 300,
            valueFormatter: (params) => {
                return params.value > 0 ? formatNumber(params.value) : "-"
            }
        }, 
        {
            headerName: "Total Price",
            field: "totalPrice",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => {
                return params.value ? formatPrice(params.value) : "-"
            }
        }, 
        {
            type: "actions",
            field: "actions",
            align: "right",
            flex: 1,
            minWidth: 200,
            getActions: (params: any) => {
                let buttons = []

                buttons.push(
                    <Tooltip title={"Refresh Storm"}>
                        <MDIconButton borderRadiusSize={"xxl"} sx={{ padding: "5px", width: "40px", height: "30px", minWidth: "unset" }} backgroundColor={"light"} onClick={() => {
                            setStormToRefresh(params.row)
                            setRefreshStormModal(true)
                        }}>
                            <RefreshIcon />
                        </MDIconButton>
                    </Tooltip>
                    )

                return buttons
            }
        }
    ]

    function refreshStorm(){
        setShowLoader(true)
        refreshHailTraceMap(stormToRefresh!).then((response) => {
            setShowLoader(false)
            setStormRefreshedModal(true)
            setExpandedRowId(null)
        })
    }

    return <MDCard>
        <MDBox p={3} pl={4} pb={4}>
            <MDTypography fontWeight={"bold"} sx={{ fontSize: "26px" }}>History</MDTypography>

            <MDBox height={"80vh"} mt={3} px={3}>
                <StyledDataGrid
                    apiRef={apiRef}
                    disableBorders={true}
                    backgroundColor={"white"}
                    loading={getHailTraceMapsQuery.isLoading}
                    rows={getHailTraceMapsQuery.data?.payload as HailTraceMap[] ?? []}
                    columns={hailTraceMapColumns}
                    disableRowSelectionOnClick={true}
                    getDetailPanelHeight={() => "auto"}
                    detailPanelExpandedRowIds={expandedRowId ? [expandedRowId] : []}
                    onDetailPanelExpandedRowIdsChange={(ids) => {
                        if (!ids) {
                            setExpandedRowId(null)
                            return
                        }

                        // Only allow the user to expand one row at a time since the google api only allows 1 map shown at a time
                        setExpandedRowId(ids[ids.length - 1])
                    }}
                    getDetailPanelContent={(row) => {
                        return <HailTraceMapDetail row={row.row as HailTraceMap} />
                    }}
                    pagination={false}
                    hideFooter={true}
                />
            </MDBox>
        </MDBox>

        <InfoModal
            show={showRefreshStormModal}
            setShow={setRefreshStormModal}
            headerText={"Are you sure you want to refresh this storm? New intersections with service areas will be created."}
            showConfirmButton={true}
            showCancelButton={true}
            cancelButtonOnClick={() => {
                setRefreshStormModal(false)
            }}
            confirmButtonOnClick={() => {
                refreshStorm()
                setRefreshStormModal(false)
            }}
        />

        <InfoModal
            show={showStormRefreshedModal}
            setShow={setStormRefreshedModal}
            headerText={"Storm refreshed successfully. Please wait until we process all service areas. Come back in a few minutes."}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setStormRefreshedModal(false)
            }}
          
        />
    </MDCard>
}

export default StormAutomationHistoryPage