import useFetchWithMsal from 'hooks/useFetch';
import { IAllDiscountCodes, ICoupon, ICouponToOrder, IMultiUseCoupon, ISingleUseList } from './discount-codes.types';
import { mockAllDiscountCodes, mockCoupons } from './discount-codes.mocks';
import API_ENDPOINT, { IObjectResult, mockedObjectResult } from '../endpoint';

const useDiscountCodesService = () => {
  const { execute } = useFetchWithMsal();
  const API_PATH = `${API_ENDPOINT}/discount-codes`;
  const shouldMock = process.env.REACT_APP_MOCKS_ON === 'true';

  const getAllDiscountCodes = (includeExpired: boolean): Promise<IObjectResult<IAllDiscountCodes>> => {
    if (shouldMock) return new Promise((resolve) => resolve({ payload: mockAllDiscountCodes }));
    return execute({ endpoint: `${API_PATH}?includeExpired=${includeExpired}`, method: 'GET' });
  };

  const getCouponByList = (listId: number): Promise<IObjectResult<ICoupon[]>> => {
    if (shouldMock) return new Promise((resolve) => resolve({ payload: mockCoupons }));
    return execute({ endpoint: `${API_PATH}/lists/${listId}/coupons`, method: 'GET' });
  };

  const createMultiUseCoupon = (multiUseCoupon: IMultiUseCoupon) => {
    if (shouldMock) return new Promise((resolve) => resolve(null));
    return execute({ endpoint: `${API_PATH}/multi-use-coupons`, method: 'POST', data: multiUseCoupon });
  };

  const createSingleUseCoupon = (listData: ISingleUseList): Promise<IObjectResult<ISingleUseList>> => {
    if (shouldMock) return new Promise((resolve) => resolve({ payload: { id: 1, name: 'mockName' } }));
    return execute({ endpoint: `${API_PATH}/lists`, method: 'POST', data: listData });
  };

  const updateMultiUseCoupon = (multiUseCoupon: IMultiUseCoupon) => {
    if (shouldMock) return new Promise((resolve) => resolve(null));
    return execute({
      endpoint: `${API_PATH}/multi-use-coupons/${multiUseCoupon.id}`,
      method: 'PUT',
      data: multiUseCoupon,
    });
  };

  const updateSingleUseList = (list: ISingleUseList) => {
    if (shouldMock) return new Promise((resolve) => resolve(null));
    return execute({ endpoint: `${API_PATH}/lists/${list.id}`, method: 'PUT', data: list });
  };

  const assignCouponToOrder = (couponToOrder: ICouponToOrder): Promise<IObjectResult<{}>> => {
    if (shouldMock) return new Promise((resolve) => resolve(mockedObjectResult({})));
    return execute({ endpoint: `${API_PATH}/assign-coupon-to-order`, method: 'PUT', data: couponToOrder });
  };

  const deleteMultiUseCoupon = (id: number) => {
    if (shouldMock) return new Promise((resolve) => resolve(null));
    return execute({ endpoint: `${API_PATH}/multi-use-coupons/${id}`, method: 'DELETE' });
  };

  const deleteSingleUseCoupon = (id: number) => {
    if (shouldMock) return new Promise((resolve) => resolve(null));
    return execute({ endpoint: `${API_PATH}/lists/${id}`, method: 'DELETE' });
  };

  const deleteCouponsFromSingleUseList = ({ listId, couponIds }: { listId: number; couponIds: number[] }) => {
    if (shouldMock) return new Promise((resolve) => resolve(null));
    return execute({ endpoint: `${API_PATH}/lists/${listId}/coupons`, method: 'DELETE', data: { couponIds } });
  };

  return {
    getAllDiscountCodes,
    getCouponByList,
    createMultiUseCoupon,
    createSingleUseCoupon,
    updateMultiUseCoupon,
    updateSingleUseList,
    assignCouponToOrder,
    deleteMultiUseCoupon,
    deleteSingleUseCoupon,
    deleteCouponsFromSingleUseList,
  };
};

export default useDiscountCodesService;
