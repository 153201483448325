import { ReactNode, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { useQuery } from '@tanstack/react-query';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Box } from '@mui/material';

import { IOrder, ProductionSpeed } from 'newStandard/src/services/order/types';
import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { PostageTypes } from 'models/enums/PostageTypes';
import MDCard from 'material-ui/components/MDCard';
import OrderService from 'services/order';

interface CheckoutWrapperProps {
  children?: ReactNode;
}

export default function CheckoutWrapper({ children }: CheckoutWrapperProps) {
  const { getOrderCheckout } = OrderService();
  const { template, step, save } = useWorkflowContext<IOrder>();
  const [stripe, setStripe] = useState<Stripe | null>(null);

  const { data: { payload: checkout } = {}, refetch } = useQuery({
    queryKey: ['checkout', 'order', template.id],
    queryFn: () => getOrderCheckout(template.id),
    enabled: false,
  });

  useEffect(() => {
    const loadStripeInstance = async () => {
      const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '');
      setStripe(stripeInstance);
    };

    loadStripeInstance();
  }, []);

  useEffect(() => {
    if (Number(step) !== 4) return;
    if (template.productionSpeed && template.postageType) return save(template, refetch);
    const updatedOrder = {
      ...template,
      productionSpeed: template.productionSpeed ?? ProductionSpeed.Normal,
      postageType: template.postageType ?? PostageTypes.Standard,
    };
    save(updatedOrder, refetch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  if (!checkout?.stripeToken) {
    return (
      <MDCard sx={{ p: 2 }}>
        <Box mx="auto" maxWidth={1300} width={'100%'}>
          {children}
        </Box>
      </MDCard>
    );
  }

  return (
    <MDCard sx={{ p: 2 }}>
      <Box mx="auto" maxWidth={1500} width={'100%'}>
        <Elements stripe={stripe} options={{ clientSecret: checkout.stripeToken }}>
          {children}
        </Elements>
      </Box>
    </MDCard>
  );
}
