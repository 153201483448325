import {Autocomplete, Dialog, DialogContent, DialogTitle, Grid, Stack, TextField, Typography} from "@mui/material"
import MDButton from "material-ui/components/MDButton"
import { Formik, Form, Field } from "formik"
import { ProfileRecipientValidation } from "models/modelValidations/profileRecipient"
import { States } from "models/enums/States"
import {useQueryClient} from "@tanstack/react-query";
import ProfileRecipientsService from "services/profile-recipient";
import MDInput from "material-ui/components/MDInput";
import {IProfileRecipient} from "models/profileRecipient";
import { useGlobal } from "context/global-context"

interface AddNewRecipientModalProps {
	show: boolean
	setShow: Function
	recipientToManage: IProfileRecipient | null
	setRecipientToManage: Function
}

function AddNewRecipientModal({ show, setShow, recipientToManage, setRecipientToManage}: AddNewRecipientModalProps) {
	const {setShowLoader} = useGlobal()

	const queryClient = useQueryClient()
	const {postProfileRecipient} = ProfileRecipientsService()

	function upsertRecipient(newRecipient: IProfileRecipient){
		setShowLoader(true)

		postProfileRecipient(newRecipient).then(() => {
			setShow(false)
			setShowLoader(false)
			setRecipientToManage(null)

			queryClient.invalidateQueries({queryKey: ["profileRecipients"]})
		})
	}

	const defaultRecipient = recipientToManage || {
		id: 0,
		salutation: "",
		firstName: "",
		lastName: "",
		email: "",
		toOrganization: "",
		address1: "",
		address2: "",
		city: "",
		state: "",
		zipCode: "",
		anniversary: "",
		birthDay: "",
	}

	return (
		<Dialog open={show} onClose={() => setShow(false)}>
			<DialogContent>
				<DialogTitle>
					{recipientToManage? "Manage Recipient" : "Add Recipient"}
				</DialogTitle>

				<Formik
					initialValues={defaultRecipient}
					validationSchema={ProfileRecipientValidation}
					onSubmit={(values: any, formikHelpers) => {
						upsertRecipient(values)
					}}
				>
					{({ errors, touched }) => (
						<Form>
							<Grid container gap={"20px"} justifyContent={"space-between"}>
								<Grid item sx={{ width: "50%", flex: 1 }}>
									<Field name={"firstName"}>
										{({ field, form, meta }: { field: any; form: any; meta: any }) => (
											<>
												<MDInput
													sx={{ width: "100%" }}
													type="text"
													label="First Name"
													InputLabelProps={{ shrink: true }}
													{...field}
												/>
												{meta.touched && meta.error && (
													<Typography sx={{ fontSize: "12px !important" }} color="error">
														{meta.error}
													</Typography>
												)}
											</>
										)}
									</Field>
								</Grid>

								<Grid item sx={{ width: "50%", flex: 1 }}>
									<Field name={"lastName"}>
										{({ field, form, meta }: { field: any; form: any; meta: any }) => (
											<>
												<MDInput
													sx={{ width: "100%" }}
													type="text"
													label="Last Name"
													InputLabelProps={{ shrink: true }}
													{...field}
												/>
												{meta.touched && meta.error && (
													<Typography sx={{ fontSize: "12px !important" }} color="error">
														{meta.error}
													</Typography>
												)}
											</>
										)}
									</Field>
								</Grid>
							</Grid>

							<Grid container mt={3}>
								<Grid item sx={{ width: "100%" }}>
									<Field name={"email"}>
										{({ field, form, meta }: { field: any; form: any; meta: any }) => (
											<>
												<MDInput
													sx={{ width: "100%" }}
													type="text"
													label="Email"
													InputLabelProps={{ shrink: true }}
													{...field}
												/>
												{meta.touched && meta.error && (
													<Typography sx={{ fontSize: "12px !important" }} color="error">
														{meta.error}
													</Typography>
												)}
											</>
										)}
									</Field>
								</Grid>
							</Grid>

							<Grid container mt={3}>
								<Grid item sx={{ width: "100%" }}>
									<Field name={"toOrganization"}>
										{({ field, form, meta }: { field: any; form: any; meta: any }) => (
											<>
												<MDInput
													sx={{ width: "100%" }}
													type="text"
													label="Business Name"
													InputLabelProps={{ shrink: true }}
													{...field}
												/>
												{meta.touched && meta.error && (
													<Typography sx={{ fontSize: "12px !important" }} color="error">
														{meta.error}
													</Typography>
												)}
											</>
										)}
									</Field>
								</Grid>
							</Grid>

							<Grid container mt={3}>
								<Grid item sx={{ width: "100%" }}>
									<Field name={"address1"}>
										{({ field, form, meta }: { field: any; form: any; meta: any }) => (
											<>
												<MDInput
													sx={{ width: "100%" }}
													type="text"
													label="Address 1"
													InputLabelProps={{ shrink: true }}
													{...field}
												/>
												{meta.touched && meta.error && (
													<Typography sx={{ fontSize: "12px !important" }} color="error">
														{meta.error}
													</Typography>
												)}
											</>
										)}
									</Field>
								</Grid>
							</Grid>

							<Grid container mt={3}>
								<Grid item sx={{ width: "100%" }}>
									<Field name={"address2"}>
										{({ field, form, meta }: { field: any; form: any; meta: any }) => (
											<>
												<MDInput
													sx={{ width: "100%" }}
													type="text"
													label="Address 2"
													InputLabelProps={{ shrink: true }}
													{...field}
												/>
												{meta.touched && meta.error && (
													<Typography sx={{ fontSize: "12px !important" }} color="error">
														{meta.error}
													</Typography>
												)}
											</>
										)}
									</Field>
								</Grid>
							</Grid>

							<Grid container mt={3} gap={"20px"} flexWrap={"nowrap"}>
								<Grid item>
									<Field name={"city"}>
										{({ field, form, meta }: { field: any; form: any; meta: any }) => (
											<>
												<MDInput
													sx={{ width: "100%" }}
													type="text"
													label="City"
													InputLabelProps={{ shrink: true }}
													{...field}
												/>
												{meta.touched && meta.error && (
													<Typography sx={{ fontSize: "12px !important" }} color="error">
														{meta.error}
													</Typography>
												)}
											</>
										)}
									</Field>
								</Grid>

								<Grid item>
									<Field name={"state"}>
										{({ field, form, meta }: { field: any; form: any; meta: any }) => {
											return (
												<>
													<Autocomplete
													 	value={field.value}
														options={Object.keys(States)}
														onChange={(event, value) => {
															form.setFieldValue("state", value)
														}}
														onInputChange={(_, value) => {
															if(!recipientToManage && States[value]) form.setFieldValue("state", value)
														}}
														renderInput={(props: any) => (
															<TextField
																{...props}
																label={"State"}
																InputLabelProps={{ shrink: true }}
															/>
														)}
													/>

													{meta.touched && meta.error && (
														<Typography sx={{ fontSize: "12px !important" }} color="error">
															{meta.error}
														</Typography>
													)}
												</>
											)
										}}
									</Field>
								</Grid>

								<Grid item>
									<Field name={"zipCode"}>
										{({ field, form, meta }: { field: any; form: any; meta: any }) => (
											<>
												<MDInput
													inputProps={{ maxLength: 5 }}
													type="text"
													label={"Zip"}
													sx={{ width: "100%" }}
													InputLabelProps={{ shrink: true }}
													{...field}
												/>
												{meta.touched && meta.error && (
													<Typography sx={{ fontSize: "12px !important" }} color="error">
														{meta.error}
													</Typography>
												)}
											</>
										)}
									</Field>
								</Grid>
							</Grid>

							<Grid container mt={3} gap={"20px"}>
								<Grid item sx={{ flex: 1 }}>
									<Field name={"birthDay"}>
										{({ field, form, meta }: { field: any; form: any; meta: any }) => (
											<>
												<MDInput
													sx={{ width: "100%" }}
													type="date"
													label="Birthday"
													InputLabelProps={{ shrink: true }}
													{...field}
												/>
												{meta.touched && meta.error && (
													<Typography sx={{ fontSize: "12px !important" }} color="error">
														{meta.error}
													</Typography>
												)}
											</>
										)}
									</Field>
								</Grid>

								<Grid item sx={{ flex: 1 }}>
									<Field name={"anniversary"}>
										{({ field, form, meta }: { field: any; form: any; meta: any }) => (
											<>
												<MDInput
													sx={{ width: "100%" }}
													type="date"
													label="Anniversary"
													InputLabelProps={{ shrink: true }}
													{...field}
												/>

												{meta.touched && meta.error && (
													<Typography sx={{ fontSize: "12px !important" }} color="error">
														{meta.error}
													</Typography>
												)}
											</>
										)}
									</Field>
								</Grid>
							</Grid>

							<Stack direction="row" spacing={1} marginTop={3} justifyContent="flex-end">
								<MDButton circular={true} color="light" onClick={() => setShow(false)}>
									Cancel
								</MDButton>
								<MDButton
									circular={true}
									type="submit"
								>
									Save
								</MDButton>
							</Stack>
						</Form>
					)}
				</Formik>
			</DialogContent>
		</Dialog>
	)
}

export default AddNewRecipientModal