import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Stack } from '@mui/material';

import { WorkflowContextProvider } from 'newStandard/src/contexts/useWorkflowContext';
import ProductStep from 'newStandard/src/components/ProductStep';
import { useOrderService } from 'newStandard/src/services/order';
import { IOrder } from 'newStandard/src/services/order/types';
import TemplateEditor from 'newStandard/src/features/Editor';
import Stepper from 'newStandard/src/components/Stepper';
import MDCard from 'material-ui/components/MDCard';
import Loader from 'components/loader';

import RecipientsStep from './components/RecipientsStep';
import CheckoutStep from './components/CheckoutStep';

const ORDER_STEPS = ['Product', 'Design', 'Recipients', 'Checkout'];

export default function OrderPage() {
  const params = useParams();
  const navigate = useNavigate();
  const orderId = parseInt(params.id || '');
  const { getOrder, postOrder } = useOrderService();
  const [order, setOrder] = useState<IOrder>({} as IOrder);

  const { mutate: updateOrder, isPending } = useMutation({ mutationFn: postOrder });
  const { data: orders, isError } = useQuery({ queryKey: ['order', orderId], queryFn: () => getOrder(orderId) });

  useEffect(() => {
    if (isError) navigate('/orders');
  }, [isError, navigate]);

  useEffect(() => {
    if (orders?.length) setOrder(orders[0]);
  }, [orders, setOrder]);

  const handleSave = (updatedOrder: IOrder, callback?: () => void) => {
    updateOrder(updatedOrder, {
      onSuccess: (o) => {
        callback?.();
        setOrder(o);
      },
    });
  };

  if (!order.id) return <OrderPage.Loading />;
  return (
    <WorkflowContextProvider
      steps={ORDER_STEPS}
      template={order}
      setTemplate={setOrder}
      save={handleSave}
      isSaving={isPending}
    >
      <ProductStep />
      <TemplateEditor key={order.product} />
      <RecipientsStep />
      <CheckoutStep />
    </WorkflowContextProvider>
  );
}

OrderPage.Loading = function OrderPageLoading() {
  return (
    <Stepper steps={ORDER_STEPS} maxStepAllowed={1} changeStep={() => {}}>
      <MDCard>
        <Stack alignItems={'center'} p={2}>
          <Loader />
        </Stack>
      </MDCard>
    </Stepper>
  );
};
