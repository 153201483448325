import { useEffect, useState } from 'react';
import { CSVBoxButton } from '@csvbox/react';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Alert, Box, Grid, IconButton, Snackbar, Tooltip, Typography } from '@mui/material';
import { DeleteOutline, Edit, InfoOutlined, PersonAddAlt, UploadFile } from '@mui/icons-material';

import MDCard from 'material-ui/components/MDCard';
import Table from 'components/table';
import InfoModal from 'components/info-modal';
import useDoNotMailService from 'newStandard/src/services/doNotMail';
import ProfileService from 'services/profile';
import MDButton from 'material-ui/components/MDButton';
import { actionColumnProps, defaultSearchParams } from 'components/table/table.utils';
import { IDoNotMailRecipient } from 'newStandard/src/services/doNotMail/types';
import DoNotMailUpsertModal from './components/do-not-mail-upsert-modal';
import { GridSearchParams } from 'models/gridSearchParams';

export default function DoNotMail() {
  const queryClient = useQueryClient();
  const { getProfile } = ProfileService();
  const { getAllDoNotMailRecipients, deleteDoNotMailRecipients } = useDoNotMailService();
  const [modalProps, setModalProps] = useState<{ recipient?: IDoNotMailRecipient; open: boolean }>({ open: false });
  const [searchParams, setSearchParams] = useState<GridSearchParams>(defaultSearchParams);
  const [recipientsToDelete, setRecipientsToDelete] = useState<number[]>([]);
  const [recipients, setRecipients] = useState<IDoNotMailRecipient[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const deleteAmout = recipientsToDelete?.length || 0;

  const { data: profile } = useQuery({ queryKey: ['profile'], queryFn: getProfile, refetchOnWindowFocus: false });
  const { data: { payload: rawRecipients = [] } = {}, isFetching } = useQuery({
    queryFn: getAllDoNotMailRecipients,
    queryKey: ['getAllDoNotMailRecipients'],
  });
  const allSelected = deleteAmout === recipients?.length;

  const { mutate: deleteRecipients, isPending } = useMutation({
    mutationFn: deleteDoNotMailRecipients,
    onSuccess: (response) => {
      if (response?.hasErrors) return setErrorMessage(response.errors?.[0]?? 'Something went wrong.');
      queryClient.invalidateQueries({ queryKey: ['getAllDoNotMailRecipients'] });
      if (deleteAmout) setRecipientsToDelete([]);
    },
  });

  useEffect(() => {
    if (!searchParams.search) return setRecipients(rawRecipients);
    const filteredRecipients = rawRecipients.filter((item) =>
      Object.values(item).some((value) => String(value).toLowerCase().includes(searchParams.search.toLowerCase()))
    );
    setRecipients(filteredRecipients);
  }, [rawRecipients, searchParams.search]);

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First Name', width: 120 },
    { field: 'lastName', headerName: 'Last Name', width: 120 },
    { field: 'address1', headerName: 'Address', flex: 1 },
    { field: 'address2', headerName: 'Address 2', width: 120 },
    { field: 'city', headerName: 'City', width: 150 },
    { field: 'state', headerName: 'State', width: 68, align: 'center' },
    { field: 'zipCode', headerName: 'Zip Code', width: 92, align: 'center' },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      ...actionColumnProps,
      getActions: ({ row }: GridRowParams) => [
        <IconButton onClick={() => setModalProps({ open: true, recipient: row })}>
          <Edit />
        </IconButton>,
        <IconButton color="error" onClick={() => handleDeleteClick(row.id)}>
          <DeleteOutline />
        </IconButton>,
      ],
    },
  ];

  const handleDeleteClick = (id: number) => {
    setRecipientsToDelete([id]);
    setOpenDeleteModal(true);
  };

  const handleDeleteModalConfirm = () => {
    deleteRecipients(recipientsToDelete);
    setOpenDeleteModal(false);
  };

  const handleUploadFinished = (result: boolean) => {
    if (result) queryClient.invalidateQueries({ queryKey: ['getAllDoNotMailRecipients'] });
    else setErrorMessage('CSV upload failed. Please try again, or contact support for assistance.');
  };

  const renderToolbar = () => (
    <Grid item flex={1} display={'flex'} justifyContent={'space-between'}>
      <Box display={'flex'} gap={1}>
        {profile?.id && (
          <CSVBoxButton
            licenseKey={process.env.REACT_APP_CSVBOX_DO_NOT_MAIL_RECIPIENTS ?? ''}
            user={{ user_id: 'LettrLabsCsvBoxAdmin', profile_id: profile?.id }}
            onImport={handleUploadFinished}
            render={(launch) => (
              <MDButton onClick={launch}>
                <UploadFile sx={{ mr: 1 }} />
                Upload CSV
              </MDButton>
            )}
          />
        )}

        <MDButton color="secondary" onClick={() => setModalProps({ open: true })}>
          <PersonAddAlt sx={{ mr: 1 }} />
          Insert Address
        </MDButton>
      </Box>
      <Tooltip
        title={!deleteAmout ? 'First select a recipient' : `Remove ${allSelected ? 'all' : deleteAmout} selected`}
      >
        <Box>
          <IconButton color="error" disabled={!deleteAmout} onClick={() => setOpenDeleteModal(true)}>
            <DeleteOutline />
          </IconButton>
        </Box>
      </Tooltip>
    </Grid>
  );

  if (!profile) return null;

  return (
    <MDCard sx={{ p: 2 }}>
      <Box m={2}>
        <Box display={'flex'} alignItems={'center'} gap={1} mb={2}>
          <Typography variant="h4">Do Not Mail</Typography>
          <Tooltip title={'Add recipients here to exclude them from receiving letters'}>
            <InfoOutlined color="disabled" />
          </Tooltip>
        </Box>
        <Table
          rows={recipients}
          columns={columns}
          density="compact"
          tableHeight={'64vh'}
          setAllSelected={() => {}}
          setSearchParams={setSearchParams}
          totalRowCount={recipients.length}
          selectedRows={recipientsToDelete}
          allSelected={allSelected}
          setSelectedRows={setRecipientsToDelete}
          isLoading={isFetching || isPending}
          renderToolbarFilterRight={renderToolbar}
        />
      </Box>

      <DoNotMailUpsertModal onClose={() => setModalProps({ open: false })} {...modalProps} />
      <InfoModal
        showCancelButton
        showConfirmButton
        show={!!openDeleteModal}
        setShow={() => setOpenDeleteModal(false)}
        cancelButtonOnClick={() => setOpenDeleteModal(false)}
        confirmButtonOnClick={() => handleDeleteModalConfirm()}
        headerText={`Are you sure you want to delete ${deleteAmout} recipient${deleteAmout > 1 ? 's' : ''}?`}
      />
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error" variant="standard" sx={{ py: 0 }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </MDCard>
  );
}
