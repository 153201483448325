import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT, { IObjectResult } from "../endpoint";
import {CTMPhoneNumber} from "models/CTMPhoneNumber";
import {ProfilePhoneNumber} from "models/phoneNumber";

function PhoneNumberService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/phonenumber`

    function getPhoneNumbers(): Promise<IObjectResult<CTMPhoneNumber[]>>{
        return execute({
            endpoint: API_PATH,
            method: "GET"
        })
    }

    function findPhoneNumbers(areaCode: "All" | string){
        return execute({
            endpoint: `${API_PATH}/search`,
            method: "POST",
            data: {
                areaCode: areaCode
            }
        })
    }

    function updateForwardsTo(number: ProfilePhoneNumber){
        return execute({
            endpoint: `${API_PATH}`,
            method: "POST",
            data: number
        })
    }

    function getAreaCodes(){
        return execute({
            endpoint: `${API_PATH}/areacode`,
            method: "GET"
        })
    }

    function getPaymentMethod(){
        return execute({
            endpoint: `${API_PATH}/paymentmethod`,
            method: "GET"
        })
    }

    function postPaymentMethod(data: any){
        return execute({
            endpoint: `${API_PATH}/paymentmethod`,
            method: "POST",
            data: data
        })
    }

    function checkoutPhoneNumbers(cart: CTMPhoneNumber[]){
        return execute({
            endpoint: `${API_PATH}/checkout`,
            method: "POST",
            data: {
                cart: cart
            }
        })
    }

    function deletePhoneNumber(id: number){
        return execute({
            endpoint: `${API_PATH}/${id}`,
            method: "DELETE"
        })
    }

    return {getPhoneNumbers, getAreaCodes, findPhoneNumbers, getPaymentMethod, checkoutPhoneNumbers, deletePhoneNumber, updateForwardsTo, postPaymentMethod}
}

export default PhoneNumberService