// @ts-nocheck
import {Autocomplete, CircularProgress, DialogContent, Grid, MenuItem} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "material-ui/components/MDBox";
import Dialog from "@mui/material/Dialog";
import {RadiusMail, RadiusMailAddress} from "models/radius-mailing/radiusMail";
import MDInput from "material-ui/components/MDInput";
import React, { useEffect, useMemo, useRef, useState} from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MDTypography from "material-ui/components/MDTypography";
import { debounce } from '@mui/material/utils';
import MDButton from "material-ui/components/MDButton";
import {GoogleMap, Marker} from "@react-google-maps/api";
import {Coordinate} from "models/coordinate";
import RadiusMailService from "services/radius-mail";
import InfoModal from "components/info-modal";
import { useGlobal } from "context/global-context";
import { mapContainerStyle } from "helpers/google-map-helper";

interface AddSingleAddressModalProps{
    show: boolean
    setShow: Function
    radiusMail: RadiusMail
    setRadiusMail: Function
    showCenter: Coordinate
    showZoom: number
    geocoder: any
    mainPageMap: google.maps.Map
}

interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}
interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
    description: string;
    structured_formatting: StructuredFormatting;
}

function AddSingleAddressModal({show, setShow, radiusMail, setRadiusMail, showCenter, showZoom, geocoder, mainPageMap}: AddSingleAddressModalProps) {
    const {setShowLoader} = useGlobal()

    const {postRadiusMail} = RadiusMailService()
    const minimumRecipientCount = 25;

    const [value, setValue] = useState<PlaceType | null>(null);
    const [inputValue, setInputValue] = useState<string>('');
    const [options, setOptions] = useState<readonly PlaceType[]>([]);

    const [center, setCenter] = useState<google.maps.LatLng | google.maps.LatLngLiteral>()
    const [zoom, setZoom] = useState<number>(7)

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [errorModalText, setErrorModalText] = useState<string>("")

    const autocompleteService = useRef<google.maps.places.AutocompleteService>(null);

    const [autocompleteLoading, setAutocompleteLoading] = useState<boolean>(false)

    const mapOptions: google.maps.MapOptions = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeId: 'hybrid',
        disableDefaultUI: true
    }

    useEffect(() => {
        if(show){
            setCenter(showCenter)
            setZoom(showZoom)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const fetch = useMemo(
        () =>
            debounce(
                (
                    request: { input: string },
                    callback: (results?: readonly PlaceType[]) => void,
                ) => {
                    (autocompleteService.current as any).getPlacePredictions(
                        request,
                        callback,
                    );
                },
                400,
            ),
        [],
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google.maps.places) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return;
        }

        setAutocompleteLoading(true)
        fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
            if (active) {
                let newOptions: readonly PlaceType[] = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }

            setAutocompleteLoading(false)
        });
    }, [value, inputValue, fetch]);

    function addAddress(){
        geocoder.geocode({address: value?.description}, (results: any, status: any) => {
            if (status === 'OK' && results && results.length > 0) {
                const fullAddress = results[0].formatted_address

                const lat =  results[0].geometry.location.lat()
                const lng =  results[0].geometry.location.lng()

                setRadiusMail((prevState: RadiusMail) => {
                    let sortedAddresses = prevState.addresses.sort((a, b) => {
                        if (a.id > b.id) {
                            return 1
                        } else if (a.id < b.id) {
                            return -1
                        }
                        return 0
                    })

                    let lastId = sortedAddresses.length ? sortedAddresses[sortedAddresses.length - 1].id : 0

                    let newAddress: RadiusMailAddress = {
                        id: lastId + 1,
                        isNew: true,
                        address: fullAddress,
                        coordinate: {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                        },
                        structures: minimumRecipientCount,
                        radiusPinAddress: '',
                        radiusPinStreet: ''
                    }

                    return {...prevState, addresses: [...prevState.addresses, newAddress]}
                })

                setCenter({lat: lat, lng: lng})
                setZoom(12)

                setValue(null)
            }
        })
    }

    function handleClose(){
        let postData = {...radiusMail};

        for (let e of postData.addresses) {
            if (e.isNew) {
                e.id = 0
            }
        }

        setShowLoader(true)

        postRadiusMail(postData).then((response) => {
            let resp = response.payload as RadiusMail

            setRadiusMail(resp)
            setShowLoader(false)

            if(response.hasWarnings){
                setErrorModalText(response.warnings[0])
                setShowErrorModal(true)
            }
            else{
                setShow(false)

                mainPageMap.setCenter(resp.addresses[resp.addresses.length - 1].coordinate)
            }
        })
    }

    return <Dialog open={show} onClose={handleClose} maxWidth={false}>
        <DialogContent sx={{width: "750px"}}>
            <DialogTitle>
                Add an Address
            </DialogTitle>

            <Grid container alignItems={"center"} gap={1}>
                <Grid item flex={1}>
                    <Autocomplete
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : option.description
                        }
                        filterOptions={(x) => x}
                        options={options}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        value={value}
                        noOptionsText="No locations"
                        loading={autocompleteLoading}
                        loadingText={
                            <MDBox textAlign={"center"}>
                                <CircularProgress color="primary"/>
                            </MDBox>
                        }
                        onChange={(event: any, newValue: any) => {
                            if(!newValue){
                                return
                            }

                            setOptions(newValue ? [newValue, ...options] : options);
                            setValue(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        renderInput={(params) => (
                            <MDInput {...params} label="Type your address here" fullWidth />
                        )}
                        renderOption={(props, option) => {
                            return (
                                <MenuItem sx={{paddingLeft: "5px"}} {...props}>
                                    <Grid container alignItems="center" gap={1}>
                                        <Grid item display={"flex"}>
                                            <LocationOnIcon color={"primary"} />
                                        </Grid>

                                        <Grid item flex={1} sx={{ wordWrap: 'break-word' }}>
                                            <MDTypography
                                                display={"inline-block"}
                                                fontWeight={"bold"}
                                                sx={{fontSize: 14}}
                                            >
                                                {option.structured_formatting.main_text}
                                            </MDTypography>
                                            <MDTypography sx={{fontSize: 12}}>
                                                {option.structured_formatting.secondary_text}
                                            </MDTypography>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            );
                        }}
                    />
                </Grid>

                <Grid item>
                    <Grid item>
                        <MDButton color={"primary"} onClick={addAddress}>
                            Add Address
                        </MDButton>
                    </Grid>
                </Grid>
            </Grid>

            <MDBox mt={2} sx={{width: "100%", height: "500px"}}>
                <GoogleMap
                    zoom={zoom}
                    center={center}
                    mapContainerStyle={mapContainerStyle}
                    options={mapOptions}
                >
                    {radiusMail.addresses.map((address, index) => {
                        return <Marker
                                key={index}
                                position={{
                                    lat: address.coordinate.lat,
                                    lng: address.coordinate.lng
                                }}
                            />
                    })}
                </GoogleMap>
            </MDBox>

            <Grid container mt={3} alignItems={"center"} justifyContent={"right"} gap={1}>
                <Grid item>
                    <MDButton color={"primary"} onClick={handleClose}>
                        Save
                    </MDButton>
                </Grid>
            </Grid>
        </DialogContent>

        <InfoModal
            show={showErrorModal}
            setShow={setShowErrorModal}
            headerText={errorModalText}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowErrorModal(false)
            }}
        />
    </Dialog>
}

export default AddSingleAddressModal