import {DesignCategoryData} from "models/designCategoryData";
import MDBox from "material-ui/components/MDBox";
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {DesignCategory} from "models/designCategory";
import PremadeDesignsList from "../premade-designs-list";
import React, {useState} from "react";
import {PremadeDesign} from "models/premadeDesign";
import {ResponsiveType} from "react-multi-carousel";

interface PremadeDesignsBlockProps {
    data: DesignCategoryData
    showFilter?: boolean
    selectedPremadeDesign: PremadeDesign
    designOnClick: Function
    containerHeight?: string
    responsiveSteps: ResponsiveType
}

function PremadeDesignsBlock({data, showFilter = true, selectedPremadeDesign, designOnClick, containerHeight, responsiveSteps}: PremadeDesignsBlockProps){
    const [selectedCategory, setSelectedCategory] = useState<string>("All")

    return <MDBox>
        {showFilter?
            <MDBox mb={2}>
                <FormControl fullWidth={true}>
                    <InputLabel id="demo-simple-select-label">Categories</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Categories"
                        value={selectedCategory}
                        onChange={(event) => {
                            setSelectedCategory(event.target.value as string)
                        }}
                    >
                        <MenuItem value={"All"}>All</MenuItem>
                        {data?.allCategories?.map((category: string, index: number) => {
                            return <MenuItem key={index} value={category}>{category}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </MDBox>
            :
            null
        }

        <MDBox height={containerHeight ?? "auto"} sx={{overflowY: containerHeight? "scroll" : "auto"}}>
            {data?.designCategories?.filter((a, index: number) => selectedCategory === "All" || a.name === selectedCategory).map((designCategory: DesignCategory, index: number) => {
                return <MDBox key={index} mb={2} px={2}>
                    <PremadeDesignsList viewMode={selectedCategory === "All"? "carousel" : "grid"} designCategory={designCategory} selectedPremadeDesign={selectedPremadeDesign} designOnClick={designOnClick} responsiveSteps={responsiveSteps}/>
                </MDBox>
            })}
        </MDBox>
    </MDBox>
}

export default PremadeDesignsBlock