import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import { Box, IconButton, List } from '@mui/material';
import { ChevronLeft, ChevronRight, Logout } from '@mui/icons-material';

import logo from 'assets/images/logo.svg';
import ProfileService from 'services/profile';
import logoFull from 'assets/images/logo-full.svg';
import sidebarRoutes from 'newStandard/src/routes/sidebar';
import sidebarSuperAdminRoutes from 'newStandard/src/routes/sidebarSuperAdmin';
import sidebarAdminRoutes from 'newStandard/src/routes/sidebarAdmin';
import { RouteType } from 'newStandard/src/routes/types';
import { useGlobal } from 'context/global-context';

import { StyledDrawer, StyledSidebarDivider } from './StyledSidebar';
import SidebarItemActingAs from './SidebarItemActingAs';
import SidebarItem from './SidebarItem';

export default function Sidebar() {
  const { instance } = useMsal();
  const { isSidebarOpen, setIsSidebarOpen } = useGlobal();
  const [routes, setRoutes] = useState<RouteType[]>([]);
  const { getLoggedInProfile } = ProfileService();

  const { data: profile } = useQuery({ queryKey: ['loggedInProfile'], queryFn: getLoggedInProfile });

  useEffect(() => {
    let auxRoutes = [...sidebarRoutes];
    if (profile) {
      const email = profile.email.toLowerCase();
      const isAdmin = profile.isAdmin;
      const isSuperAdmin = [
        'fabio@lettrlabs.com',
        'brandon@lettrlabs.com',
        'kornel@lettrlabs.com',
        'jordan@lettrlabs.com',
        'dan@lettrlabs.com',
        'arik@lettrlabs.com',
        'heather@lettrlabs.com'
      ].includes(email);
      if (profile.industry === 'Home Services' || email.endsWith('lettrlabs.com') || isAdmin) {
        const stormRoute = auxRoutes[auxRoutes.findIndex((el) => el.route === '/storm-automation')];
        if (stormRoute) stormRoute.hide = false;
      }
      if (isAdmin) auxRoutes.push(...sidebarAdminRoutes);
      if (isSuperAdmin) {
        const superAdminRoutes = { ...sidebarSuperAdminRoutes };
        if (profile.email.toLowerCase() === 'brandon@lettrlabs.com' && Array.isArray(superAdminRoutes.collapse)) {
          const routeIndex = superAdminRoutes.collapse.findIndex((el) => el.route === '/superadmin/discount-approval');
          if (routeIndex >= 0) superAdminRoutes.collapse[routeIndex].hide = false;
        }
        auxRoutes.push(superAdminRoutes);
      }
    }
    setRoutes(auxRoutes);
  }, [profile]);

  return (
    <StyledDrawer variant="permanent" open={isSidebarOpen} actingAsUser={!!profile?.actingAsUserId}>
      <Box p={1} pt={3} display={'flex'} justifyContent={'center'}>
        <NavLink to={'/home'}>
          <Box component="img" src={isSidebarOpen ? logoFull : logo} alt="Logo" height={40} />
        </NavLink>
        <Box position="absolute" right={isSidebarOpen ? 20 : 16} zIndex={1} top={70} color="whitesmoke">
          <IconButton
            size="small"
            color="inherit"
            sx={{ backgroundColor: '#120b3d', p: 0, '&:hover': { backgroundColor: '#120b3ddd' } }}
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            {isSidebarOpen ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Box>
      </Box>
      <StyledSidebarDivider />
      <List>
        <SidebarItemActingAs />
        {routes.map((route) => (
          <SidebarItem key={route.name} {...route} />
        ))}
        <SidebarItem name="Logout" icon={<Logout />} onClick={() => instance.logout()} />
      </List>
    </StyledDrawer>
  );
}
