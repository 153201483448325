import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";

function ApiKeyService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/apikey`

    function getApiKeys(){
        return execute({
            endpoint: `${API_PATH}`,
            method: "GET"
        })
    }

    function createApiKey(name: string): Promise<{key: string}>{
        return execute({
            endpoint: `${API_PATH}`,
            method: "POST",
            data: {
                description: name
            }
        })
    }

    function deleteApiKey(id: number){
        return execute({
            endpoint: `${API_PATH}/${id}`,
            method: "DELETE"
        })
    }

    return {getApiKeys, createApiKey, deleteApiKey}
}

export default ApiKeyService