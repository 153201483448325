import useFetchWithMsal from 'hooks/useFetch';
import API_ENDPOINT, { IObjectResult, mockedObjectResult } from 'services/endpoint';
import { mockDoNotMailRecipients } from './mocks';
import { IDoNotMailRecipient } from './types';

const useDoNotMailService = () => {
  const { execute } = useFetchWithMsal();
  const API_PATH_V2 = `${API_ENDPOINT}/v2/do-not-mail`;
  const shouldMock = false;

  const getAllDoNotMailRecipients = (): Promise<IObjectResult<IDoNotMailRecipient[]>> => {
    if (shouldMock) return new Promise((resolve) => resolve({ payload: mockDoNotMailRecipients }));
    return execute({ endpoint: API_PATH_V2, method: 'GET' });
  };

  const createDoNotMailRecipient = (recipient: IDoNotMailRecipient): Promise<IObjectResult<{}>> => {
    if (shouldMock) return new Promise((resolve) => resolve(mockedObjectResult({})));
    return execute({ endpoint: API_PATH_V2, method: 'POST', data: recipient });
  };

  const updateDoNotMailRecipient = (recipient: IDoNotMailRecipient): Promise<IObjectResult<{}>> => {
    if (shouldMock) return new Promise((resolve) => resolve(mockedObjectResult({})));
    return execute({ endpoint: `${API_PATH_V2}`, method: 'PUT', data: recipient });
  };

  const deleteDoNotMailRecipients = (recipientIds: number[]): Promise<IObjectResult<{}>> => {
    if (shouldMock) return new Promise((resolve) => resolve(mockedObjectResult({})));
    return execute({ endpoint: `${API_PATH_V2}:delete-selected/`, method: "DELETE", data: { recipientIds } });
  };

  return {
    getAllDoNotMailRecipients,
    createDoNotMailRecipient,
    updateDoNotMailRecipient,
    deleteDoNotMailRecipients,
  };
};

export default useDoNotMailService;
