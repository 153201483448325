import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT, { IObjectResult } from "../endpoint";
import {ReturnAddress} from "models/returnAddress";

function ProfileReturnAddress() {
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/profiles/addresses`

    // Gets all of the user's return addresses
    function getProfileReturnAddresses():Promise<IObjectResult<ReturnAddress[]>> {
        return execute({
            endpoint: API_PATH,
            method: "GET"
        })
    }

    // Updates or creates a return address
    function postReturnAddress(returnAddress: ReturnAddress):Promise<IObjectResult<ReturnAddress>> {
        return execute({
            endpoint: API_PATH,
            method: "POST",
            data: returnAddress
        })
    }

    // Updates or creates a return address
    function deleteReturnAddress(returnAddressId: number) {
        return execute({
            endpoint: `${API_PATH}/${returnAddressId}`,
            method: "DELETE",
        })
    }

    return {getProfileReturnAddresses, postReturnAddress, deleteReturnAddress}
}

export default ProfileReturnAddress