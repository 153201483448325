import { ConversionDataSourceType, IConversion } from './types';

export const conversionMocks: IConversion[] = [
  {
    downloadLink: 'https://example.com/downloads/document1.pdf',
    fileName: 'document1.pdf',
    id: 1001,
    dataSourceType: ConversionDataSourceType.ApiUpload,
    uploadDate: '2024-11-01T08:45:00Z',
    canDelete: true,
    status: 'Completed',
  },
  {
    downloadLink: 'https://example.com/downloads/report_final.docx',
    fileName: 'report_final.docx',
    id: 1002,
    dataSourceType: ConversionDataSourceType.ApiUpload,
    uploadDate: '2024-10-25T10:00:00Z',
    canDelete: false,
    status: 'Deleted',
  },
  {
    downloadLink: 'https://example.com/downloads/image_2024.png',
    fileName: 'image_2024.png',
    id: 1003,
    dataSourceType: ConversionDataSourceType.CsvImport,
    uploadDate: '2024-09-18T13:30:00Z',
    canDelete: true,
    status: 'Failed',
  },
  {
    downloadLink: 'https://example.com/downloads/budget_summary.xlsx',
    fileName: 'budget_summary.xlsx',
    id: 1004,
    dataSourceType: ConversionDataSourceType.CsvImport,
    uploadDate: '2024-07-05T09:15:00Z',
    canDelete: false,
    status: 'Processing',
  },
  {
    downloadLink: 'https://example.com/downloads/presentation_final.pptx',
    fileName: 'presentation_final.pptx',
    id: 1005,
    dataSourceType: ConversionDataSourceType.ApiUpload,
    uploadDate: '2024-06-20T16:45:00Z',
    canDelete: true,
    status: 'Processing',
  },
];
