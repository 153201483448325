import useFetchWithMsal from 'hooks/useFetch';
import API_ENDPOINT from 'services/endpoint';

import { IFont } from './types';

export default function useFontService() {
  const { execute } = useFetchWithMsal();

  const API_PATH = `${API_ENDPOINT}/fonts`;

  const getFonts = (): Promise<IFont[]> => {
    return execute({ endpoint: API_PATH, method: 'GET' });
  };

  const getFontGlyphs = (id?: number) => {
    if (!id) return Promise.resolve(null);
    return execute({ endpoint: `${API_PATH}/${id}/font-glyphs`, method: 'GET' });
  };

  return { getFonts, getFontGlyphs };
}
