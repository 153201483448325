/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 PRO React TS Base Styles
import colors from "material-ui/theme/base/colors";
import borders from "material-ui/theme/base/borders";

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "material-ui/theme/functions/pxToRem";
import typography from "../base/typography";

const {grey, secondary, tooltipColors, light} = colors;
const {borderRadius} = borders;
const { size, fontWeightRegular } = typography;

// types
type Types = any;

const slider: Types = {
    styleOverrides: {
    //     root: {
    //         width: "100%",

    //         "& .MuiSlider-active, & .Mui-focusVisible": {
    //             boxShadow: "none !important",
    //         },

    //         "& .MuiSlider-valueLabel": {
    //             color: black.main,
    //         },

    //         "&.Mui-disabled": {
    //             opacity: 0.6
    //         }
    //     },

        rail: {
    //         height: pxToRem(4),
            background: grey[200],
    //         borderRadius: borderRadius.lg,
            opacity: 1,
        },

    //     track: {
    //         background: primary.main,
    //         height: pxToRem(4),
    //         position: "relative",
    //         border: "none",
    //         borderRadius: borderRadius.lg,
    //         zIndex: 1,
    //     },

        mark: {
            display: "none"
        },

        markLabel: {
            fontSize: 12,
            color: secondary.main,
            fontWeight: 400
        },

        valueLabel: {
            backgroundColor: tooltipColors.main,
            borderRadius: borderRadius.md,

            ".MuiSlider-valueLabelLabel": {
                color: light.main,
                fontSize: size.sm,
                fontWeight: fontWeightRegular,
            },
        },

        thumb: {
            width: pxToRem(16),
            height: pxToRem(16),
    //         backgroundColor: primary.main,
    //         zIndex: 10,
    //         transition: "all 200ms linear",

    //         "&:hover": {
    //             boxShadow: "none",
    //         },

    //         "&:active": {
    //             transform: "translate(-50%, -50%) scale(1.4)",
    //         },

    //         "&.Mui-active": {boxShadow: boxShadow([0, 0], [0, 14], info.main, 0.16)},
        },
    },
};

export default slider;
