import { useSearchParams } from 'react-router-dom';
import { CheckCircleRounded } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

import MDButton from 'material-ui/components/MDButton';

export default function LeadRevealSuccessModal() {
  const [searchParams, setSearchParams] = useSearchParams();
  const open = searchParams.get('modal') === 'SuccessLeadReveal';

  return (
    <Dialog open={open} onClose={() => setSearchParams('', { replace: true })} maxWidth="md">
      <DialogContent>
        <DialogTitle display={'flex'} alignItems={'center'} sx={{ mb: 1, svg: { mr: 1, height: 32 } }}>
          <CheckCircleRounded color="success" />
          You've successfully setup LeadReveal!
        </DialogTitle>
        <Typography fontSize={16}>To get started create a LeadReveal Smart Automation to get Sending!</Typography>
        <MDButton
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => setSearchParams('modal=NewAutomation&dataSourceId=3', { replace: true })}
        >
          Create Automation
        </MDButton>
      </DialogContent>
    </Dialog>
  );
}
