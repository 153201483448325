import Retool from "react-retool";
import MDBox from "material-ui/components/MDBox";

function SubscriptionCredits() {
    return (
        <MDBox style={{ height: "90vh" }}>
            <Retool url={"https://lettrlabs.retool.com/embedded/public/68a2a14b-670d-481d-a58b-f2d7a505cb01"} />
        </MDBox>
    );
}

export default SubscriptionCredits;