import { ProductOptions } from 'newStandard/src/services/order/types';

export const includedCharacters: Partial<{ [key in ProductOptions]: number }> = {
  [ProductOptions.LargeHandwrittenCardA8]: 500,
  [ProductOptions.HandwrittenBiFoldCard]: 1000,
  [ProductOptions.HandwrittenPostCardA8]: 350,
};

export const maxCharacters: Partial<{ [key in ProductOptions]: number }> = {
  [ProductOptions.LargeHandwrittenCardA8]: 800,
  [ProductOptions.HandwrittenBiFoldCard]: 1600,
  [ProductOptions.HandwrittenPostCardA8]: 350,
};
