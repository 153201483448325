import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { IProduct, productCategories } from 'newStandard/src/components/ProductStep/ProductStep.utils';
import { addBorderToPage, setBlockToImageByName, stretchImageToFullBleed } from 'helpers/ImgLyHelper';
import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { ProductOptions, YesNo } from 'newStandard/src/services/order/types';
import useFontService from 'newStandard/src/services/font';
import { addGlyphs } from 'helpers/glyphHelper';

import { BlockNames, DefaultArtifacts } from '../utils/sceneEnums';
import { addBleedMargin, resetBleeds } from '../utils/bleedHelper';
import { recreatePlaceholderImage } from '../utils/imageHelper';
import { useEditorContext } from '../contexts/useEditorContext';
import { checkIsPrintedCard } from '../utils/templateHelper';
import { keepOutFirstLoad } from '../utils/keepOutHelper';
import { ArtifactType } from '../services/artifact/types';

export default function useLoadCardScene() {
  const { getFontGlyphs } = useFontService();
  const { template, setTemplate } = useWorkflowContext();
  const { engine, isSceneLoading, selectedFont, setIsSceneLoading, setIsApplyingTemplateId, setPages } =
    useEditorContext();

  const { data: fontGlyphs } = useQuery({
    queryKey: ['font-glyphs', selectedFont?.id],
    queryFn: () => getFontGlyphs(selectedFont?.id),
  });

  useEffect(() => {
    if (!engine?.scene) return;
    const { protocol, host } = window.location;
    const random = Math.floor(Math.random() * 1000000000000000);
    const sceneUrl = `${protocol}//${host}/api/scene/${template.id}/Card/?${random}`;
    engine.scene.loadFromURL(sceneUrl).then(() => setIsSceneLoading(true));
  }, [engine?.scene, setIsSceneLoading, template.id]);

  useEffect(() => {
    const isPrintedCard = checkIsPrintedCard(template.product);

    if (!isSceneLoading || !engine || !template || (!isPrintedCard && !fontGlyphs?.value)) return;

    let product = {} as IProduct;
    productCategories.forEach((category) => {
      const currentProduct = category.products.find((p) => p.name === template.product);
      if (currentProduct) return (product = currentProduct);
    });

    // * Consts

    const isBifold = template.product === ProductOptions.HandwrittenBiFoldCard;
    const pages = engine.scene.getPages();
    setPages(pages);

    // * Functions

    const setQRCodeVisibility = async () => {
      const qrCodeBlocks = engine.block.findByName(BlockNames.QRCode);
      if (template.useQr && qrCodeBlocks.length) {
        engine.block.setScopeEnabled(qrCodeBlocks[0], 'lifecycle/duplicate', true);
        engine.block.setScopeEnabled(qrCodeBlocks[0], 'lifecycle/destroy', true);
        const newBlock = engine.block.duplicate(qrCodeBlocks[0]);
        engine.block.destroy(qrCodeBlocks[0]);
        engine.block.setName(newBlock, BlockNames.QRCode);
        engine.block.setScopeEnabled(newBlock, 'editor/select', true);
        engine.block.setScopeEnabled(newBlock, 'layer/resize', true);
        engine.block.setScopeEnabled(newBlock, 'layer/move', true);
      } else {
        qrCodeBlocks.forEach((block) => {
          engine.block.setScopeEnabled(block, 'lifecycle/destroy', true);
          engine.block.destroy(block);
          setTemplate((prev) => ({
            ...prev,
            qrAngle: null,
            qrCodeHeight: null,
            qrCodeSide: null,
            qrCodeWidth: null,
            qrCodeX: null,
            qrCodeY: null,
            qrUrl: null,
            useQr: false,
          }));
        });
      }
    };

    const loadImagesFromArtifacts = async () => {
      if (template.doubleSided === YesNo.Yes || isBifold) {
        const rearImageName = isBifold ? BlockNames.OutsideFrontImage : BlockNames.RearImage;
        const rearImage = template.orderArtifacts.find((artifact) => artifact.artifactType === ArtifactType.RearImage);
        if (rearImage) setBlockToImageByName(engine, rearImageName, rearImage.blobUri);
      }
    };

    const strechPlaceholderImageByName = (blockName: BlockNames) => {
      const image = engine.block.findByName(blockName)[0];
      if (image) {
        const imageFill = engine.block.getFill(image);
        const imageUrl = engine.block.getString(imageFill, 'fill/image/imageFileURI');
        // Only make it full stretch if it's the placeholder image
        // since we don't want to overwrite the size of the user's selected image
        if (imageUrl?.includes('/scenes/')) stretchImageToFullBleed(engine, image, product.name);
      }
    };

    const textConfig = (blockName: BlockNames) => {
      const textBlockId = engine.block.findByName(blockName)[0];
      if (!textBlockId) return;
      // Add template text to the engine with glyph variations
      const text = blockName === BlockNames.HandwrittenText2 ? template.text2 : template.text;
      engine.block.setString(textBlockId, 'text/text', addGlyphs(text, fontGlyphs.value));
      // Enable text/hasClippedLines usage, which sets the hasTextOutsideOfHandwrittenTextArea state
      engine.block.setBool(textBlockId, 'text/clipLinesOutsideOfFrame', true);
    };

    const removeStampFromPrintedProducts = () => {
      const blockToRemove = [
        ...engine.block.findByName(BlockNames.UspsStamp),
        ...engine.block.findByName(BlockNames.UspsStampText),
        ...engine.block.findByName(BlockNames.UspsStampElement),
        ...[100667854, 69210520], // Stamp Element, hard coded cause is not named
      ];
      blockToRemove.forEach((block) => {
        if (engine.block.isValid(block)) {
          engine.block.setScopeEnabled(block, 'lifecycle/destroy', true);
          engine.block.destroy(block);
        }
      });
    };

    const disableStructureBlocks = () => {
      const blocksToDisable = [
        ...engine.block.findByName(BlockNames.Border),
        ...engine.block.findByName(BlockNames.SafeZone),
        ...engine.block.findByName(BlockNames.SafeZoneText),
        ...engine.block.findByName(BlockNames.SafeZoneSmallText),
        ...engine.block.findByName(BlockNames.SafeZoneBigText),
        ...engine.block.findByName(BlockNames.FullBleedSmallText),
        ...engine.block.findByName(BlockNames.FullBleedBigText),
        ...engine.block.findByName(BlockNames.OutsideKeepOutAreaText),
      ];
      blocksToDisable.forEach((block) => {
        engine.block.setIncludedInExport(block, false);
        engine.block.setScopeEnabled(block, 'editor/select', false);
      });
    };

    const showFrontPage = () => {
      if (!pages.length) return;
      engine.block.setScopeEnabled(pages[0], 'layer/visibility', true);
      engine.block.setVisible(pages[0], true);
      engine.block.setScopeEnabled(pages[1], 'layer/visibility', true);
      engine.block.setVisible(pages[1], false);
    };

    const nameShapesToBeIgnoredOnValidations = () => {
      const shapes = engine.block.findByKind('shape');
      shapes.forEach((shape) => {
        if (!engine.block.getName(shape)) engine.block.setName(shape, BlockNames.InheritedShape);
      });
    };

    const handlePlaceholderImage = (name: BlockNames, defaultArtifact: DefaultArtifacts) => {
      const placeholders = engine.block.findByName(name);
      placeholders.forEach((block) => {
        const fill = engine.block.getFill(block);
        const uri = fill ? engine.block.getString(fill, 'fill/image/imageFileURI') : null;
        // The placeholder coming with the scene is missing a bunch of image functionalities
        // So we recreate the placeholder image to enable it
        if (defaultArtifact === uri) recreatePlaceholderImage(engine, block);
        else if (name === BlockNames.FrontLogo && template.noFrontLogo === YesNo.Yes) {
          engine.block.setScopeEnabled(block, 'layer/visibility', true);
          engine.block.setVisible(block, false);
        }
      });
    };

    const getFrontDefaultArtifact = () => {
      if (template.product === ProductOptions.PrintedPostcard4x6) return DefaultArtifacts.PrintedPostCardFrontImage;
      if (isPrintedCard) return DefaultArtifacts.OtherPrintedPostCardFrontImage;
      return DefaultArtifacts.Front;
    };

    // * Scene builder

    keepOutFirstLoad(engine, template.product);
    resetBleeds(engine, product);
    addBorderToPage(engine, pages[1]);
    setQRCodeVisibility();
    nameShapesToBeIgnoredOnValidations();
    if (template.noFrontLogo !== YesNo.Yes) handlePlaceholderImage(BlockNames.FrontLogo, getFrontDefaultArtifact());
    handlePlaceholderImage(BlockNames.RearImage, DefaultArtifacts.Rear);
    handlePlaceholderImage(BlockNames.OutsideFrontImage, DefaultArtifacts.OutsideFront);
    if (template.orderArtifacts) loadImagesFromArtifacts();

    if (isPrintedCard) {
      addBorderToPage(engine, pages[0]);
      strechPlaceholderImageByName(BlockNames.FrontLogo);
      strechPlaceholderImageByName(BlockNames.RearImage);
      addBleedMargin(engine);
      removeStampFromPrintedProducts();
    } else {
      textConfig(BlockNames.HandwrittenText);
      const rearImage = engine.block.findByName(BlockNames.RearImage)[0];
      if (rearImage) engine.block.setScopeEnabled(rearImage, 'lifecycle/destroy', true);
      if (isBifold) {
        textConfig(BlockNames.HandwrittenText2);
        strechPlaceholderImageByName(BlockNames.OutsideFrontImage);
      }
    }

    engine.block.findAllSelected().forEach((block) => engine.block.setSelected(block, false));
    disableStructureBlocks();
    showFrontPage();
    setIsSceneLoading(false);
    if (checkIsPrintedCard(template.product)) setIsApplyingTemplateId(undefined);
  }, [
    engine,
    isSceneLoading,
    setIsSceneLoading,
    setIsApplyingTemplateId,
    template,
    setTemplate,
    setPages,
    fontGlyphs?.value,
  ]);
}
