import useFetchWithMsal from 'hooks/useFetch';
import API_ENDPOINT from '../endpoint';
import { DesignCategory } from 'models/designCategory';

function PremadeDesignService() {
  const { execute } = useFetchWithMsal();

  const API_PATH = `${API_ENDPOINT}/designs`;

  // Gets all premade designs
  function getPremadeDesigns(): Promise<{ allCategories: string[], designCategories: DesignCategory[] }> {
    return execute({
      endpoint: `${API_PATH}`,
      method: 'GET',
    });
  }

  return { getPremadeDesigns };
}

export default PremadeDesignService;
