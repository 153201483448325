import Dialog from "@mui/material/Dialog"
import {DialogContent, Stack,} from "@mui/material"
import MDButton from "material-ui/components/MDButton"
import {PostageTypes} from "models/enums/PostageTypes"
import {useState} from "react";
import {Template} from "models/template";
import IntegrationOrderService from "services/integration-order";
import {useMutation} from "@tanstack/react-query";
import MDInput from "material-ui/components/MDInput";
import MDBox from "material-ui/components/MDBox";
import InfoModal from "../info-modal";
import {useLocation, useNavigate} from "react-router-dom";
import { useGlobal } from "context/global-context";

export interface IntegrationOrderModalProps {
    show: boolean
    setShow: Function
    template?: Template
    isSmartAutomation?: boolean
}

function IntegrationOrderModal({show, setShow, template, isSmartAutomation}: IntegrationOrderModalProps) {
    const navigate = useNavigate()
    const { pathname } = useLocation();
    const {setShowLoader, setNavigationCallback} = useGlobal()
    const {createIntegrationOrderFromTemplate} = IntegrationOrderService()

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
    const [integrationOrderName, setIntegrationOrderName] = useState<string>("")

    const createIntegrationOrderFromTemplateMutation = useMutation({
        mutationFn: () => {
            return createIntegrationOrderFromTemplate(integrationOrderName, template?.id || 0, PostageTypes.FirstClass)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data) => {
            setShowLoader(false)
            setShow(false)
            setIntegrationOrderName("")
            setNavigationCallback(() => () => navigate(`${pathname}?templateId=${data.payload.templateId}`))
            navigate(`/template/${data.payload.templateId}`)
        }
    })

    return (
        <Dialog open={show} onClose={() => setShow(false)} maxWidth={false}>
            <DialogContent>
                <MDBox>
                    <MDInput
                        fullWidth={true}
                        width={"100%"}
                        type="text"
                        value={integrationOrderName}
                        helperText={`This name will help you identify your ${isSmartAutomation ? "smart automation" : "integration order"} later`}
                        label={`${isSmartAutomation ? "Smart Automation" : "Integration Order"} Name`}
                        InputLabelProps={{shrink: true}}
                        onChange={(e: { target: { value: any } }) => {
                            setIntegrationOrderName(e.target.value)
                        }}
                    />
                </MDBox>

                <Stack
                    direction="row"
                    spacing={2}
                    marginTop={3}
                    justifyContent="flex-end"
                >
                    <MDButton
                        color="light"
                        onClick={() => setShow(false)}
                    >
                        Cancel
                    </MDButton>
                    <MDButton
                        color="primary"
                        onClick={() => {
                            createIntegrationOrderFromTemplateMutation.mutate()
                        }}
                    >
                        Create
                    </MDButton>
                </Stack>
            </DialogContent>

            <InfoModal
                show={showSuccessModal}
                setShow={setShowSuccessModal}
                headerText={"Integration order successfully created. You can find it in your integrations page"}
                showConfirmButton={true} confirmButtonOnClick={() => setShowSuccessModal(false)}
            />
        </Dialog>
    )
}

export default IntegrationOrderModal
