import { ITemplateOrderIntersection } from 'newStandard/src/contexts/useWorkflowContext';
import { ProductOptions } from 'newStandard/src/services/order/types';
import { OrderDefaults } from 'models/enums/OrderDefaults';

// Category Icons
import HandwrittenCardEnvelop from 'assets/icons/handwritten-card-envelop.svg';
import HandwrittenPostcard from 'assets/icons/handwritten-postcard.svg';
import PrintedPostcard from 'assets/icons/printed-postcard.svg';

// Images
import image1 from 'assets/images/products/card/image1.png';
import image2 from 'assets/images/products/card/image2.png';
import image3 from 'assets/images/products/card/image3.png';
import image4 from 'assets/images/products/card/image4.png';
import image5 from 'assets/images/products/card/image5.png';

import bifold1 from 'assets/images/products/card/bifold1.png';
import bifold2 from 'assets/images/products/card/bifold2.png';
import bifold3 from 'assets/images/products/card/bifold3.png';
import bifold4 from 'assets/images/products/card/bifold4.png';
import bifold5 from 'assets/images/products/card/bifold5.png';

import imagepc1 from 'assets/images/products/postcard/image1.png';
import imagepc2 from 'assets/images/products/postcard/image2.png';
import imagepc3 from 'assets/images/products/postcard/image3.png';
import imagepc4 from 'assets/images/products/postcard/image4.png';
import imagepc5 from 'assets/images/products/postcard/image5.png';

import image4x6pc1 from 'assets/images/products/printed-postcard/4x6/4x6-1-postcard-double.png';
import image4x6pc2 from 'assets/images/products/printed-postcard/4x6/4x6-1-postcard-front.png';
import image4x6pc3 from 'assets/images/products/printed-postcard/4x6/4x6-2-postcard-double.png';
import image4x6pc4 from 'assets/images/products/printed-postcard/4x6/4x6-3-postcard-double.png';
import image4x6pc5 from 'assets/images/products/printed-postcard/4x6/4x6-4-postcard-double.png';

import image6x9pc1 from 'assets/images/products/printed-postcard/6x9/6x9-1-postcard-double.png';
import image6x9pc2 from 'assets/images/products/printed-postcard/6x9/6x9-1-postcard-front.png';
import image6x9pc3 from 'assets/images/products/printed-postcard/6x9/6x9-1-postcard-rear.png';
import image6x9pc4 from 'assets/images/products/printed-postcard/6x9/6x9-2-postcard-double.png';
import image6x9pc5 from 'assets/images/products/printed-postcard/6x9/6x9-3-postcard-double.png';

import image6x11pc1 from 'assets/images/products/printed-postcard/6x11/6x11-1-postcard-double.png';
import image6x11pc2 from 'assets/images/products/printed-postcard/6x11/6x11-1-postcard-rear.png';
import image6x11pc3 from 'assets/images/products/printed-postcard/6x11/6x11-2-postcard-double.png';
import image6x11pc4 from 'assets/images/products/printed-postcard/6x11/6x11-2-postcard-front.png';
import image6x11pc5 from 'assets/images/products/printed-postcard/6x11/6x11-2-postcard-rear.png';

export enum DeprecatedProductOptions {
  MediumHandwrittenCardA7 = 'Medium Handwritten Card (A7)',
}

export enum ProductDetails {
  Dimension = 'Dimension',
  HandwrittenTextLength = 'Handwritten Text Length',
  Envelope = 'Envelope',
  Stamp = 'Stamp',
}

export interface IProduct {
  id: number;
  categoryId: number;
  name: ProductOptions;
  details: { [key in ProductDetails]: { bullet: string; short?: string } };
  label: string;
  icon: string;
  priceBeforeAddons: number;
  safeZoneArea: string;
  fullBleedArea: string;
  designSpecsUrl: string;
  images: string[];
}

export interface IProductCategory {
  id: number;
  name: string;
  icon: string;
  products: IProduct[];
}

export const productCategories: IProductCategory[] = [
  {
    id: 0,
    name: 'Handwritten Card\n+ Envelope',
    icon: HandwrittenCardEnvelop,
    products: [
      {
        id: 2,
        categoryId: 0,
        name: ProductOptions.LargeHandwrittenCardA8,
        label: 'Handwritten Flat Card + Envelope',
        details: {
          [ProductDetails.Dimension]: { bullet: '5.3" x 7.5"' },
          [ProductDetails.HandwrittenTextLength]: {
            bullet: 'Up to 500 characters included in base price (800 max)',
            short: '500 Characters included, up to 800 available',
          },
          [ProductDetails.Envelope]: { bullet: 'Handwritten Envelope' },
          [ProductDetails.Stamp]: { bullet: 'Real Stamp' },
        },
        icon: 'mail',
        priceBeforeAddons: 1.7,
        safeZoneArea: '(5.1" x 7.3")',
        fullBleedArea: '(5.5" x 7.7")',
        designSpecsUrl: 'https://www.lettrlabs.com/help-guide/large-handwritten-cards-specs',
        images: [image1, image2, image3, image4, image5],
      },
      {
        id: 8,
        categoryId: 0,
        name: ProductOptions.HandwrittenBiFoldCard,
        label: 'Handwritten Bi-fold card + Envelope',
        details: {
          [ProductDetails.Dimension]: { bullet: '10.6" x 7.5"' },
          [ProductDetails.HandwrittenTextLength]: {
            bullet: 'Up to 500 characters included in base price (800 max per panel)',
            short: '500 Characters included, up to 800 per panel',
          },
          [ProductDetails.Envelope]: { bullet: 'Handwritten Envelope' },
          [ProductDetails.Stamp]: { bullet: 'Real Stamp' },
        },
        icon: 'mail',
        priceBeforeAddons: 2.05,
        safeZoneArea: '(10.4" x 7.3")',
        fullBleedArea: '(10.8" x 7.7")',
        designSpecsUrl: 'https://www.lettrlabs.com/help-guide/handwritten-bifold-cards-specs',
        images: [bifold1, bifold2, bifold3, bifold4, bifold5],
      },
    ],
  },
  {
    id: 1,
    name: 'Printed\nPostcard',
    icon: HandwrittenPostcard,
    products: [
      {
        id: 6,
        categoryId: 1,
        name: ProductOptions.PrintedPostcard4x6,
        label: 'Printed Postcard - 4"x6"',
        details: {
          [ProductDetails.Dimension]: { bullet: '4" x 6"' },
          [ProductDetails.HandwrittenTextLength]: { bullet: 'N/A' },
          [ProductDetails.Envelope]: { bullet: 'N/A' },
          [ProductDetails.Stamp]: { bullet: 'Printed Stamp' },
        },
        icon: 'rectangle',
        priceBeforeAddons: 0.61,
        safeZoneArea: '(3.7" x 5.7")',
        fullBleedArea: '(4.2" x 6.2")',
        designSpecsUrl: 'https://www.lettrlabs.com/help-guide/printed-postcard-specs-4-2-x-6-2',
        images: [image4x6pc1, image4x6pc2, image4x6pc3, image4x6pc4, image4x6pc5],
      },
      {
        id: 7,
        categoryId: 1,
        name: ProductOptions.PrintedPostcard6x9,
        label: 'Printed Postcard - 6"x9"',
        details: {
          [ProductDetails.Dimension]: { bullet: '6" x 9"' },
          [ProductDetails.HandwrittenTextLength]: { bullet: 'N/A' },
          [ProductDetails.Envelope]: { bullet: 'N/A' },
          [ProductDetails.Stamp]: { bullet: 'Printed Stamp' },
        },
        icon: 'rectangle',
        priceBeforeAddons: 0.66,
        safeZoneArea: '(5.7" x 8.7")',
        fullBleedArea: '(6.2" x 9.2")',
        designSpecsUrl: 'https://www.lettrlabs.com/help-guide/printed-postcard-specs-6-2-x-9-2',
        images: [image6x9pc1, image6x9pc2, image6x9pc3, image6x9pc4, image6x9pc5],
      },
      {
        id: 5,
        categoryId: 1,
        name: ProductOptions.PrintedPostcard6x11,
        label: 'Printed Postcard - 6"x11"',
        details: {
          [ProductDetails.Dimension]: { bullet: '6" x 11"' },
          [ProductDetails.HandwrittenTextLength]: { bullet: 'N/A' },
          [ProductDetails.Envelope]: { bullet: 'N/A' },
          [ProductDetails.Stamp]: { bullet: 'Printed Stamp' },
        },
        icon: 'rectangle',
        priceBeforeAddons: 0.92,
        safeZoneArea: '(5.7" x 10.7")',
        fullBleedArea: '(6.2" x 11.2")',
        designSpecsUrl: 'https://www.lettrlabs.com/help-guide/printed-postcard-specs-6-2-x-11-2',
        images: [image6x11pc1, image6x11pc2, image6x11pc3, image6x11pc4, image6x11pc5],
      },
    ],
  },
  {
    id: 2,
    name: 'Handwritten\nPostcard',
    icon: PrintedPostcard,
    products: [
      {
        id: 3,
        categoryId: 2,
        name: ProductOptions.HandwrittenPostCardA8,
        label: 'Handwritten Postcard',
        details: {
          [ProductDetails.Dimension]: { bullet: '5.3" x 7.8"' },
          [ProductDetails.HandwrittenTextLength]: { bullet: '350 Characters included' },
          [ProductDetails.Envelope]: { bullet: 'N/A' },
          [ProductDetails.Stamp]: { bullet: 'Real Stamp' },
        },
        icon: 'featured_play_list',
        priceBeforeAddons: 0.95,
        safeZoneArea: '(5.1" x 7.6")',
        fullBleedArea: '(5.5" x 8")',
        designSpecsUrl: 'https://www.lettrlabs.com/help-guide/handwritten-postcard-specs',
        images: [imagepc1, imagepc2, imagepc3, imagepc4, imagepc5],
      },
    ],
  },
];

export const cleanTemplate = (product: ProductOptions): Partial<ITemplateOrderIntersection> => ({
  product: product,
  font: 'Derek',
  useQr: undefined,
  qrUrl: undefined,
  returnTitle: undefined,
  returnFirstName: undefined,
  returnLastName: undefined,
  returnOrganization: undefined,
  returnAddress1: undefined,
  returnAddress2: undefined,
  returnCity: undefined,
  returnState: undefined,
  returnZip: undefined,
  text: OrderDefaults.OrderText,
  text2: product === ProductOptions.HandwrittenBiFoldCard ? OrderDefaults.OrderText2 : '',
  doubleSided: undefined,
  fontSize: 18,
  fontSizeReturnAddress: product === ProductOptions.HandwrittenPostCardA8 ? 11 : 26,
  noFrontLogo: undefined,
  qrCodeY: undefined,
  qrCodeX: undefined,
  qrCodeWidth: undefined,
  qrCodeHeight: undefined,
  qrAngle: undefined,
  trackingPhoneNumber: undefined,
  hasDiscount: false,
  couponListId: undefined,
  multiUseCouponId: undefined,
  qrCodeSide: undefined,
  orderArtifacts: [],
});
