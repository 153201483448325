import { Suspense } from 'react';
import { Box, Stack } from '@mui/material';

import { TooltipTutorialtype } from 'components/tooltip-tutorial/tooltip-tutorial.util';
import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { ProductOptions } from 'newStandard/src/services/order/types';
import TooltipTutorial from 'components/tooltip-tutorial';
import MDButton from 'material-ui/components/MDButton';
import canvaLogo from 'assets/images/canva-logo.png';

import { useEditorContext } from '../../contexts/useEditorContext';
import { checkIsPrintedCard } from '../../utils/templateHelper';
import TrackingAndAttribution from './TrackingAndAttribution';
import HandwritingStyle from './HandwritingStyle';
import MailMergeFields from './MailMergeFields';
import CardFormatting from './CardFormatting';
import PanelWrapper from '../PanelWrapper';

export default function LettrLabsPanel() {
  const { template } = useWorkflowContext();
  const { engine, setLettrLabsPanel } = useEditorContext();

  const handleCanvaClick = () => {
    const canvaUrls: { [key: string]: string } = {
      [ProductOptions.PrintedPostcard4x6]:
        'https://www.canva.com/design/DAGHXtYzF18/G66n89YUYopEs8hUuibm3g/view?utm_content=DA[…]share&utm_medium=link&utm_source=publishsharelink&mode=preview',
      [ProductOptions.PrintedPostcard6x9]:
        'https://www.canva.com/design/DAGHT6JGWAY/pO7InGOAI_OstmgKXuNt6g/view?utm_content=DA[…]share&utm_medium=link&utm_source=publishsharelink&mode=preview',
      [ProductOptions.PrintedPostcard6x11]:
        'https://www.canva.com/design/DAGHXsO1iwI/Mbb4BeYcV3tAVNNpyIOkDQ/view?utm_content=DA[…]share&utm_medium=link&utm_source=publishsharelink&mode=preview',
      [ProductOptions.LargeHandwrittenCardA8]:
        'https://www.canva.com/design/DAGHX5G9G0Y/XzeloLzVVGqN2_zqtigxXg/view?utm_content=DA[…]share&utm_medium=link&utm_source=publishsharelink&mode=preview',
      [ProductOptions.HandwrittenPostCardA8]:
        'https://www.canva.com/design/DAGHX24AM60/igz6x4lwdO52oihBp5i4pg/view?utm_content=DA[…]share&utm_medium=link&utm_source=publishsharelink&mode=preview',
      [ProductOptions.HandwrittenBiFoldCard]:
        'https://www.canva.com/design/DAGVJvZyxbk/mWvhOwgmVgRgsVUku9eUQg/view?utm_content=DA[…]share&utm_medium=link&utm_source=publishsharelink&mode=preview',
    };
    window.open(canvaUrls[template.product], '_blank', 'noopener,noreferrer');
  };

  const handlePanelClose = () => {
    engine?.variable?.setString('lettrLabs', 'false');
    setLettrLabsPanel(false);
  };

  return (
    <PanelWrapper title="LettrLabs" onPanelClose={handlePanelClose}>
      <Stack p={2} spacing={{ xs: 2, xl: 4 }} height={'100%'} overflow={'auto'}>
        {!checkIsPrintedCard(template.product) && (
          <Suspense fallback={<HandwritingStyle.Skeleton />}>
            <HandwritingStyle />
          </Suspense>
        )}
        <CardFormatting />
        <TrackingAndAttribution />
        {!checkIsPrintedCard(template.product) && <MailMergeFields />}
        <Box flex={1} />

        <TooltipTutorial
          keepTooltip
          tutorialName={TooltipTutorialtype.CanvaEditableTemplateTutorial}
          title="Want to create good looking templates?"
          description="Check this Canva to learn how!"
        >
          <MDButton fullWidth color="light" onClick={handleCanvaClick}>
            <img src={canvaLogo} alt="canva" height={20} style={{ marginRight: 12 }} />
            Editable Template
          </MDButton>
        </TooltipTutorial>
      </Stack>
    </PanelWrapper>
  );
}
