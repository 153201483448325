import API_ENDPOINT from 'services/endpoint';
import useFetchWithMsal from 'hooks/useFetch';

import { IArtifact } from '../artifact/types';
import { UploadSceneProps } from './types';

export default function useSceneService() {
  const { execute } = useFetchWithMsal();

  const clearScene = (id: number, productId: number) => {
    return execute({ endpoint: `${API_ENDPOINT}/clearScene/${id}/${productId}`, method: 'POST' });
  };

  const uploadScene = ({ id, sceneType, formData }: UploadSceneProps): Promise<IArtifact> => {
    return execute({ endpoint: `${API_ENDPOINT}/scene/${id}/${sceneType}`, method: 'POST', formData });
  };

  return { clearScene, uploadScene };
}
