import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";
import {useQuery} from "@tanstack/react-query";
import TemplateService from "services/template";
import {Grid} from "@mui/material";
import React, { useEffect, useState} from "react";
import TemplateCard from "components/template-card";
import {Template} from "models/template";
import AddTemplateCard from "components/add-template-card";
import { useGlobal } from "context/global-context";

function MyTemplatesPage() {
    const {setShowLoader} = useGlobal()
    const {getTemplates} = TemplateService()

    const [columns, setColumns] = useState<number | null>(null)
    const [windowWidth, setWindowWidth] = useState<number>(0)

    const getTemplatesQuery = useQuery({
        queryKey: ["templates", false],
        queryFn: () => getTemplates()
    })

    useEffect(() => {
        setShowLoader(getTemplatesQuery.isLoading)
    }, [getTemplatesQuery.isLoading, setShowLoader]);

    useEffect(() => {
        if(!windowWidth){
            return
        }

        if(windowWidth >= 2300){
            setColumns(5)
        }
        else if(windowWidth >= 1900){
            setColumns(4)
        }
        else if(windowWidth >= 1300){
            setColumns(3)
        }
        else if(windowWidth >= 1000){
            setColumns(2)
        }
        else{
            setColumns(1)
        }
    }, [windowWidth]);

    useEffect(() => {
        setWindowWidth(window.innerWidth)

        window.addEventListener("resize", function () {
            setWindowWidth(window.innerWidth)
        })
    }, []);

    if(!columns){
        return null
    }

    return <MDBox>
        <MDCard>
            <MDBox p={2} width={"100%"}>
                <Grid container>
                    <Grid item xs={12 / columns} p={1}>
                        <AddTemplateCard/>
                    </Grid>

                    {getTemplatesQuery.data?.map((template: Template, index: number) => (
                        <Grid item key={index} xs={12 / columns} p={1}>
                            <TemplateCard template={template}/>
                        </Grid>
                    ))}
                </Grid>
            </MDBox>
        </MDCard>
    </MDBox>
}

export default MyTemplatesPage