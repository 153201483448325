import { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { ZoomIn, ZoomOut } from '@mui/icons-material';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';

import { useEditorContext } from '../contexts/useEditorContext';
import { checkHasEnvelope } from '../utils/templateHelper';

export default function ZoomButtons() {
  const { template } = useWorkflowContext();
  const { engine, envelopeEngine } = useEditorContext();

  const [zoom, setZoom] = useState<number>();

  useEffect(() => {
    if (!engine || (checkHasEnvelope(template.product) && !envelopeEngine)) return;
    const reaction = engine.reactor.createReaction();
    const unsubscribe = reaction?.subscribe(() => {
      try {
        reaction.track(() => {
          const newZoomValue = engine.scene.getZoomLevel();
          setZoom(newZoomValue);
          if (checkHasEnvelope(template.product) && envelopeEngine) envelopeEngine.scene.setZoomLevel(newZoomValue);
        });
      } catch (e) {
        console.error('Error in reaction', e);
      }
    });

    return () => {
      try {
        unsubscribe?.();
        reaction?.dispose();
      } catch (e) {
        console.error('Error in cleanup', e);
      }
    };
  }, [engine, envelopeEngine, template.product]);

  if(zoom === undefined) return null;
  return (
    <Box display={'flex'} position={'absolute'} top={70} right={320}>
      <IconButton disabled={zoom <= 0.2} onClick={() => engine?.scene.setZoomLevel(zoom - 0.1)}>
        <ZoomOut />
      </IconButton>
      <IconButton onClick={() => engine?.scene.setZoomLevel(zoom + 0.1)}>
        <ZoomIn />
      </IconButton>
    </Box>
  );
}
