import { Phone, QrCode } from '@mui/icons-material';
import { Box, Card, CardActionArea, Paper, Stack, Typography } from '@mui/material';

interface CardButtonProps {
  label: string;
  isSelected?: boolean;
  hasQrCode?: boolean;
  hasCallTracking?: boolean;
  onClick: () => void;
}

export default function CardButton({ label, isSelected, hasCallTracking, hasQrCode, onClick }: CardButtonProps) {
  const cardSx = {
    backgroundColor: isSelected ? '#FFF' : '#F05A2AD9',
    border: isSelected ? `2px solid #F05A2A` : undefined,
  };

  return (
    <Card onClick={onClick} sx={cardSx}>
      <CardActionArea>
        <Box height={72} alignContent={'center'} textAlign={'center'} position={'relative'}>
          <Typography variant="h4" color={isSelected ? undefined : '#FFF'}>
            {label}
          </Typography>
          <Stack position={'absolute'} top={0} right={0} lineHeight={0} pr={0.6} py={1} spacing={0.5}>
            {hasQrCode && (
              <Paper variant="outlined">
                <QrCode />
              </Paper>
            )}
            {hasCallTracking && (
              <Paper variant="outlined">
                <Phone />
              </Paper>
            )}
          </Stack>
        </Box>
      </CardActionArea>
    </Card>
  );
}
