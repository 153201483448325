import MDCard from "material-ui/components/MDCard";
import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import {Box} from "@mui/material";
import MDButton from "material-ui/components/MDButton";
import { useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import IntegrationOrderService from "services/integration-order";
import ProfileService from "services/profile";
import NoOrdersBlock from "./components/no-orders-block";
import IntegrationOrdersGrid from "./components/integration-orders-grid";
import {useNavigate} from "react-router-dom";
import { ITemplate } from "models/template";
import IntegrationOrderModal from "components/integration-order-modal";
import PlusIcon from "assets/icons/plus";
import SettingsIcon from '@mui/icons-material/Settings';
import { useGlobal } from "context/global-context";

function IntegrationOrdersPage() {
    const navigate = useNavigate()
    const {setShowLoader, setTemplateFlow} = useGlobal()

    const {getProfile} = ProfileService()
    const {getAllIntegrationOrders} = IntegrationOrderService()

  const [showCreateNewIntegrationOrderModal, setshowCreateNewIntegrationOrderModal] = useState<boolean>(false)
    const [template, setTemplate] = useState<ITemplate>()

    const getAllIntegrationOrdersQuery = useQuery({
        queryFn: () => {
            return getAllIntegrationOrders()
        },
        queryKey: ["allIntegrationOrders"],
        refetchOnWindowFocus: false
    })

    const getProfileQuery = useQuery({
        queryKey: ["profile"],
        queryFn: () => {
            return getProfile()
        },
    })

    useEffect(() => {
        setShowLoader(!!getAllIntegrationOrdersQuery.isLoading)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllIntegrationOrdersQuery.isLoading]);

    const handleCreateNew = () => {
            setTemplateFlow({ 
            showModal: true, 
            shouldCheckNotSupported: true,
            originalRoute: '/integration-orders',
            customCreateCallback:() => {
                setTemplate(undefined)
                setshowCreateNewIntegrationOrderModal(true)
            },
            onTemplateChange: (newTemplate) => {
                setTemplate(newTemplate)
                setshowCreateNewIntegrationOrderModal(true)
            },
        })
    }

    if(getAllIntegrationOrdersQuery.isLoading || getProfileQuery.isLoading) return null
    return (
        <MDCard>
            <MDBox p={3}>
                <MDBox mb={5}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <MDTypography fontWeight={"bold"} sx={{fontSize: "26px"}}>Integration Orders</MDTypography>
                        <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
                            <MDButton color="light" onClick={() => navigate("/integration-orders/setup")}>
                                <SettingsIcon style={{marginRight: 4}} /> Setup Zapier
                            </MDButton>
                            <MDButton onClick={handleCreateNew}>
                                <PlusIcon style={{marginRight: 4}} /> New integration order
                            </MDButton>
                        </Box>
                    </Box>
                    <MDBox mt={3}>
                        {getAllIntegrationOrdersQuery.data.payload.integrationOrders.length?
                            <IntegrationOrdersGrid
                                data={getAllIntegrationOrdersQuery.data.payload.integrationOrders}
                                gridHeight={''}
                                isLoading={getAllIntegrationOrdersQuery.isFetching}
                            />
                            :
                            <NoOrdersBlock
                                description={
                                    <MDTypography sx={{fontSize: 18}} fontWeight={"bold"}>You don't have any integration orders yet. Create one from your templates by clicking
                                        <MDBox component="a" onClick={handleCreateNew} style={{display: "inline-block", marginLeft: "4px"}}>
                                            <MDTypography color={"primary"} fontWeight={"bold"} sx={{fontSize: "18px"}} isLink={true} linkHoverColor={"primary"}>here</MDTypography>
                                        </MDBox>
                                    </MDTypography>
                                }
                            />
                        }
                    </MDBox>
                </MDBox>
            </MDBox>
            <IntegrationOrderModal
                show={showCreateNewIntegrationOrderModal}
                setShow={setshowCreateNewIntegrationOrderModal}
                template={template}
            />
        </MDCard>
    )
}

export default IntegrationOrdersPage