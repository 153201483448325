import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PaymentMethod } from '@stripe/stripe-js';
import { Box, Collapse, FormControlLabel, Grid, Paper, Radio, RadioGroup, Skeleton, Typography } from '@mui/material';

import { StripePaymentForm } from 'components/stripe-payment-container';
import RadioSelectWrapper from 'components/radio-select-wrapper';
import PaymentCard from 'components/payment-card';
import ProfileService from 'services/profile';
import { ICheckout } from 'models/checkout';

interface SelectPaymentMethodProps {
  checkout?: ICheckout;
  selectedCard?: PaymentMethod;
  setSelectedCard: (selectedCard?: PaymentMethod) => void;
}

export default function SelectPaymentMethod({ checkout, selectedCard, setSelectedCard }: SelectPaymentMethodProps) {
  const [defaultPayment, setDefaultPayment] = useState<PaymentMethod>();

  const { getProfile } = ProfileService();
  const { data: profile } = useQuery({ queryKey: ['profile'], queryFn: () => getProfile() });

  useEffect(() => {
    if (!checkout?.savedPaymentMethods.length) return;
    let auxDefault = checkout.savedPaymentMethods.find((el) => profile?.defaultPaymentMethodId === el.id);
    auxDefault = auxDefault ?? checkout.savedPaymentMethods[0];
    setDefaultPayment(auxDefault);
    setSelectedCard(auxDefault);
  }, [checkout?.savedPaymentMethods, profile?.defaultPaymentMethodId, setSelectedCard]);

  return (
    <Paper variant="outlined" sx={{ borderRadius: 4, py: 1, px: 2, mt: 2 }}>
      <Typography variant="h4" mb={1}>
        Payment Method
      </Typography>
      <Box>
        <RadioGroup value={selectedCard} sx={{ flexWrap: 'nowrap', mb: 1 }}>
          <Box display={'flex'} gap={1}>
            {checkout ? (
              <>
                <RadioSelectWrapper selected={!!selectedCard} padding={'2px 10px'}>
                  <FormControlLabel
                    control={<Radio />}
                    checked={!!selectedCard}
                    label={'Charge card on file'}
                    onClick={() => setSelectedCard(defaultPayment)}
                    disabled={!checkout.savedPaymentMethods.length}
                    sx={{ '.MuiFormControlLabel-label': { fontSize: { xs: 14, xl: 16 } } }}
                  />
                </RadioSelectWrapper>
                <RadioSelectWrapper
                  selected={!selectedCard}
                  onClick={() => setSelectedCard(undefined)}
                  padding={'2px 10px'}
                >
                  <FormControlLabel
                    value={null}
                    control={<Radio />}
                    checked={!selectedCard}
                    label={'New payment info'}
                    sx={{ '.MuiFormControlLabel-label': { fontSize: { xs: 14, xl: 16 } } }}
                  />
                </RadioSelectWrapper>
              </>
            ) : (
              <>
                <Skeleton width={'100%'} height={46} variant="rounded" />
                <Skeleton width={'100%'} height={46} variant="rounded" />
              </>
            )}
          </Box>
        </RadioGroup>
        <Collapse in={!!selectedCard}>
          <Grid container spacing={1}>
            {checkout?.savedPaymentMethods.map((card, index) => (
              <Grid item xs={12} md={6} key={index} sx={{ cursor: 'pointer' }}>
                <PaymentCard
                  card={card}
                  selected={selectedCard?.id === card.id}
                  isDefault={profile.defaultPaymentMethodId === card.id}
                  onClick={() => setSelectedCard(card)}
                  sx={{ aspectRatio: 2 / 1, width: '100%', minWidth: 260 }}
                />
              </Grid>
            ))}
          </Grid>
        </Collapse>
        {checkout?.stripeToken && (
          <Collapse in={!selectedCard}>
            <StripePaymentForm />
          </Collapse>
        )}
      </Box>
    </Paper>
  );
}
