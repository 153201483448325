import { useCallback, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { InfoOutlined } from '@mui/icons-material';
import { Box, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { formatCamelCase } from 'newStandard/src/utils/format';
import { useGlobal } from 'context/global-context';
import OrderService from 'services/order';

import {
  MailMergeFieldType,
  mailMergeFieldsOptions,
  getValidMailMergeFields,
  shouldUpdateMailMergeFields,
} from '../../utils/mailMergeOptions';
import useTemplateTextUpdate from '../../hooks/useTemplateTextUpdate';
import { useEditorContext } from '../../contexts/useEditorContext';
import { BlockNames } from '../../utils/sceneEnums';

export default function MailMergeFields() {
  const { allowDiscount } = useGlobal();
  const { template } = useWorkflowContext();
  const { getOrderParameters } = OrderService();
  const { updateTemplateText } = useTemplateTextUpdate();
  const { engine, textCursor, mailMergeFields, setMailMergeFields, isApplyingTemplateId, setIsApplyingTemplateId } =
    useEditorContext();

  const { data: { payload: parameters } = {}, isFetched } = useQuery({
    queryKey: ['orderParameters', isApplyingTemplateId ?? template.id],
    queryFn: () => getOrderParameters(isApplyingTemplateId ?? template.id),
  });

  useEffect(() => {
    if (isApplyingTemplateId && isFetched) setMailMergeFields(undefined);
  }, [isApplyingTemplateId, isFetched, setMailMergeFields]);

  useEffect(() => {
    if (!parameters || mailMergeFields) return;
    const updatedMap = new Map();
    const usedFields = new Set([...getValidMailMergeFields(template.text), ...getValidMailMergeFields(template.text2)]);
    usedFields.forEach((field) => {
      const fallback = parameters.find((p) => p.parameterType === field);
      updatedMap.set(field, fallback?.parameterText ?? '');
    });
    setMailMergeFields(updatedMap);
    setIsApplyingTemplateId(undefined);
  }, [parameters, template.text, template.text2, mailMergeFields, setMailMergeFields, setIsApplyingTemplateId]);

  useEffect(() => {
    const usedFields = new Set([...getValidMailMergeFields(template.text), ...getValidMailMergeFields(template.text2)]);
    setMailMergeFields((prevMap) => {
      if (!prevMap) return prevMap;
      const updatedMap = new Map(prevMap);
      updatedMap.forEach((_, key) => !usedFields.has(key) && updatedMap.delete(key));
      usedFields.forEach((field) => !updatedMap.has(field) && updatedMap.set(field, ''));
      return shouldUpdateMailMergeFields(updatedMap, prevMap) ? updatedMap : prevMap;
    });
  }, [setMailMergeFields, template.text, template.text2]);

  const updateParameterText = (newParameterText: string, key: MailMergeFieldType) => {
    setMailMergeFields((prevMap) => {
      const updatedMap = new Map(prevMap);
      updatedMap.set(key, newParameterText);
      return updatedMap;
    });
  };

  const handleSelectMailMergeField = useCallback(
    (field: MailMergeFieldType) => {
      if (!engine?.block) return;
      const isBlockValid = engine.block.isValid(textCursor.block);
      const block = isBlockValid ? textCursor.block : engine.block.findByName(BlockNames.HandwrittenText)[0];
      engine.block.replaceText(block, '{{' + field + '}}', textCursor.from, textCursor.to);
      updateTemplateText();
      setMailMergeFields((prevMap) => {
        const updatedMap = new Map(prevMap);
        if (updatedMap.has(field)) return prevMap;
        updatedMap.set(field, '');
        return updatedMap;
      });
    },
    [engine?.block, setMailMergeFields, textCursor, updateTemplateText]
  );

  if (!engine) return null;
  return (
    <Stack spacing={1}>
      <Box display={'flex'} gap={1} alignItems={'center'}>
        <Typography variant="body2" fontWeight={'bold'}>
          Mail-Merge Fields
        </Typography>
        <Tooltip
          componentsProps={{ tooltip: { sx: { width: 450, maxWidth: 'unset' } } }}
          title="Mail merge fields are variables that will be replaced with your upload file. This allows you to personalize each card simply based on the data available. Additionally, fallback values will be used when the data file upload is missing data for a given individual, ensuring there are no unintended blank spaces on your card."
        >
          <InfoOutlined sx={{ height: 20 }} />
        </Tooltip>
      </Box>
      {mailMergeFieldsOptions.map(({ title, fields }) => (
        <Stack key={title} spacing={0.5}>
          <Select
            fullWidth
            value={''}
            displayEmpty
            onChange={(e) => handleSelectMailMergeField(e.target.value as MailMergeFieldType)}
          >
            <MenuItem disabled value="">
              {title}
            </MenuItem>
            {fields.map((field, index) => {
              if (field === 'DISCOUNT CODE' && !allowDiscount) return null;
              return (
                <MenuItem key={index} value={field}>
                  {formatCamelCase(field)}
                </MenuItem>
              );
            })}
          </Select>
          {[...(mailMergeFields?.entries() ?? [])].map(([parameterType, parameterText]) => {
            if (!fields.includes(parameterType)) return null;
            return (
              <Box key={parameterType} display={'flex'} gap={1} alignItems={'center'} ml={2}>
                <Typography minWidth={'130px'} variant={'h5'} fontWeight={'bold'}>
                  {formatCamelCase(parameterType)}
                </Typography>
                {parameterType === 'DISCOUNT CODE' ? (
                  <Typography variant="caption">Available for Klaviyo and single orders</Typography>
                ) : (
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={'Fallback Value'}
                    defaultValue={parameterText}
                    onBlur={(e) => updateParameterText(e.target.value, parameterType)}
                  />
                )}
              </Box>
            );
          })}
        </Stack>
      ))}
    </Stack>
  );
}
