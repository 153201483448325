// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import { Circle, GoogleMap, LoadScript, OverlayView, Polygon } from '@react-google-maps/api';
import {
  Box,
  CircularProgress,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MDIconButton from 'material-ui/components/MDIconButton';
import colors from 'material-ui/theme/base/colors';
import { Coordinate } from 'models/coordinate';
import {
  getCenterOfPolygon,
  getCoordinatesAddress,
  mapContainerStyle,
  mapOptions,
  mapInfoTextContainer,
} from 'helpers/google-map-helper';
import RadiusIcon from 'assets/icons/radius';
import MDButton from 'material-ui/components/MDButton';
import PolygonIcon from 'assets/icons/polygon';
import CloseIcon from 'assets/icons/close';
import { IAddressRadiusSearch, IMoversSchema, IPolygonSearch } from 'services/smart-automation/smart-automation.types';
import MoversMapRow from './movers-map-row';
import FormikErrorMessage from 'components/formik-error-message';
import { Map, UploadOutlined } from '@mui/icons-material';
import { Field, FieldArray, FieldProps, useFormikContext } from 'formik';
import { useMoversContext } from 'context/movers-context';
import { CSVBoxButton } from '@csvbox/react';
import { useParams } from 'react-router-dom';
import useSmartAutomationService from 'services/smart-automation';
import { useMutation, useQuery } from '@tanstack/react-query';

interface IAddressData {
  shortAddress: string;
}

const MAX_ADDRESSES = 50;
const MIN_CIRCLE_RADIUS = 0.5;
const MAX_CIRCLE_RADIUS = 20;

const MoversMap = () => {
  const { id: automationId } = useParams();
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const { calculateZipcodes, getMoversZipPoll } = useSmartAutomationService();
  const { disabled, targetsCalculated, setTargetsCalculated, mapTouched, setMapTouched, pollingZips, setPollingZips } =
    useMoversContext();
  const { values, setValues, setFieldValue, errors } = useFormikContext<IMoversSchema>();
  const error = typeof errors.addressRadiusSearches === 'string' ? errors.addressRadiusSearches : undefined;

  const [isMapCollapsed, setIsMapCollapsed] = useState<boolean>(false);
  const [map, setMap] = useState<google.maps.Map>(null);
  const [hasMapLoaded, setHasMapLoaded] = useState<boolean>(false);
  const [geocoder, setGeocoder] = useState(null);
  const [center, setCenter] = useState<Coordinate>({ lat: 33.824386, lng: -118.1358666 });
  const [zoom, setZoom] = useState<number>(10);
  const [isAdding, setIsAdding] = useState<string>('');
  const [polygonsHelper, setPolygonsHelper] = useState<google.maps.Polygon[]>([]);
  const [circlesHelper, setCirclesHelper] = useState<google.maps.Circle[]>([]);

  const showSlider = values.filter.lookBackMonths && targetsCalculated;
  const mapRowDefaultProps = { map, setZoom, polygonsHelper, setPolygonsHelper, circlesHelper, setCirclesHelper };
  const isMaxAddresses = values.addressRadiusSearches?.length + values.polygonSearches?.length >= MAX_ADDRESSES;

  useEffect(() => {
    if (targetsCalculated) setIsAdding('');
  }, [targetsCalculated]);

  useEffect(() => {
    if (automationId !== 'new') setIsAdding('');
  }, [automationId]);

  const { mutate: calculate } = useMutation({
    mutationFn: calculateZipcodes,
    onMutate: () => setPollingZips(true),
    onSuccess: () => startPolling(),
  });

  const { refetch: poll } = useQuery({
    queryFn: () => getMoversZipPoll(automationId),
    queryKey: ['getMoversZipPoll'],
    enabled: false,
  });

  const startPolling = async () => {
    const interval = setInterval(async () => {
      const { data } = await poll();
      const polygons = data?.payload.polygonSearches || [];
      if (polygons.length) {
        setFieldValue(`polygonSearches`, [...values.polygonSearches, ...polygons]);
        clearInterval(interval);
        setPollingZips(false);
        setTargetsCalculated(false);
        map.setCenter(getCenterOfPolygon(polygons[0].polygonCriteria.coordinates));
      }
    }, 5000);
  };

  const handleMapLoad = () => {
    setHasMapLoaded(true);
    setGeocoder(new window.google.maps.Geocoder());
    navigator.geolocation?.getCurrentPosition(
      (pos: GeolocationPosition) => setCenter({ lat: pos.coords.latitude, lng: pos.coords.longitude }),
      () => setCenter({ lat: 33.824386, lng: -118.1358666 })
    );
  };

  const handleMapClick = (e: google.maps.MapMouseEvent) => {
    if (!isAdding) return;
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    getCoordinatesAddress(geocoder, lat, lng, (addressData: IAddressData) => {
      if (isAdding === 'radius') {
        const newAddress: IAddressRadiusSearch = {
          name: addressData.shortAddress,
          areaCriteria: { coordinate: { lat, lng }, radius: 2 },
        };
        setValues({
          ...values,
          addressRadiusSearches: [...values.addressRadiusSearches, newAddress],
        });
      } else {
        const offset = 0.02898550724; // Approximate offset for the 4 points around the center (2 miles)
        const newPolygon: IPolygonSearch = {
          name: addressData.shortAddress,
          polygonCriteria: {
            coordinates: [
              { lat: lat, lng: lng - offset },
              { lat: lat - offset, lng: lng },
              { lat: lat, lng: lng + offset },
              { lat: lat + offset, lng: lng },
            ],
          },
        };
        setValues({ ...values, polygonSearches: [...values.polygonSearches, newPolygon] });
      }
      setTargetsCalculated(false);
      if (!mapTouched) setMapTouched(true);
    });
  };

  const handlePolygonEdit = (index: number) => {
    const polygonToEdit = polygonsHelper[index];
    const updatedPath = polygonToEdit
      .getPath()
      .getArray()
      .map((coordinate: google.maps.LatLng) => ({ lat: coordinate.lat(), lng: coordinate.lng() }));
    const centerOfPolygon = getCenterOfPolygon(updatedPath);
    getCoordinatesAddress(geocoder, centerOfPolygon.lat, centerOfPolygon.lng, (addressData: IAddressData) => {
      const auxSelecteds = { ...values };
      auxSelecteds.polygonSearches[index].polygonCriteria.coordinates = updatedPath;
      auxSelecteds.polygonSearches[index].name = addressData.shortAddress;
      setValues(auxSelecteds);
      setTargetsCalculated(false);
      if (!mapTouched) setMapTouched(true);
    });
  };

  const handleCircleDragEnd = (e: google.maps.MapMouseEvent, index: number) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    getCoordinatesAddress(geocoder, lat, lng, (addressData: IAddressData) => {
      const auxSelecteds = { ...values };
      auxSelecteds.addressRadiusSearches[index].areaCriteria.coordinate.lat = lat;
      auxSelecteds.addressRadiusSearches[index].areaCriteria.coordinate.lng = lng;
      auxSelecteds.addressRadiusSearches[index].name = addressData.shortAddress;
      setValues(auxSelecteds);
      setTargetsCalculated(false);
      if (!mapTouched) setMapTouched(true);
    });
  };

  const handleRadiusChanged = (index: number) => {
    // Function with debounce because onRadiusChanged is called for each inch changed on drag
    clearTimeout(timerRef.current);
    if (circlesHelper[index]) {
      let radius = Math.floor(circlesHelper[index].getRadius() / 1609.34);
      if (radius < MIN_CIRCLE_RADIUS) radius = MIN_CIRCLE_RADIUS;
      else if (radius > MAX_CIRCLE_RADIUS) {
        // Even if I don't update the actual radius, on the second time that I try to exceed the max allowed, the map updates it
        // Bellow I did a workaround to make sure it will never be over the MAX_CIRCLE_RADIUS
        radius =
          values.addressRadiusSearches[index].areaCriteria.radius === MAX_CIRCLE_RADIUS
            ? MAX_CIRCLE_RADIUS + 0.01
            : MAX_CIRCLE_RADIUS;
      }
      timerRef.current = setTimeout(() => {
        const auxSelecteds = { ...values };
        auxSelecteds.addressRadiusSearches[index].areaCriteria.radius = radius;
        setValues(auxSelecteds);
        setTargetsCalculated(false);
        if (!mapTouched) setMapTouched(true);
      }, 100);
    }
  };

  const renderOverlayText = (name: string) => (
    <Typography
      fontWeight={'bold'}
      color={'whitesmoke'}
      textAlign={'center'}
      whiteSpace={'nowrap'}
      sx={{ textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black' }}
    >
      {name}
    </Typography>
  );

  return (
    <Box flex={1}>
      <LoadScript googleMapsApiKey="AIzaSyAnZBcPxYjFTJ2p5VykxLnOcqM0gY4uIY0" onLoad={handleMapLoad}>
        {hasMapLoaded && (
          <Collapse in={!isMapCollapsed} collapsedSize={70} sx={{ mb: 2 }}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height="480px" position={'relative'}>
              <Box display={'flex'} position={'absolute'} zIndex={1} top={12} left={12} gap={1}>
                {isAdding && !isMaxAddresses ? (
                  <>
                    <MDButton color={isAdding === 'radius' ? 'primary' : 'light'} onClick={() => setIsAdding('radius')}>
                      <RadiusIcon sx={{ mr: 1 }} />
                      Radius
                    </MDButton>
                    <MDButton
                      color={isAdding === 'polygon' ? 'primary' : 'light'}
                      onClick={() => setIsAdding('polygon')}
                    >
                      <PolygonIcon sx={{ mr: 1 }} />
                      Polygon
                    </MDButton>

                    <CSVBoxButton
                      licenseKey={process.env.REACT_APP_CSVBOX_SMART_AUTOMATION_ZIPCODES}
                      user={{ user_id: 'LettrLabsCsvBoxAdmin', smartAutomation_id: automationId }}
                      onImport={() => calculate(automationId)}
                      render={(launch) =>
                        pollingZips ? (
                          <MDButton color="light" sx={{ minWidth: 130 }}>
                            <CircularProgress size={20} />
                          </MDButton>
                        ) : (
                          <MDButton
                            color="light"
                            onClick={launch}
                            disabled={automationId === 'new'}
                            sx={{ minWidth: 130 }}
                          >
                            <UploadOutlined sx={{ mr: 1 }} />
                            Upload
                          </MDButton>
                        )
                      }
                    />
                    <MDIconButton backgroundColor="light" onClick={() => setIsAdding('')}>
                      <CloseIcon />
                    </MDIconButton>
                  </>
                ) : (
                  <>
                    {!disabled && !isMaxAddresses && (
                      <MDButton
                        color="primary"
                        onClick={() => {
                          setIsMapCollapsed(false);
                          setIsAdding('radius');
                        }}
                      >
                        <RadiusIcon sx={{ mr: 1 }} />
                        Establish Service Area
                      </MDButton>
                    )}
                    <MDButton color="light" onClick={() => setIsMapCollapsed(!isMapCollapsed)}>
                      <Map sx={{ mr: 1 }} />
                      {isMapCollapsed ? 'Expand Map' : 'Collapse Map'}
                    </MDButton>
                  </>
                )}
              </Box>
              <GoogleMap
                zoom={zoom}
                options={mapOptions}
                center={center}
                mapContainerStyle={mapContainerStyle}
                onZoomChanged={() => (map ? setZoom(map.getZoom()) : null)}
                onLoad={(map) => setMap(map)}
                onClick={handleMapClick}
              >
                <Box
                  sx={{
                    ...mapInfoTextContainer,
                    transform: isAdding && !isMaxAddresses ? 'translateY(0px)' : 'translateY(50px)',
                  }}
                >
                  <Typography fontSize={17} fontWeight={'normal'} color={'whitesmoke'}>
                    Click anywhere on the map to add an {isAdding === 'radius' ? 'address' : 'polygon'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    ...mapInfoTextContainer,
                    backgroundColor: '#a20d0dcc',
                    transform: isMaxAddresses ? 'translateY(0px)' : 'translateY(50px)',
                  }}
                >
                  <Typography fontSize={17} fontWeight={'normal'} color={'whitesmoke'}>
                    You've reached the maximum limit of {MAX_ADDRESSES} addresses
                  </Typography>
                </Box>
                {values.addressRadiusSearches.map((address, index) => (
                  <Box key={index}>
                    <OverlayView
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      getPixelPositionOffset={(width, height) => ({ x: -(width / 2), y: -(height / 2) })}
                      position={{
                        lat: address.areaCriteria.coordinate.lat,
                        lng: address.areaCriteria.coordinate.lng,
                      }}
                    >
                      {renderOverlayText(address.name)}
                    </OverlayView>
                    <Circle
                      editable={!disabled}
                      draggable={!disabled}
                      center={{ lat: address.areaCriteria.coordinate.lat, lng: address.areaCriteria.coordinate.lng }}
                      radius={address.areaCriteria.radius * 1609.34}
                      options={{
                        fillColor: colors.primary.main,
                        fillOpacity: 0.3,
                        strokeColor: colors.primary.main,
                        strokeOpacity: 0.8,
                      }}
                      onDragEnd={(e) => handleCircleDragEnd(e, index)}
                      onLoad={(c) => setCirclesHelper((prev) => [...prev, c])}
                      onRadiusChanged={() => handleRadiusChanged(index)}
                    />
                  </Box>
                ))}

                {values.polygonSearches.map((polygon, index) => (
                  <Box key={index}>
                    <OverlayView
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      getPixelPositionOffset={(width, height) => ({ x: -(width / 2), y: -(height / 2) })}
                      position={getCenterOfPolygon(polygon.polygonCriteria.coordinates)}
                    >
                      {renderOverlayText(polygon.name)}
                    </OverlayView>
                    <Polygon
                      key={index}
                      editable={!disabled}
                      draggable={!disabled}
                      path={polygon.polygonCriteria.coordinates}
                      options={{
                        fillColor: colors.primary.main,
                        fillOpacity: 0.4,
                        strokeColor: colors.primary.main,
                        strokeOpacity: 1,
                        strokeWeight: 3,
                      }}
                      onLoad={(p) => setPolygonsHelper((prev) => [...prev, p])}
                      onUnmount={() => setPolygonsHelper([])}
                      onMouseUp={() => handlePolygonEdit(index)}
                      onDragEnd={() => handlePolygonEdit(index)}
                    />
                  </Box>
                ))}
              </GoogleMap>
            </Box>
          </Collapse>
        )}
      </LoadScript>

      <TableContainer component={Paper} variant="outlined" sx={!!error && mapTouched && { borderColor: 'red' }}>
        <Table>
          <TableHead>
            <TableRow>
              {showSlider && <TableCell />}
              <TableCell>Service Area</TableCell>
              <TableCell align="center">Search Type</TableCell>
              <TableCell align="center">
                Avg. {values.filter.ownerOrRenterFilter === 1 ? 'Renters' : 'Movers'} per Month
              </TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray
              name={'addressRadiusSearches'}
              render={() =>
                values.addressRadiusSearches?.map((row, index) => (
                  <Field key={row.name} name={`addressRadiusSearches.${index}.desiredQuantity`}>
                    {(fieldProps: FieldProps) => (
                      <MoversMapRow row={row} index={index} type="Radius" {...mapRowDefaultProps} {...fieldProps} />
                    )}
                  </Field>
                ))
              }
            />
            <FieldArray
              name={'polygonSearches'}
              render={() =>
                values.polygonSearches?.map((row, index) => (
                  <Field key={row.name} name={`polygonSearches.${index}.desiredQuantity`}>
                    {(fieldProps: FieldProps) => (
                      <MoversMapRow row={row} index={index} type="Polygon" {...mapRowDefaultProps} {...fieldProps} />
                    )}
                  </Field>
                ))
              }
            />
          </TableBody>
        </Table>
      </TableContainer>
      <FormikErrorMessage meta={{ touched: mapTouched, error }} />
    </Box>
  );
};

export default MoversMap;
