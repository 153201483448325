import { Box, Paper, SxProps, Theme } from '@mui/material';

export interface ITemplatePreviews {
  sceneFrontThumb?: string;
  sceneBackThumb?: string;
  envelopeFull?: string;
}

interface TemplateScenePreviewProps extends ITemplatePreviews {
  variant?: 'elevation' | 'outlined';
  isBifold?: boolean;
  sx?: SxProps<Theme>;
}

const TemplateScenePreview = ({
  sceneFrontThumb,
  sceneBackThumb,
  envelopeFull,
  variant = 'outlined',
  isBifold,
  sx,
}: TemplateScenePreviewProps) => {
  const renderImage = (src: string, halfScene?: boolean) => (
    <Box flex={1}>
      <Paper
        square
        variant={variant}
        elevation={4}
        sx={{ display: 'flex', overflow: 'hidden', height: halfScene ? '50%' : 'auto' }}
      >
        <img alt="" src={src} style={{ width: '100%', height: 'auto', objectFit: 'cover', objectPosition: 'bottom' }} />
      </Paper>
    </Box>
  );

  return (
    <Box display="flex" gap={1} flexWrap="nowrap" sx={sx}>
      {sceneFrontThumb && renderImage(sceneFrontThumb)}
      {sceneBackThumb && renderImage(sceneBackThumb, isBifold)}
      {envelopeFull && renderImage(envelopeFull)}
    </Box>
  );
};

export default TemplateScenePreview;
