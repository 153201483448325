import {format, parseISO} from "date-fns";
import {DateTimeFormats} from "../models/enums/DateTimeFormats";

export function formatPrice(price: number): string {
    if(price == null){
        return ''
    }

    return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
    });
}

export function formatNumber(number?: number){
    return number?.toLocaleString('en-US');
}

export function formatDate(date: string | Date) {
    if (!date) {
        return ''
    }

    return format(parseISO(date.toString()), DateTimeFormats.Main);
}

export function formatNumberShort(number: number){
    if (number < 1000) {
        return number.toString();
    } else {
        const roundedNumber = Math.round(number / 100) / 10; // Round to one decimal place
        return roundedNumber + 'k';
    }
}