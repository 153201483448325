import { Add, Remove } from '@mui/icons-material';
import MDIconButton from 'material-ui/components/MDIconButton';

interface IncrementDecrementButtonProps {
  type: 'minus' | 'plus';
  value: number;
  minValue: number;
  maxValue: number;
  allowHalfIncrement?: boolean;
  setValue: (value: number) => void;
}

export default function IncrementDecrementButton({
  type,
  value,
  minValue,
  maxValue,
  allowHalfIncrement,
  setValue,
}: IncrementDecrementButtonProps) {
  const isMinus = type === 'minus';
  const isDisabled = (isMinus ? minValue : maxValue) === value;
  const Icon = isMinus ? Remove : Add;

  const x = allowHalfIncrement ? 0.5 : 1;

  return (
    <MDIconButton
      square
      size={'small'}
      color={'white'}
      onClick={() => setValue(value + (isMinus ? -x : x))}
      disabled={isDisabled}
      sx={{ aspectRatio: 1 }}
    >
      <Icon color={'secondary'} />
    </MDIconButton>
  );
}
