import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';

import MDButton from 'material-ui/components/MDButton';
import MailboxBigIcon from 'assets/icons/mailbox-big';
import { useGlobal } from 'context/global-context';
import OrderService from 'services/order';
import { Order } from 'models/order';

interface SuccessModalProps {
  show: boolean;
  close: () => void;
}

export default function SuccessModal({ show, close }: SuccessModalProps) {
  const navigate = useNavigate();
  const { setShowLoader } = useGlobal();
  const { postOrder } = OrderService();

  const { mutate: createOrder } = useMutation({
    mutationFn: () => postOrder(new Order(0)),
    onMutate: () => setShowLoader(true),
    onSuccess: (data: Order) => {
      close();
      setShowLoader(false);
      navigate(`/order/${data.id}`);
    },
  });

  return (
    <Dialog open={show}>
      <DialogContent>
        <Box textAlign={'center'} p={2}>
          <MailboxBigIcon sx={{ width: '44px', height: '44px' }} />

          <Typography mt={2} variant={'h2'}>
            Thank You!
          </Typography>

          <Typography fontSize={20}>Your order has been placed!</Typography>

          <Box display={'flex'} gap={1} mt={6}>
            <MDButton color={'light'} circular onClick={() => createOrder()}>
              Create New Order
            </MDButton>
            <MDButton
              circular
              onClick={() => {
                close();
                navigate('/orders');
              }}
            >
              View Orders
            </MDButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
