// @ts-nocheck

import {useMsal} from "@azure/msal-react";
import {protectedResources} from "../authConfig";
import logger from "loglevel";
import {useMemo} from "react";
import {downloadCsvResponse, getFileName} from "../helpers/csvFileDownloader";
import moment from "moment";
import { jwtDecode } from "jwt-decode";

interface RequestProps{
    endpoint: string
    method: "POST" | "GET" | "DELETE" | "PUT"
    data?: object
    formData?: FormData
}

const useFetchWithMsal = () => {
    const { instance } = useMsal();

    const execute = useMemo(() => {
        function executeRequest({endpoint, method, data, formData}: RequestProps) {
            let token = localStorage.getItem("token")

            if(token){
                let decodedToken = jwtDecode(token)

                let currentTime = moment()
                let expirationTime = moment(decodedToken.exp * 1000)

                // Check if the token is still valid. If not -> continue to getting a search one and making the request
                if(expirationTime.isAfter(currentTime)){
                    return makeRequest(token, endpoint, method, data, formData)
                }
            }

            return instance.acquireTokenSilent({scopes: protectedResources.scopes, account: instance.getActiveAccount()})
                .then((tokenData) => {
                    if (tokenData) {
                        let newToken = tokenData.idToken

                        localStorage.setItem("token", newToken)

                        return makeRequest(newToken, endpoint, method, data, formData)
                    }
                })
        }

        return executeRequest;
    }, [instance]);

    function makeRequest(token: string, endpoint: string, method: string, data?: object, formData?: FormData){
        const headers = new Headers();

        //Hate that i can't use the normal "Authorization" header: https://github.com/Azure/static-web-apps/issues/34
        headers.set("X-Custom-Authorization", `Bearer ${token}`);

        if(data){
            headers.set('Content-Type', 'application/json');
        }

        let options: any = {
            method: method,
            headers: headers
        };

        if (data) {
            options.body = JSON.stringify(data);
        }
        if(formData){
            options.body = formData
        }

        return fetch(endpoint, options)
            .then((response) => {
                //Don't parse this to json so that the FE can download its content
                if(response.headers.get('Content-Type') === 'application/octet-stream'){
                    return response.blob().then((r) => {
                        downloadCsvResponse(r, getFileName(response.headers))
                    })
                }
                return response.json()
            })
            .catch((error) => {
                logger.error(error);
            });
    }

    return {
        execute
    };
};

export default useFetchWithMsal;