import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import MDBox from "material-ui/components/MDBox"
import MDButton from "material-ui/components/MDButton"
import {
    DialogContent, Select,
} from "@mui/material"
import {Order} from "models/order"
import {OrderStatus} from "models/enums/orderStatus"
import AdminService from "services/admin";
import {useQueryClient} from "@tanstack/react-query";
import { useEffect, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import { useGlobal } from "context/global-context"

export interface UpdateOrderStatusModalProps {
    show: boolean
    setShow: Function
    order: Order
}

// Data
function UpdateOrderStatusModal({show, setShow, order}: UpdateOrderStatusModalProps) {
    const queryClient = useQueryClient()
    const {setShowLoader} = useGlobal()

    const {postOrder} = AdminService()

    const [orderStatus, setOrderStatus] = useState<OrderStatus | null>(null)

    const orderStatuses = [
        OrderStatus.EditsNeeded,
        OrderStatus.InProduction,
        OrderStatus.ReadyForProduction,
        OrderStatus.InQa,
        OrderStatus.Mailed,
        OrderStatus.Cancelled,
        OrderStatus.Paid,
        OrderStatus.Deleted
    ]

    function updateOrder(){
        order.orderStatus = orderStatus!
        setShowLoader(true)

        postOrder(order).then(() => {
            setShowLoader(false)
            setShow(false)

            queryClient.invalidateQueries({queryKey: ["adminOrders"]})
        })
    }

    useEffect(() => {
        if(show && order){
            setOrderStatus(order.orderStatus)
        }
    }, [show, order]);

    return (
        <Dialog
            onClose={() => {
                setShow(false)
            }}
            open={show}
        >
            <DialogContent sx={{width: 500}}>
                <DialogTitle>Update status for order #{order?.id}</DialogTitle>

                <MDBox mt={1}>
                    <Select
                        fullWidth={true}
                        value={orderStatus}
                        onChange={(event: any) => {
                            setOrderStatus(event.target.value)
                        }}
                    >
                        {orderStatuses.map((status, index) => {
                            return <MenuItem key={index} value={status}>{status}</MenuItem>
                        })}
                    </Select>
                </MDBox>

                <MDBox
                    mt={3}
                    width="100%"
                    display="flex"
                    alignItems={"center"}
                    justifyContent="flex-end"
                    gap={1}
                >
                    <MDButton
                        circular={true}
                        color="light"
                        onClick={() => {
                            setShow(false)
                        }}
                    >
                        Cancel
                    </MDButton>

                    <MDButton
                        circular={true}
                        color="primary"
                        onClick={updateOrder}
                    >
                        Update
                    </MDButton>
                </MDBox>
            </DialogContent>
        </Dialog>
    )
}

export default UpdateOrderStatusModal
