const API_ENDPOINT = '/api';

export interface IObjectResult<T> {
  errors?: string[];
  hasErrors?: boolean;
  hasWarnings?: boolean;
  payload: T;
  warnings?: string[];
}

export const mockedObjectResult = <T, >(payload: T) => ({ payload });

export default API_ENDPOINT;
