import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import { useGlobal } from 'context/global-context';
import Loader from 'components/loader';
import './index.scss';

const LoadingModal = () => {
  const { showLoader } = useGlobal();

  return (
    <Dialog open={showLoader}>
      <DialogContent>
        <Loader />
      </DialogContent>
    </Dialog>
  );
};

export default LoadingModal;
