import Retool from 'react-retool';
import { Box } from '@mui/material';

export default function DiscountApproval() {
  return (
    <Box style={{ height: '90vh' }}>
      <Retool url="https://lettrlabs.retool.com/app/discount-approval" />
    </Box>
  );
}
