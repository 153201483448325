export enum ResolveProfileEnrichmentConflicts
{
    Existent = 1,
    Enriched = 2,
    Both = 3,
    None = 4
}

export enum EnrichmentType {
    Address = 1,
    Birthday = 2,
    Both = 3,
  }