import { ProductOptions } from 'newStandard/src/services/order/types';

export enum DefaultText {
  Text = 'Hey {{FIRST NAME}},\nI see that you purchased your home at 123 N Jefferson St. If you are considering selling, I would love to have a chat.\n\nCheers!\nJohn Smith\n(855)333-3915',
  Text2 = "P.S. Can't wait to share more opportunities like that with you!",
}

export function checkIsPrintedCard(product: string) {
  return (
    product === ProductOptions.PrintedPostcard6x11 ||
    product === ProductOptions.PrintedPostcard4x6 ||
    product === ProductOptions.PrintedPostcard6x9
  );
}

export function checkHasEnvelope(product: string) {
  return product === ProductOptions.LargeHandwrittenCardA8 || product === ProductOptions.HandwrittenBiFoldCard;
}

export function checkHasFrontLogo(product: string) {
  return product === ProductOptions.LargeHandwrittenCardA8 || product === ProductOptions.HandwrittenPostCardA8;
}
