// @ts-nocheck
import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { PaymentMethod } from '@stripe/stripe-js';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import MDButton from 'material-ui/components/MDButton';

interface CheckoutModalProps {
  balance?: number;
  isPending: boolean;
  selectedCard: PaymentMethod | null;
  hasIncompleteCard: boolean;
  setErrorMessage: (errorMessage: string) => void;
  onCheckout: (paymentMethodId?: string) => void;
}

export default function CheckoutButton({
  balance = 0,
  isPending,
  selectedCard,
  hasIncompleteCard,
  setErrorMessage,
  onCheckout,
}: CheckoutModalProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [isPaying, setIsPaying] = useState<boolean>(false);
  const isSubmitDisabled = (isPending || !!(!selectedCard && hasIncompleteCard)) && balance > 1;

  const completeCheckout = async () => {
    if(balance === 0 || selectedCard) return onCheckout()
    if (!stripe || !elements) return;
    setIsPaying(true);
    const card = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({ type: 'card', card });
    if (error) setErrorMessage(error.message ?? '');
    else onCheckout(paymentMethod.id);
    setIsPaying(false);
  };

  return (
    <MDButton fullWidth disabled={isSubmitDisabled || isPaying} onClick={completeCheckout}>
      {isPending || isPaying ? <CircularProgress size={18} color="inherit" /> : 'Place your order'}
    </MDButton>
  );
}
