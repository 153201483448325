import useFetchWithMsal from 'hooks/useFetch';
import API_ENDPOINT, { IObjectResult } from '../endpoint';
import { IDataSource, ILeadRevealSource, ILeadRevealSourcePayload, IShopifyStore } from './data-sources.types';

const useDataSourcesService = () => {
  const { execute } = useFetchWithMsal();
  const API_PATH = `${API_ENDPOINT}/data-sources`;

  const getAllDataSources = (): Promise<{ payload: IDataSource[] }> => {
    return execute({
      endpoint: API_PATH,
      method: 'GET',
    });
  };

  const getSourceInstallRequest = (source: string) => {
    return execute({
      endpoint: `${API_PATH}/${source}/oauth/auth-request`,
      method: 'GET',
    });
  };

  const getLeadRevealSource = (): Promise<{ payload: ILeadRevealSource }> => {
    return execute({
      endpoint: `${API_PATH}/lead-reveal`,
      method: 'GET',
    });
  };

  const createLeadRevealSource = (payload: string): Promise<{ payload: ILeadRevealSourcePayload }> => {
    return execute({
      endpoint: `${API_PATH}/lead-reveal`,
      method: 'POST',
      data: { payload },
    });
  };

  const updateLeadRevealSource = (payload: string): Promise<{ payload: ILeadRevealSourcePayload }> => {
    return execute({
      endpoint: `${API_PATH}/lead-reveal`,
      method: 'PUT',
      data: { payload },
    });
  };

  const finishLeadRevealSetup = (payload: string): Promise<{ payload: ILeadRevealSourcePayload }> => {
    return execute({
      endpoint: `${API_PATH}/lead-reveal/finish-setup`,
      method: 'PUT',
      data: { payload },
    });
  };

  const testLeadRevealConnection = (): Promise<IObjectResult<boolean | { message: string }>> => {
    return execute({
      endpoint: `${API_PATH}/lead-reveal/connection-test`,
      method: 'POST',
    });
  };

  const getShopifyStores = (): Promise<{ payload: IShopifyStore[] }> => {
    return execute({
      endpoint: `${API_PATH}/shopify/stores`,
      method: 'GET',
    });
  };

  const revokeDataSource = (source: string, id?: string): Promise<IObjectResult<{}>> => {
    return execute({
      endpoint: `${API_PATH}/${source}/oauth/revoke${id ? `/${id}` : ''}`,
      method: 'POST',
    });
  };

  const setupShopify = (shopId: string, shopDomain: string, email: string): Promise<IObjectResult<string>> => {
    return execute({
      endpoint: `${API_PATH}/shopify/oauth/callback?shopId=${shopId}&shopDomain=${shopDomain}&email=${email}`,
      method: 'POST',
    });
  };

  return {
    getAllDataSources,
    getSourceInstallRequest,
    getLeadRevealSource,
    createLeadRevealSource,
    updateLeadRevealSource,
    finishLeadRevealSetup,
    testLeadRevealConnection,
    getShopifyStores,
    revokeDataSource,
    setupShopify,
  };
};

export default useDataSourcesService;
