import { ReactNode, useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Stack } from '@mui/material';

import { ICheckout } from 'models/checkout';
import Loader from 'components/loader';

interface CheckoutWrapperProps {
  children?: ReactNode;
  checkout?: ICheckout;
  isLoading: boolean;
}

export default function CheckoutWrapper({ children, checkout, isLoading }: CheckoutWrapperProps) {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? ''));
  }, []);

  if (isLoading) {
    return (
      <Stack alignItems={'center'}>
        <Loader />
      </Stack>
    );
  }

  if (!checkout?.stripeToken) return <Elements stripe={stripePromise}>{children}</Elements>;

  return (
    <Elements stripe={stripePromise} options={{ clientSecret: checkout.stripeToken }}>
      {children}
    </Elements>
  );
}
