import { useEffect, useState } from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import { Box, Divider, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import ProfileRecipientEnrichmentService from 'services/profile-recipient-enrichment';
import { EnrichmentType } from 'models/enums/ProfileEnrichment';
import CheckoutModal from 'newStandard/src/components/Checkout';
import { GridSearchParams } from 'models/gridSearchParams';

interface EnrichModalProps {
  show: boolean;
  setShow: Function;
  selectedRecipients: number[];
  lastSearchParams: GridSearchParams;
  enrichBy: EnrichmentType;
  selectedAll: boolean;
}

export default function EnrichmentCheckoutModal({
  show,
  setShow,
  selectedRecipients,
  lastSearchParams,
  enrichBy,
  selectedAll,
}: EnrichModalProps) {
  const { getEnrichCheckout, postEnrichCheckout } = ProfileRecipientEnrichmentService();
  const [selectedCard, setSelectedCard] = useState<PaymentMethod | null>(null);

  const { data: checkoutSummary, isLoading, isRefetching, refetch } = useQuery({
    queryFn: () => getEnrichCheckout({ enrichedBy: enrichBy, profileRecipientIds: selectedRecipients, selectedAll: selectedAll }, lastSearchParams),
    queryKey: ['getEnrichCheckout'],
    enabled: false,
  });

  const { mutate: checkout, isPending } = useMutation({
    mutationFn: (newPaymentId?: string) => postEnrichCheckout(selectedRecipients, enrichBy, lastSearchParams, selectedAll, selectedCard?.id ?? newPaymentId!),
    onSuccess: () => setShow(false),
  });

  useEffect(() => {
    if (show) refetch();
  }, [refetch, show]);

  return (
    <CheckoutModal
      show={show}
      isLoading={isLoading || isRefetching}
      isPending={isPending}
      checkout={checkoutSummary}
      selectedCard={selectedCard}
      onClose={() => setShow(false)}
      setSelectedCard={setSelectedCard}
      onCheckout={checkout}
    >
      <Box component={'ul'} ml={3} mb={2}>
        <Typography component={'li'} fontSize={14} fontWeight={'normal'}>
          Enrichment enhances your existing data by adding external information, improving its accuracy and completeness.
        </Typography>
        <Typography component={'li'} fontSize={14} fontWeight={'normal'}>
          You will only be charged for the recipients that are successfully enriched.
        </Typography>
        <Typography component={'li'} fontSize={14} fontWeight={'normal'}>
          Once the process is complete, you will receive an email with the results.
        </Typography>
        <Typography component={'li'} fontSize={14} fontWeight={'normal'}>
          Before the enriched data is saved, you must resolve any conflicts.
        </Typography>
      </Box>
      <Divider />
    </CheckoutModal>
  );
}
