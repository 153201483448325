import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import {DialogContent, Stack} from "@mui/material"
import MDButton from "material-ui/components/MDButton"
import MDTypography from "material-ui/components/MDTypography"
import MDInput from "material-ui/components/MDInput";
import MDBox from "material-ui/components/MDBox";

export interface TwoButtonModalProps {
    description?: string
    formFieldHelperText?: any
    formFieldLabel?: string
    formFieldOnChange?(value: any): unknown
    formFieldValue?: any
    primaryButtonOnClick: () => void
    primaryButtonText: string
    onClose: () => void
    open: boolean
    secondaryButtonOnClick: () => void
    secondaryButtonText: string
    title?: string
}

// Data
function TwoButtonWithOptionalFormFieldModal(props: TwoButtonModalProps): JSX.Element {
    function showFormField(): boolean {
        if (props.formFieldOnChange && props.formFieldLabel) return true
        return false
    }

    function primaryButtonDisabled(): boolean {
        //If we have a form field, make sure it's populated to show the button
        if (showFormField()) {
            return !props.formFieldValue || props.formFieldValue.trim() === ""
        }
        return false
    }

    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogContent sx={{width: "500px"}}>
                {props.title && <DialogTitle>{props.title}</DialogTitle>}

                {props.description && (
                    <MDTypography variant="button">{props.description}</MDTypography>
                )}

                {showFormField() && (
                    <MDBox>
                        <MDInput
                            fullWidth={true}
                            type="text"
                            helperText={
                                props.formFieldHelperText ? props.formFieldHelperText : ""
                            }
                            value={props.formFieldValue}
                            label={props.formFieldLabel}
                            InputLabelProps={{shrink: true}}
                            onChange={(e: { target: { value: any } }) =>
                                props.formFieldOnChange?.(e.target.value)
                            }
                        />
                    </MDBox>
                )}

                <Stack
                    direction="row"
                    spacing={2}
                    marginTop={2}
                    justifyContent="flex-end"
                >
                    <MDButton
                        color="light"
                        onClick={() => props.secondaryButtonOnClick()}
                    >
                        {props.secondaryButtonText}
                    </MDButton>
                    <MDButton
                        onClick={() => props.primaryButtonOnClick()}
                        disabled={primaryButtonDisabled()}
                    >
                        {props.primaryButtonText}
                    </MDButton>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default TwoButtonWithOptionalFormFieldModal
