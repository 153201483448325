import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Alert,
  Box,
  Checkbox,
  Collapse,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { OrderStatus } from 'newStandard/src/services/order/types';
import ArtifactService from 'services/artifact-service';
import Loader from 'components/loader';

interface ReviewProofsProps {
  collapse?: boolean;
  isProofApproved: boolean;
  setIsProofApproved: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ReviewProofs({ collapse, isProofApproved, setIsProofApproved }: ReviewProofsProps) {
  const { template, step, lastStep } = useWorkflowContext();
  const [showProof, setShowProof] = useState<string>('');
  const { getOrderProofs } = ArtifactService();
  const [images, setImages] = useState<string[]>([]);
  const [showError, setShowError] = useState<boolean>(false);

  const { refetch, isRefetching } = useQuery({
    queryFn: () => getOrderProofs(template.id),
    queryKey: ['getOrderProofs'],
    enabled: false,
  });

  useEffect(() => {
    const fetchProofs = async (requestAttempts: number) => {
      const { data: { payload } = {} } = await refetch();
      if (payload?.length) return setImages(payload.map((image) => image.blobUri));
      if (requestAttempts === 3) return setShowError(true);
      fetchProofs(requestAttempts + 1);
    };

    if (lastStep !== step) return;
    fetchProofs(1);
    setIsProofApproved(false);
  }, [refetch, step, lastStep, setIsProofApproved]);

  useEffect(() => {
    if (template.orderStatus === OrderStatus.Paid) setIsProofApproved(true);
  }, [setIsProofApproved, template.orderStatus]);

  return (
    <Stack spacing={1}>
      <Typography variant="h4">Proofs</Typography>
      {showError ? (
        <Alert severity="error">An error occurred while loading your digital proof. Please try again later.</Alert>
      ) : isRefetching || !images.length ? (
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          minHeight={'100%'}
        >
          <Loader />
          <Typography mt={2} variant={'h5'} color={'primary'}>
            Please wait while your digital proof is loading
          </Typography>
        </Box>
      ) : (
        <>
          <Collapse in={!collapse} collapsedSize={'50%'} orientation="horizontal">
            <Grid container spacing={1}>
              {images.map((image) => (
                <Grid item key={image} sm={collapse ? 4 : 12} lg={collapse ? 4 : 6} xl={4}>
                  <Paper
                    square
                    variant="outlined"
                    sx={{ flex: 1, display: 'flex', cursor: 'pointer' }}
                    onClick={() => setShowProof(image)}
                  >
                    <img alt={''} width="100%" height="auto" src={image} />
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Collapse>

          <Alert severity="info" icon={false} sx={{ py: 0, '.MuiAlert-message': collapse ? { p: 0 } : {} }}>
            <Typography variant={collapse ? 'h5' : 'h4'} p={0.4}>
              <FormControlLabel
                disableTypography
                control={
                  <Checkbox
                    checked={isProofApproved}
                    onChange={() => setIsProofApproved((prev) => !prev)}
                    sx={{ '.MuiSvgIcon-root': { border: '1px solid #F05A2A' } }}
                  />
                }
                label={
                  template.isTemplate
                    ? "I've reviewed my proofs and confirm they meet my standards."
                    : 'I approve these proofs to be produced.'
                }
              />
            </Typography>
          </Alert>
        </>
      )}

      <Dialog open={!!showProof} onClick={() => setShowProof('')} maxWidth="lg">
        <DialogContent>
          <Paper square variant="outlined" sx={{ flex: 1, display: 'flex' }}>
            <img alt={''} width="100%" height="auto" src={showProof} />
          </Paper>
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
