import { Alert, Box, CircularProgress, Grid, Slider, Stack } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loader from "components/loader";
import isNumeric from "helpers/isNumeric";
import MDButton from "material-ui/components/MDButton";
import { ApiResponse } from "models/apiResponse";
import { ProfileConfigurationType, ProfileDataGridConfiguration } from "models/profileConfiguration";
import { useState } from "react";
import ProfileConfigurationService from "services/profile-configuration";

interface ProfileEnrichmentAutoMergeSettingsProps {
    onSave: Function,
    onCancel: Function
}

export default function ProfileEnrichmentAutoMergeSettings(props: ProfileEnrichmentAutoMergeSettingsProps) {
    const [enrichmentSettingsValue, setEnrichmentSettingsValue] = useState<number>(0);

    const { getProfileConfiguration, postProfileConfiguration } = ProfileConfigurationService();

    const { data: { payload: enrichmentSettings } = {}, isLoading: enrichmentSettingsQueryIsLoading } = useQuery<ApiResponse<ProfileDataGridConfiguration>>({
        queryKey: ["enrichmentSettingsQuery"],
        queryFn: () => getProfileConfiguration(ProfileConfigurationType.EnrichmentSettings),
        refetchOnWindowFocus: false
    });

    const saveProfileConfigurationMutation = useMutation({
        mutationFn: () => {
            const body: ProfileDataGridConfiguration = {
                id: enrichmentSettings?.id ?? 0,
                configurationType: ProfileConfigurationType.EnrichmentSettings,
                configurationValue: `${enrichmentSettingsValue}`
            };
            return postProfileConfiguration(body)
        },
        onMutate: () => {
            props.onSave();
        }
    })

    const loaderBox = () => {
        return (
            <Box
                minHeight={'10vh'}
                overflow="hidden"
                display="flex"
                alignItems={'center'}
                justifyContent={'center'}
                width={'100%'}
            >
                <Loader />
            </Box>
        );
    };

    const marks = [
        { value: 0, label: 'Override all despite differences' },
        { value: 40, label: 'Any similarity at all' },
        { value: 90, label: 'Don\'t override anything' }
    ];

    const captionText: Record<number, string> = {
        0: 'Recipients will be merged regardless of how similar they are.',
        40: 'Recipients with an average level of similarity will be merged.',
        90: 'Only recipients with high similarity will be merged.'
    };

    const getConfigurationValue = (configuration?: ProfileDataGridConfiguration) => {
        if (configuration && isNumeric(configuration.configurationValue))
            return Number(configuration.configurationValue);
        return 0;
    }

    return <>
        <Grid container direction="row" sx={{ justifyContent: "center", alignItems: "center" }}
        >
            <Grid item xs={10}>
                {enrichmentSettingsQueryIsLoading
                    ? <>{loaderBox()}</>
                    : <>
                        <Stack mt={3} direction="row">
                            <Slider
                                size="small"
                                aria-label="Marks"
                                defaultValue={getConfigurationValue(enrichmentSettings) ?? 0}
                                value={enrichmentSettingsValue}
                                onChange={(_, newValue: number | number[]) => { setEnrichmentSettingsValue(newValue as number) }}
                                min={0}
                                max={90}
                                step={null}
                                valueLabelDisplay="off"
                                marks={marks}
                            />
                        </Stack>
                    </>
                }
            </Grid>
            <Grid item xs={10} mt={5}>
                <Alert severity="info">
                    {captionText[enrichmentSettingsValue]}
                </Alert>
            </Grid>
            <Grid item xs={12} >
                <Stack direction={'row'} spacing={2} mt={2} sx={{ justifyContent: "flex-end", alignItems: "center" }}>
                    <MDButton onClick={() => { saveProfileConfigurationMutation.mutate() }}>
                        {saveProfileConfigurationMutation.isPending ? <CircularProgress size={18} color="inherit" /> : 'Save'}
                    </MDButton>
                    <MDButton color="light" onClick={() => props.onCancel()}>
                        Cancel
                    </MDButton>
                </Stack>
            </Grid>
        </Grid>
    </>
}