import { useEffect, useRef } from 'react';

export function useInterval(callback: Function, delay: number) {
    const savedCallback = useRef<Function>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function func() {
            savedCallback.current?.();
        }
        if (delay !== null) {
            // Executes once at the start
            func()

            let id = setInterval(func, delay);

            return () => clearInterval(id);
        }
    }, [delay]);
}