import { CreativeEngine } from '@cesdk/cesdk-js';

import { addImagePlaceholder } from 'helpers/ImgLyHelper';
import { ProductOptions } from 'newStandard/src/services/order/types';

import { BlockNames, DefaultArtifacts } from './sceneEnums';

export enum KeepOutColor {
  RED = 'red',
  WHITE = 'white',
}

const whiteOutBlockId = 1011880354;

type RGBAType = [number, number, number, number];

export function setWhiteOutBlockColor(engine: CreativeEngine, color: KeepOutColor) {
  const rgba: RGBAType = color === KeepOutColor.RED ? [1, 0, 0, 0.25] : [1, 1, 1, 0];
  const blocks = engine?.block.findByName(BlockNames.USPSBarCode);
  blocks?.forEach((block) => {
    engine.block.setScopeEnabled(block, 'fill/change', true);
    engine.block.setFillSolidColor(block, ...rgba);
  });
}

export function setKeepOutBlockColor(engine: CreativeEngine, color: KeepOutColor, blockName: BlockNames) {
  const rgba: RGBAType = color === KeepOutColor.RED ? [1, 0, 0, 0.25] : [1, 1, 1, 1];
  const blocks = engine?.block.findByName(blockName);
  blocks?.forEach((block) => {
    engine.block.setScopeEnabled(block, 'fill/change', true);
    engine.block.setFillSolidColor(block, ...rgba);
    engine.block.setScopeEnabled(block, 'editor/select', false);
  });
  const barcodeIds = engine.block.findByName(BlockNames.Postcard_USPSBarCode);
  if (barcodeIds?.length) {
    if (color === KeepOutColor.RED) engine.block.sendToBack(barcodeIds[0]);
    else engine.block.bringToFront(barcodeIds[0]);
  }
}

export function createHWPCKeepoutZone(engine: CreativeEngine) {
  // Create block
  const frontPage = engine.scene.getPages()[0];
  const positionX = 3.97;
  const positionY = 1.85;
  const width = 3.82;
  const height = 3.44;
  const blockName = BlockNames.HWPCKeepOutZone;
  addKeepOutArea(engine, frontPage, positionX, positionY, width, height, blockName);
  // Bring barcode and address to front
  const addressIds = engine.block.findByName(BlockNames.Postcard_ToAddress);
  const barcodeIds = engine.block.findByName(BlockNames.Postcard_USPSBarCode);
  if (addressIds.length && barcodeIds.length) {
    engine.block.setAlwaysOnTop(addressIds[0], true);
    engine.block.setAlwaysOnTop(barcodeIds[0], true);
    engine.block.bringToFront(addressIds[0]);
    engine.block.bringToFront(barcodeIds[0]);
  }
}

export function addKeepOutArea(
  engine: CreativeEngine,
  parentId: number,
  positionX: number,
  positionY: number,
  width: number,
  height: number,
  blockName: BlockNames
) {
  try {
    const block = engine.block.create('graphic');
    engine.block.setName(block, blockName);
    // shape
    const rectShape = engine.block.createShape('rect');
    engine.block.setShape(block, rectShape);
    // fill
    const solidColor = engine.block.createFill('color');
    engine.block.setFill(block, solidColor);
    engine.block.setFillEnabled(block, true);
    engine.block.setColor(block, 'fill/solid/color', { r: 1, g: 1, b: 1, a: 1 });
    // placeholder
    engine.block.setBool(block, 'placeholderControls/showButton', false);
    engine.block.setBool(block, 'placeholderControls/showOverlay', false);
    engine.block.setBool(block, 'placeholder/enabled', false);
    // Other properties
    engine.block.setAlwaysOnTop(block, true);
    engine.block.setPositionX(block, positionX);
    engine.block.setPositionY(block, positionY);
    engine.block.setHeightMode(block, 'Absolute');
    engine.block.setHeight(block, height);
    engine.block.setWidthMode(block, 'Absolute');
    engine.block.setWidth(block, width);
    // prevent select
    engine.block.setScopeEnabled(block, 'editor/select', false);
    // Append the block to parent
    engine.block.appendChild(parentId, block);
  } catch (error) {
    console.error('Error adding keepout area:', error);
  }
}

export function createKeepOutText(engine: CreativeEngine) {
  const text = engine.block.create('text');
  const backPage = engine.scene.getPages()[1];
  engine.block.appendChild(backPage, text);
  engine.block.setName(text, BlockNames.OutsideKeepOutAreaText);
  engine.block.setWidthMode(text, 'Auto');
  engine.block.setHeightMode(text, 'Auto');
  engine.block.setFloat(text, 'text/fontSize', 48);
  engine.block.replaceText(text, 'KEEP OUT AREA');
  engine.block.setTextColor(text, { r: 1, g: 0, b: 0, a: 1 });
  engine.block.setPositionXMode(text, 'Absolute');
  engine.block.setPositionX(text, 1.1);
  engine.block.setPositionYMode(text, 'Absolute');
  engine.block.setPositionY(text, 2.3);
  engine.block.setScopeEnabled(text, 'editor/select', false);
  engine.block.setIncludedInExport(text, false);
}

export function addBifoldKeepoutArea(engine: CreativeEngine) {
  const imageBlock = engine.block.findByName(BlockNames.OutsideFrontImage);
  const width = 7.7;
  const height = 5.4;
  const positionX = -0.1;
  const topHalfPositionY = -0.1;
  const bottomHalfPositionY = 5.3;
  const pageId = engine.block.findByType('page')[0];
  const images = engine.block.findByKind('image');
  const outsideImages = images.find((block) => engine.block.getParent(block) === pageId);
  if (!imageBlock.length && !outsideImages) {
    addImagePlaceholder(
      engine,
      pageId,
      positionX,
      bottomHalfPositionY,
      width,
      height,
      BlockNames.OutsideFrontImage,
      DefaultArtifacts.OutsideFront
    );
  }
  addKeepOutArea(engine, pageId, positionX, topHalfPositionY, width, height, BlockNames.OutsideKeepOutArea);
  const hasKeepOutText = engine.block.findByName(BlockNames.OutsideKeepOutAreaText);
  if (!hasKeepOutText.length) createKeepOutText(engine);
}

function safeRound(value: number) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

export function keepOutFirstLoad(engine: CreativeEngine, product: ProductOptions) {
  let sizeError = false;
  const pages = engine.scene.getPages();
  const height = safeRound(engine.block.getHeight(pages[0]));
  const width = safeRound(engine.block.getWidth(pages[0]));
  const whiteBlock = [...engine.block.findByName(BlockNames.USPSBarCode), whiteOutBlockId];
  const postcardBarCodeBlocks = engine?.block.findByName(BlockNames.Postcard_USPSBarCode);

  setWhiteOutBlockColor(engine, KeepOutColor.WHITE);
  postcardBarCodeBlocks?.forEach((block) => engine.block.setAlwaysOnTop(block, true));
  engine.block.findByName(BlockNames.USPSBarCode).forEach((p) => engine.block.setScopeEnabled(p, 'layer/resize', true));
  pages.forEach((el) => engine.block.setScopeEnabled(el, 'layer/resize', true));
  engine.block.isValid(whiteOutBlockId) && engine.block.setScopeEnabled(whiteOutBlockId, 'layer/resize', true);

  if (product === ProductOptions.LargeHandwrittenCardA8 && (height !== 5.3 || width !== 7.5)) sizeError = true;
  else if (product === ProductOptions.HandwrittenBiFoldCard) addBifoldKeepoutArea(engine);
  else if (product === ProductOptions.HandwrittenPostCardA8) {
    if (!engine.block.findByName(BlockNames.HWPCKeepOutZone).length) createHWPCKeepoutZone(engine);
    else setKeepOutBlockColor(engine, KeepOutColor.WHITE, BlockNames.HWPCKeepOutZone);
    if (height !== 5.3 || width !== 7.8) sizeError = true;
  } else if (product === ProductOptions.PrintedPostcard4x6 && (height !== 4 || width !== 6)) {
    engine.block.resizeContentAware(pages, 6, 4);
    whiteBlock.forEach((block) => {
      engine.block.setHeight(block, 2.76);
      engine.block.setWidth(block, 4.06);
    });
  } else if (product === ProductOptions.PrintedPostcard6x11 && (height !== 6 || width !== 11)) {
    engine.block.resizeContentAware(pages, 11, 6);
    whiteBlock.forEach((block) => {
      engine.block.setHeight(block, 2.78);
      engine.block.setWidth(block, 4.15);
    });
  } else if (product === ProductOptions.PrintedPostcard6x9 && (height !== 6 || width !== 9)) {
    engine.block.resizeContentAware(pages, 9, 6);
    whiteBlock.forEach((block) => {
      engine.block.setHeight(block, 2.77);
      engine.block.setWidth(block, 4.12);
    });
  }
  if (sizeError) {
    window.alert('The scene you are trying to load is not the correct size for this product. Please contact support.');
    throw new Error('Scene is not the correct size for this product.');
  }
}
