export enum ProfileConfigurationType {
    AddressBookColumns,
    OrderRecipientColumns,
    // Enrichment
    EnrichmentSettings
}

export interface ProfileDataGridConfiguration {
    id: number
    configurationType: ProfileConfigurationType
    configurationValue: string
}