import { FieldMetaProps } from 'formik';
import { Typography } from '@mui/material';

interface FormikErrorMessageProps<T> {
  meta: Omit<FieldMetaProps<T>, 'value' | 'initialTouched'>;
}

const FormikErrorMessage = <T,>({ meta }: FormikErrorMessageProps<T>) => {
  if (!meta.touched || !meta.error) return null;
  return (
    <Typography sx={{ fontSize: '12px !important' }} color="error">
      {meta.error}
    </Typography>
  );
};

export default FormikErrorMessage;
