import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { debounce } from '@mui/material';

import { addGlyphs } from 'helpers/glyphHelper';
import useFontService from 'newStandard/src/services/font';
import { ProductOptions } from 'newStandard/src/services/order/types';
import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';

import { useEditorContext } from '../contexts/useEditorContext';
import { checkIsPrintedCard } from '../utils/templateHelper';
import useTemplateTextUpdate from './useTemplateTextUpdate';
import { BlockNames } from '../utils/sceneEnums';

export default function useHandwrittenText() {
  const { getFonts, getFontGlyphs } = useFontService();
  const { template, setTemplate } = useWorkflowContext();
  const { updateTemplateText } = useTemplateTextUpdate();
  const { engine, envelopeEngine, isSceneLoading, selectedFont, setSelectedFont, setTextCursor } = useEditorContext();
  const [retryFontSize, setRetryFontSize] = useState<number>(0);
  const [retryFont, setRetryFont] = useState<number>(0);

  const { data: fonts } = useQuery({ queryKey: ['fonts'], queryFn: getFonts });
  const { data: fontGlyphs } = useQuery({
    queryKey: ['font-glyphs', selectedFont?.id],
    queryFn: () => getFontGlyphs(selectedFont?.id),
  });

  // * Change template font
  useEffect(() => {
    if (template && fonts?.length && template.font !== selectedFont?.label) {
      const auxFont = fonts.find((font) => font.label === template.font);
      if (!auxFont) setTemplate((prev) => ({ ...prev, font: fonts[0].label }));
      setSelectedFont(auxFont ?? fonts[0]);
    }
  }, [fonts, selectedFont, template, setTemplate, setSelectedFont]);

  // * Change engine font
  useEffect(() => {
    if (!engine?.block || !selectedFont || !fonts?.length || isSceneLoading) return;
    const isHandritten = !checkIsPrintedCard(template.product);
    const textBlocks = [
      ...engine.block.findByName(BlockNames.HandwrittenText),
      ...engine.block.findByName(BlockNames.HandwrittenText2),
      ...(isHandritten ? engine.block.findByName(BlockNames.Postcard_ToAddress) : []),
    ];
    textBlocks.forEach((blockId) => {
      engine.block.setScopeEnabled(blockId, 'text/character', true);
      engine.block.setFont(blockId, selectedFont.url, {
        name: selectedFont.url.match(/handwritten-fonts\/([^_]+)_/)?.[1] ?? '',
        fonts: [{ uri: selectedFont.url, subFamily: 'Regular', weight: 'normal', style: 'normal' }],
      });
      engine.block.setScopeEnabled(blockId, 'text/character', false);
    });
  }, [engine?.block, fonts?.length, selectedFont, template.product, isSceneLoading]);

  // * Change envelope engine font
  useEffect(() => {
    if (!envelopeEngine?.block || !selectedFont?.url || !fonts?.length || isSceneLoading) return;
    const textBlocks = [
      ...envelopeEngine.block.findByName(BlockNames.ReturnAddress),
      ...envelopeEngine.block.findByName(BlockNames.Envelope_ToAddress),
    ];

    const debouncedRetry = debounce((attempt: number) => setRetryFont(attempt), 300);
    if (!textBlocks.length) return debouncedRetry(retryFont + 1);

    textBlocks.forEach((blockId) => {
      envelopeEngine.block.setScopeEnabled(blockId, 'text/character', true);
      envelopeEngine.block.setFont(blockId, selectedFont.url, {
        name: selectedFont.url.match(/handwritten-fonts\/([^_]+)_/)?.[1] ?? '',
        fonts: [{ uri: selectedFont.url, subFamily: 'Regular', weight: 'normal', style: 'normal' }],
      });
      envelopeEngine.block.setScopeEnabled(blockId, 'text/character', false);
    });
  }, [envelopeEngine?.block, fonts?.length, isSceneLoading, selectedFont?.url, retryFont]);

  // * Change font size
  useEffect(() => {
    if (!engine?.block || !template?.fontSize) return;
    const texts = [
      ...engine.block.findByName(BlockNames.HandwrittenText),
      ...engine.block.findByName(BlockNames.HandwrittenText2),
    ];
    const debouncedRetry = debounce((attempt: number) => setRetryFontSize(attempt), 300);
    if (!texts.length) return debouncedRetry(retryFontSize + 1);
    texts.forEach((text) => engine.block.setFloat(text, 'text/fontSize', template.fontSize));
  }, [engine, template?.fontSize, retryFontSize]);

  // * Add glyph variations
  useEffect(() => {
    if (!engine?.block || !fontGlyphs?.value) return;
    const replaceText = (templateText: string, blockName: BlockNames) => {
      const textBlockId = engine.block.findByName(blockName)[0];
      if (!textBlockId) return;
      engine.block.setString(textBlockId, 'text/text', addGlyphs(templateText, fontGlyphs.value));
    };
    replaceText(template.text, BlockNames.HandwrittenText);
    if (template.product === ProductOptions.HandwrittenBiFoldCard) {
      replaceText(template.text2, BlockNames.HandwrittenText2);
    }
  }, [engine?.block, fontGlyphs?.value, template.product, template.text, template.text2]);

  // * Text change listener
  useEffect(() => {
    const isPrinted = checkIsPrintedCard(template.product);
    if (!engine?.editor || isPrinted || !fontGlyphs?.value) return;

    const unsubscribe = engine.editor.onStateChanged(() => {
      if (engine.editor.getEditMode() !== 'Text') return;
      const selectedIds = engine.block.findAllSelected();
      updateTemplateText(selectedIds[0]);
      setTextCursor({ ...engine.block.getTextCursorRange(), block: selectedIds[0] });
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engine, fontGlyphs?.value]);

  return { selectedFont };
}
