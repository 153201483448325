export enum ArtifactType {
  ContactUpload = 'Contacts',
  CreativeEditorScene = 'Scene',
  EnvelopeScene = 'EnvelopeScene',
  ProofFront = 'ProofFront',
  ProofBack = 'ProofBack',
  ProofEnvelope = 'ProofEnvelope',
  ThumbFront = 'ThumbFront',
  ThumbBack = 'ThumbBack',
  ThumbEnvelope = 'ThumbEnvelope',
  FullFront = 'FullFront',
  FullBack = 'FullBack',
  FullEnvelope = 'FullEnvelope',
  FrontLogo = 'Front_Logo',
  Image = 'Image',
  Other1 = 'Other_1',
  Other2 = 'Other_2',
  Other3 = 'Other_3',
  QRCode = 'QR_Code',
  RearImage = 'Rear_Image',
  TrackingPhoneNumber = 'TrackingPhoneNumber',
}

export interface IArtifact {
  artifactType: ArtifactType;
  blobUri: string;
  customerId: string;
  description: string;
  fileContentType: string;
  fileLength: string;
  fileName: string;
  id: number;
  orderId: number;
}
