import * as Yup from 'yup';

export const orderRecipientValidation = Yup.object({
  id: Yup.number(),
  salutation: Yup.string().nullable(),
  firstName: Yup.string().nullable(),
  lastName: Yup.string().nullable(),
  toOrganization: Yup.string().nullable(),
  address1: Yup.string().required('Required').nullable(),
  address2: Yup.string().nullable(),
  city: Yup.string().required('Required').nullable(),
  state: Yup.string().required('Required').nullable(),
  zipCode: Yup.string().length(5, 'Zip must be 5 digits long').required('Required').nullable(),
  anniversary: Yup.date().nullable(),
  birthday: Yup.date().nullable(),
});
