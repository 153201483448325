import { Box, Chip, Divider, Grid, Paper, Skeleton, Typography } from '@mui/material';

import { formatNumber, formatPrice } from 'helpers/formatters';
import { ICheckout } from 'models/checkout';

interface OrderSummaryProps {
  checkout?: ICheckout;
  isLoading: boolean;
}

export default function OrderSummary({ checkout, isLoading }: OrderSummaryProps) {
  return (
    <Paper variant="outlined" sx={{ borderRadius: 4, py: 1, px: 2 }}>
      <Typography variant="h4" mb={1}>
        Order Summary
      </Typography>

      <Grid container py={1}>
        <Grid item xs={6}>
          <Typography variant="h5" fontSize={{ xs: 14, xl: 16 }}>
            Product
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h5" fontSize={{ xs: 14, xl: 16 }}>
            Cost
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h5" fontSize={{ xs: 14, xl: 16 }} textAlign={'center'}>
            Qty
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5" fontSize={{ xs: 14, xl: 16 }} textAlign={'end'}>
            Amount
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ m: 0, mb: 1 }} />

      {isLoading ? (
        <>
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        checkout?.lineItems.map((lineItem) => (
          <>
            <Grid container key={lineItem.name}>
              <Grid item xs={6}>
                <Typography fontSize={{ xs: 14, xl: 16 }} fontWeight={'bold'}>
                  {lineItem.name}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontSize={{ xs: 14, xl: 16 }} fontWeight={'bold'}>
                  {formatPrice(lineItem.rate)}
                </Typography>
              </Grid>
              <Grid item xs textAlign={'center'}>
                <Typography fontSize={{ xs: 14, xl: 16 }} fontWeight={'bold'}>
                  x {lineItem.quantity ? formatNumber(lineItem.quantity) : ''}
                </Typography>
              </Grid>
              <Grid item xs={3} textAlign={'end'}>
                <Typography fontSize={{ xs: 14, xl: 16 }} fontWeight={'bold'}>
                  {formatPrice(lineItem.quantity * lineItem.rate)}
                </Typography>
              </Grid>
            </Grid>

            <Box pl={2}>
              {lineItem.subItems.map((subItem) => (
                <Grid container key={`${lineItem.name}${subItem.name}`} alignItems={'center'}>
                  <Grid item xs={6} mr={-2}>
                    <Typography fontSize={{ xs: 14, xl: 16 }}>{subItem.name}</Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Chip size="small" label={formatPrice(subItem.cost)} />
                  </Grid>

                  <Grid item xs={2}></Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              ))}
            </Box>
          </>
        ))
      )}
      <Divider light />

      <Grid container>
        <Grid item xs>
          <Typography fontSize={{ xs: 14, xl: 16 }} fontWeight={'bold'}>
            Total Tax
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign={'end'}>
          <Typography fontSize={{ xs: 14, xl: 16 }} fontWeight={'bold'}>
            {isLoading ? <Skeleton /> : formatPrice(0)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <Typography fontSize={{ xs: 14, xl: 16 }}>Credits</Typography>
        </Grid>
        <Grid item xs={3} textAlign={'end'}>
          <Typography fontSize={{ xs: 14, xl: 16 }}>
            {isLoading ? <Skeleton /> : formatPrice(checkout?.credits?.reduce((a, b) => a + b.total, 0) ?? 0)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs>
          <Typography variant="h4" fontSize={{ xs: 18, xl: 20 }} color="#DA4819">
            Total Due
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign={'end'}>
          <Typography variant="h4" fontSize={{ xs: 18, xl: 20 }} color="#DA4819">
            {isLoading ? <Skeleton /> : formatPrice(checkout?.balance ?? 0)}
          </Typography>
        </Grid>
      </Grid>
      <Divider light />
      <Typography fontSize={12} mt={-1}>
        * By placing your order, you agree to LettrLabs terms and conditions
      </Typography>
    </Paper>
  );
}
