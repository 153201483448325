export enum DefaultArtifacts {
  QrCode = 'https://lettrlabsappstorage.blob.core.windows.net/scenes/images/QR_Code.png',
  Blank = 'https://lettrlabsappstorage.blob.core.windows.net/scenes/images/QR_Code.png',
  Rear = 'https://lettrlabsappstorage.blob.core.windows.net/scenes/images/Rear_Image.png',
  OutsideFront = 'https://lettrlabsappstorage.blob.core.windows.net/scenes/images/Outside_Front_Image.png',
  OutsideKeepOut = 'https://lettrlabsappstorage.blob.core.windows.net/scenes/images/Outside_KeepOut_Image.png',
  Front = 'https://lettrlabsappstorage.blob.core.windows.net/scenes/images/Front_Logo.png',
  A8Card = 'https://lettrlabsappstorage.blob.core.windows.net/scenes/large_handwritten_card(A8).scene',
  A8PostCard = 'https://lettrlabsappstorage.blob.core.windows.net/scenes/handwritten_post_card.scene',
  PrintedPostCardFrontImage = 'https://lettrlabsappstorage.blob.core.windows.net/scenes/images/Front_Image_High.png',
  OtherPrintedPostCardFrontImage = 'https://lettrlabsappstorage.blob.core.windows.net/scenes/images/Front_Image.png',
}

export enum BlockNames {
  //Added image for all scenes
  Image = 'Image',
  //Added image for all scenes
  PrintedText = 'PrintedText',
  //Border for all scenes
  Border = 'Rectangle',
  //Front logo for all scenes
  FrontLogo = 'Front_Logo',
  //Read logo for all scenes
  RearImage = 'Rear_Image',
  //Outside Front Image (REAR image for Bi-Fold Cards)
  OutsideFrontImage = 'Outside_Front_Image',
  //Outside Rear Bottom Keep Out Area (for Bi-Fold Cards)
  OutsideKeepOutArea = 'Outside_Keep_Out_Area',
  //Outside Rear Keep Out Area Text (for Bi-Fold Cards)
  OutsideKeepOutAreaText = 'Outside_Keep_Out_Area_Text',
  //Handwritten text
  HandwrittenText = 'Greeting',
  //Handwritten text
  HandwrittenText2 = 'Greeting_2',
  //Return address on envelope
  ReturnAddress = 'ReturnAddress',
  //QR code block
  QRCode = 'QR_Code',
  //Tracking Phone Number block
  TrackingPhoneNumber = 'TrackingPhoneNumber',
  //To address on envelope
  Envelope_ToAddress = 'ToAddress',
  //Postcard  return address
  Postcard_ToAddress = 'Static Greeting',
  //Postcard bar code
  Postcard_USPSBarCode = 'IMB',
  //Postcard stamp (Needs to be renamed from 'image' on envelopes if we want to target it)
  Postcard_Stamp = 'Stamp',
  //Usps barcode on envelope
  USPSBarCode = 'USPS_BARCODE',
  //USPS address
  USPSAddress = 'USPS_ADDRESS',
  //Bleed lines
  SafeZone = 'SafeZone',
  //Bleed text
  SafeZoneText = 'SafeZoneText',
  //Version
  Version = 'Version',
  SceneVersion = 'Scene_Version',
  FullBleedBigText = 'FullBleedBigText',
  FullBleedSmallText = 'FullBleedSmallText',
  SafeZoneBigText = 'SafeZoneBigText',
  SafeZoneSmallText = 'SafeZoneSmallText',
  UspsStamp = 'USPS_STAMP',
  UspsStampText = 'STAMP_TEXT',
  UspsStampElement = 'Element',
  HWPCKeepOutZone = 'HandwrittenPostCardKeepoutZone',
  Crease = 'Crease',
  Line = 'Line',
  InheritedShape = 'InheritedShape',
}
