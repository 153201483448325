import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentIntentResult, PaymentMethod } from '@stripe/stripe-js';

import MDButton from 'material-ui/components/MDButton';

const DEFAULT_ERROR_MESSAGE = 'An unexpected error occurred. Please try again or contact billing@lettrlabs.com';

interface StripeCheckoutButtonProps {
  stripeToken: string;
  isConfirmDisabled: boolean;
  selectedCard?: PaymentMethod;
  setErrorMessage(errorMessage: string): void;
  setShowSuccess(showSuccess: boolean): void;
}

export default function StripeCheckoutButton({
  isConfirmDisabled,
  selectedCard,
  stripeToken,
  setErrorMessage,
  setShowSuccess,
}: StripeCheckoutButtonProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [isPaying, setIsPaying] = useState<boolean>(false);

  const paymentCallback = (result: PaymentIntentResult) => {
    if (result.error) {
      let error = result.error;
      if (error.type === 'card_error') {
        if (error.code === 'card_declined') {
          setErrorMessage('You card has been declined. Please contact your bank for additional information');
        } else if (error.code === 'expired_card') {
          setErrorMessage('You card has expired');
        } else if (error.code === 'incorrect_cvc' || error.code === 'incorrect_number') {
          setErrorMessage('Invalid cvc or number. Please verify your credentials and try again');
        } else setErrorMessage(DEFAULT_ERROR_MESSAGE);
      } else setErrorMessage(DEFAULT_ERROR_MESSAGE);
    } else if (result.paymentIntent.status === 'succeeded') setShowSuccess(true);
    else setErrorMessage(DEFAULT_ERROR_MESSAGE);
    setIsPaying(false);
  };

  const completeCheckout = () => {
    if (!stripe || !elements) return;
    setIsPaying(true);
    if (selectedCard) {
      stripe.confirmCardPayment(stripeToken, { payment_method: selectedCard.id }).then(paymentCallback);
    } else {
      elements.submit().then((result) => {
        if (result.error) {
          setErrorMessage(DEFAULT_ERROR_MESSAGE);
          setIsPaying(false);
          return;
        }
        // stripe.confirmPayment will only redirect if the user chooses a redirect-based payment method
        stripe.confirmPayment({ elements, redirect: 'if_required' }).then(paymentCallback);
      });
    }
  };

  return (
    <MDButton
      fullWidth
      disabled={isConfirmDisabled || isPaying}
      onClick={() => completeCheckout()}
      sx={{ maxWidth: { xs: '100%', lg: 160 } }}
    >
      {isPaying ? <CircularProgress size={18} color="inherit" /> : 'Place your order'}
    </MDButton>
  );
}
