import { Box } from '@mui/material';
import logo from 'assets/images/logo-inverted.png';

function Loader() {
  return (
    <Box sx={{ width: '40px', height: '40px', padding: 0 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40px"
        height="40px"
        viewBox="0 0 100 100"
        style={{ background: '#ffffff;image-rendering:auto' }}
      >
        <g transform="translate(56 56) rotate(0) scale(1 1) translate(-50 -50)">
          <image
            x="0"
            y="0"
            width="88"
            height="88"
            href={logo}
            style={{
              transformOrigin: '50% 50%',
              animation: '1.35135s linear 0s infinite normal forwards running spinner-breath',
            }}
          ></image>
        </g>
      </svg>
    </Box>
  );
}

export default Loader;
