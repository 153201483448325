import Retool from "react-retool";
import MDBox from "material-ui/components/MDBox";

function isValidKey(key: any): key is 'prd' | 'uat' | 'dev' | 'default' {
    return ['prd', 'uat', 'dev', 'default'].includes(key);
}

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const urlMap: { [key in 'prd' | 'uat' | 'dev' | 'default']?: string } = {
    dev: "https://lettrlabs.retool.com/embedded/public/ba017c36-e6d0-4eb8-959c-1ce54f6314e0",
    uat: "https://lettrlabs.retool.com/embedded/public/de1fa634-95dd-4329-9d5a-e671b97be1e3",
    prd: "https://lettrlabs.retool.com/embedded/public/0c378178-18c6-46d8-92dd-b5c2acf0df27",
    default: "https://lettrlabs.retool.com/embedded/public/ba017c36-e6d0-4eb8-959c-1ce54f6314e0"
};

const url = isValidKey(ENVIRONMENT) ? urlMap[ENVIRONMENT] : urlMap.default;

function AdminOrdersWithIssues() {
    return (
        <MDBox style={{ height: "90vh" }}>
            <Retool url={url} />
        </MDBox>
    );
}

export default AdminOrdersWithIssues;