import { useState } from 'react';
import { IconButton } from '@mui/material';
import { ContentCopyRounded, LibraryAddCheckRounded } from '@mui/icons-material';

interface CopyToClipboardButtonProps {
  text: string;
}

export default function CopyToClipboardButton({ text }: CopyToClipboardButtonProps) {
  const [copied, setCopied] = useState(false);
  const CopyIcon = copied ? LibraryAddCheckRounded : ContentCopyRounded;

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(text);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <IconButton onClick={handleCopy}>
      <CopyIcon />
    </IconButton>
  );
}
