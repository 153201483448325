import useFetchWithMsal from 'hooks/useFetch';
import API_ENDPOINT from 'services/endpoint';
import { IArtifact } from './types';

const useArtifactService = () => {
  const { execute } = useFetchWithMsal();

  const API_PATH = `${API_ENDPOINT}/orderArtifacts`;

  const uploadOrderArtifact = (orderId: number, formData: FormData): Promise<IArtifact> => {
    return execute({ endpoint: `${API_PATH}/${orderId}`, method: 'POST', formData });
  };

  const getPastOrderArtifacts = (): Promise<IArtifact[]> => {
    return execute({ endpoint: `${API_ENDPOINT}/pastOrderArtifacts`, method: 'GET' });
  };

  const removeArtifact = (orderId: number, artifactId: number) => {
    return execute({ endpoint: `${API_PATH}/${orderId}/${artifactId}`, method: 'DELETE' });
  };

  const generateQrCode = (orderId: number, qrUrl: string): Promise<IArtifact> => {
    const formData = new FormData();
    formData.append('qrCodeUrl', qrUrl);
    return execute({ endpoint: `${API_PATH}/generateQrCode/${orderId}`, method: 'POST', formData });
  };

  const updateArtifactType = (artifact: IArtifact) => {
    return execute({ endpoint: `${API_ENDPOINT}/orderartifacts-update-type`, method: 'POST', data: artifact });
  };

  const copyOrderArtifact = (orderId: number, artifactUri: string) => {
    const formData = new FormData();
    formData.append('artifactUri', artifactUri);
    return execute({ endpoint: `${API_ENDPOINT}/order/${orderId}/orderartifacts:copy`, method: 'POST', formData });
  };

  return {
    removeArtifact,
    generateQrCode,
    uploadOrderArtifact,
    updateArtifactType,
    getPastOrderArtifacts,
    copyOrderArtifact,
  };
};

export default useArtifactService;
