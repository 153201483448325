import { KeyboardEvent, MouseEventHandler, useEffect, useState } from 'react';
import { Alert, Box, Chip, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FileUploadOutlined } from '@mui/icons-material';
import { CSVBoxButton } from '@csvbox/react';

import MDButton from 'material-ui/components/MDButton';
import MDCard from 'material-ui/components/MDCard';
import ProfileService from 'services/profile';

export interface ImportRecipientsModalProps {
  open: boolean;
  onClose?: () => void;
}

export default function ImportRecipientsModal({ open, onClose }: ImportRecipientsModalProps) {
  const [tableError, setTableError] = useState<boolean>(false);
  const [border, setBorder] = useState<boolean>(false);
  const [newTag, setNewTag] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);

  const queryClient = useQueryClient();
  const { getProfile } = ProfileService();

  const { data: profile } = useQuery({ queryKey: ['profile'], queryFn: getProfile, refetchOnWindowFocus: false });

  useEffect(() => {
    if (tags.length >= 5) setBorder(false);
  }, [tags]);

  const handleClose = () => {
    onClose?.();
    setTableError(false);
  };

  const handleInputKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      const auxTag = newTag.trim();
      if (auxTag && !tags.includes(auxTag)) {
        setTags((prev) => [...prev, auxTag]);
        setNewTag('');
      }
    }
  };

  const handleUploadFinished = (result: boolean) => {
    if (result) {
      queryClient.invalidateQueries({ queryKey: ['profileRecipients'] });
      handleClose();
    } else setTableError(true);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogContent>
        <Typography variant="h4" mb={3}>
          Import Recipients
        </Typography>

        <Typography fontSize={16} fontWeight={500}>
          Add up to 5 tags to easily identify your recipients
        </Typography>
        <MDCard
          color="light"
          borderRadiusSize={'xl'}
          sx={{ border: `2px solid ${border ? '#F05A2A' : 'transparent'}` }}
        >
          <Box mx={1} pt={'2px'} display="flex" alignItems="center" flexWrap="wrap">
            {tags.map((tag, index) => (
              <Chip key={index} label={tag} onDelete={() => setTags(tags.filter((t) => t !== tag))} sx={{ m: '2px' }} />
            ))}
            <TextField
              fullWidth
              value={newTag}
              variant="outlined"
              placeholder="Add Tag"
              onFocus={() => setBorder(true)}
              onBlur={() => setBorder(false)}
              onChange={(evt) => setNewTag(evt.target.value)}
              onKeyDown={(evt) => handleInputKeyDown(evt)}
              sx={{ minWidth: 100, flex: 1, '& fieldset': { border: 'none' } }}
              disabled={tags.length >= 5}
            />
          </Box>
        </MDCard>
        <Typography variant="caption">Press "Enter" or "Tab" to insert the new tag.</Typography>
        {tableError && (
          <Alert severity="error" onClose={() => setTableError(false)} sx={{ mt: 1 }}>
            Upload failed. Please try again.
          </Alert>
        )}

        {!!profile?.id && (
          <CSVBoxButton
            licenseKey={process.env.REACT_APP_CSVBOX_PROFILE_RECIPIENTS ?? ''}
            user={{ user_id: 'LettrLabsCsvBoxAdmin', profile_id: profile.id, general_tags: tags }}
            key={tags.join('-')}
            onImport={handleUploadFinished}
            render={(launch: MouseEventHandler<HTMLButtonElement>) => (
              <MDButton onClick={launch} sx={{ float: 'inline-end', mt: 2 }}>
                <FileUploadOutlined sx={{ mr: 1 }} />
                Upload CSV File
              </MDButton>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
