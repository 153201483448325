import { useEffect, useState } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import API_ENDPOINT from 'services/endpoint';
import MDButton from 'material-ui/components/MDButton';

const DataSourceCallback = () => {
  const navigate = useNavigate();
  const { source } = useParams();
  const { search, pathname } = useLocation();
  const [callbackError, setCallbackError] = useState<boolean>(false);
  const queryParams = new URLSearchParams(search);
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const error = queryParams.get('error');

  useEffect(() => {
    const getCallback = async () => {
      const endpoint = `${API_ENDPOINT}/data-sources/${source}/oauth/callback?_code=${code}&state=${state}`;
      const fetchOptions = { method: 'GET', headers: { 'Content-Type': 'application/json' } };
      try {
        const res = await fetch(endpoint, fetchOptions);
        const response = await res.json();
        if (response.payload) window.location.href = response.payload;
        else navigate(`${pathname}?error=true`, { replace: true });
      } catch {
        navigate(`${pathname}?error=true`, { replace: true });
      }
    };

    if (code && state && source) getCallback();
    else if (error) setCallbackError(true);
  }, [code, error, navigate, pathname, source, state]);

  const transformString = (str: string) => {
    let words = str.split('-');
    words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join(' ');
  };

  const sourceName = transformString(source ?? '');

  return (
    <Box
      height={'100vh'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      color={'#F05A2A'}
    >
      <Stack spacing={3} maxWidth={980} mx={'auto'} alignItems={'center'} textAlign={'center'}>
        <Typography variant="h3" color={callbackError ? 'darkred' : '#130B46'}>
          {callbackError ? `Something went wrong with ${sourceName} set up` : `Setting up ${sourceName}...`}
        </Typography>
        {error === 'access_denied' && (
          <Typography color={"#000"}>
            You denied the permissions needed for LettrLabs to access your account information, which blocked the{' '}
            {sourceName} installation. Please restart the setup and allow the required permissions to continue.
          </Typography>
        )}
        {callbackError ? (
          <MDButton color="light" onClick={() => navigate('/settings/smart-automation-setup')}>
            Go back to LettrLabs
          </MDButton>
        ) : (
          <CircularProgress color="inherit" />
        )}
      </Stack>
    </Box>
  );
};

export default DataSourceCallback;
