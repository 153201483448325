import { CreativeEngine } from '@cesdk/cesdk-js';

import { createBleedLine, createBleedText, removeBlock } from 'helpers/ImgLyHelper';
import { IProduct } from 'newStandard/src/components/ProductStep/ProductStep.utils';
import { ProductOptions } from 'newStandard/src/services/order/types';

import { BlockNames } from './sceneEnums';

export function addBleedMargin(engine: CreativeEngine) {
  const pages = engine.scene.getPages();
  pages.forEach((page) => {
    engine.block.setBool(page, 'page/marginEnabled', true);
    engine.block.setFloat(page, 'page/margin/left', 0.1);
    engine.block.setFloat(page, 'page/margin/right', 0.1);
    engine.block.setFloat(page, 'page/margin/top', 0.1);
    engine.block.setFloat(page, 'page/margin/bottom', 0.1);
  });
}

function lineColor(color: 'green' | 'blue') {
  return { r: 0, g: Number(color === 'green'), b: Number(color === 'blue'), a: 0.7 };
}

export function resetBleeds(engine: CreativeEngine, product: IProduct) {
  let bleedBlocks: number[] = [];
  let bleedTexts: number[] = [];

  if (product.name === ProductOptions.LargeHandwrittenCardA8) {
    bleedBlocks = [283120005, 674238882, 1819283914];
    bleedTexts = [
      16781712, 846205310, 1383073566, 1447039419, 1515196807, 2248151492, 2348814764, 2790265285, 3745518008,
    ];
  } else if (
    product.name === ProductOptions.PrintedPostcard4x6 ||
    product.name === ProductOptions.PrintedPostcard6x9 ||
    product.name === ProductOptions.PrintedPostcard6x11
  ) {
    bleedBlocks = [260051411, 1456476561];
    bleedTexts = [
      63967621, 1162875335, 2026901895, 3353350590, 1567625603, 1963987375, 2345668993, 475009402, 62919120, 349180287,
      762319261, 1608520110, 2145391001,
    ];
  } else if (product.name === ProductOptions.HandwrittenPostCardA8) {
    bleedBlocks = [141562264, 260051411, 1530925510];
    bleedTexts = [1622151590, 2026901895, 3353350590, 586154539, 762319261, 1608520110];
  }

  bleedBlocks.push(...engine.block.findByName(BlockNames.SafeZone));
  bleedTexts.push(...engine.block.findByName(BlockNames.SafeZoneText));
  bleedTexts.push(...engine.block.findByName(BlockNames.SafeZoneSmallText));
  bleedTexts.push(...engine.block.findByName(BlockNames.SafeZoneBigText));
  bleedTexts.push(...engine.block.findByName(BlockNames.FullBleedSmallText));
  bleedTexts.push(...engine.block.findByName(BlockNames.FullBleedBigText));
  // This removes the lines and text that come from the premade scene
  for (let id of [...bleedBlocks, ...bleedTexts]) removeBlock(engine, id);

  const pages = engine.scene.getPages();
  const isBifold = product.name === ProductOptions.HandwrittenBiFoldCard;
  pages.forEach((p, index) => {
    const isBifoldBack = isBifold && index === 1;
    createBleedLine(engine, p, lineColor('green'), -0.2, isBifoldBack ? -5.5 : -0.2, 0.1, isBifoldBack ? 5.45 : 0.1);
    createBleedLine(engine, p, lineColor('blue'), 0.2, isBifoldBack ? -5.1 : 0.2, -0.1, isBifoldBack ? 5.2 : -0.1);
    const fbbText = `Full Bleed ${product.fullBleedArea}`;
    createBleedText(engine, p, fbbText, { r: 0, g: 0, b: 0.6, a: 1 }, 8, -0.1, 0.13, BlockNames.FullBleedBigText);
    const fbsText = 'For edge-to-edge designs, extend your background graphics to this border.';
    createBleedText(engine, p, fbsText, { r: 0, g: 0, b: 0, a: 1 }, 6, -0.1, 0.25, BlockNames.FullBleedSmallText);
    const szbText = `Safe Zone ${product.safeZoneArea}`;
    createBleedText(engine, p, szbText, { r: 0, g: 0.6, b: 0, a: 1 }, 8, 3, 0.13, BlockNames.SafeZoneBigText);
    const szsText = 'Keep all text and important graphics within this region.';
    createBleedText(engine, p, szsText, { r: 0, g: 0, b: 0, a: 1 }, 6, 3, 0.25, BlockNames.SafeZoneSmallText);
  });
}
