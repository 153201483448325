import { Dialog, DialogContent, Stack, Typography } from '@mui/material';
import Loader from 'components/loader';

export default function TaggingRunningBlockScreen() {
  return (
    <Dialog open maxWidth="md">
      <DialogContent>
        <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Loader />
          <Typography>Please wait while we tag all your uploaded recipients.</Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
