import useFetchWithMsal from 'hooks/useFetch';
import API_ENDPOINT, { IObjectResult } from 'services/endpoint';

const API_PATH = `${API_ENDPOINT}/payments`;

export default function usePaymentsService() {
  const { execute } = useFetchWithMsal();

  const retryPaymentWithNewMethod = (paymentMethodId: string) => {
    return execute({ endpoint: `${API_PATH}/retry`, method: 'POST', data: { paymentMethodId } });
  };

  const getSubscriptionLink = (): Promise<IObjectResult<string>> => {
    return execute({ endpoint: `${API_PATH}/get-subscription-link`, method: 'GET' });
  };

  return { retryPaymentWithNewMethod, getSubscriptionLink };
}
