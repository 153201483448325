import { useEffect } from 'react';

import { ProductOptions } from 'newStandard/src/services/order/types';
import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';

import { KeepOutColor, setKeepOutBlockColor, setWhiteOutBlockColor } from '../utils/keepOutHelper';
import { useEditorContext } from '../contexts/useEditorContext';
import { checkIsPrintedCard } from '../utils/templateHelper';
import { BlockNames } from '../utils/sceneEnums';

export default function useBlockSelection() {
  const { engine } = useEditorContext();
  const { template } = useWorkflowContext();

  useEffect(() => {
    if (!engine?.block || !template?.product) return;
    const unsubscribe = engine.block.onSelectionChanged(() => {
      const selectedIds = engine.block.findAllSelected();

      const isHWBlock = [BlockNames.HandwrittenText, BlockNames.HandwrittenText2].includes(
        (selectedIds.length ? engine.block.getName(selectedIds[0]) : '') as BlockNames
      );
      const keepoutColor = selectedIds.length && !isHWBlock ? KeepOutColor.RED : KeepOutColor.WHITE;
      if (checkIsPrintedCard(template.product)) {
        setWhiteOutBlockColor(engine, keepoutColor);
      } else if (template.product === ProductOptions.HandwrittenPostCardA8) {
        setKeepOutBlockColor(engine, keepoutColor, BlockNames.HWPCKeepOutZone);
      } else if (template.product === ProductOptions.HandwrittenBiFoldCard) {
        setKeepOutBlockColor(engine, keepoutColor, BlockNames.OutsideKeepOutArea);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [engine, template?.product]);
}
