// @ts-nocheck
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Typography,
  Link,
} from '@mui/material';
import useDiscountCodesService from 'services/discount-codes';
import FormikErrorMessage from 'components/formik-error-message';
import colors from 'material-ui/theme/base/colors';
import { useNavigate } from 'react-router-dom';
import { ISingleUseList } from 'services/discount-codes/discount-codes.types';

type CouponType = 'multiUseCouponId' | 'couponListId';

export interface ISelectedDiscountCode {
  multiUseCouponId?: number | null;
  couponListId?: number | null;
}

interface SelectDiscountCodesProps {
  disabled?: boolean;
  selectedDiscount: ISelectedDiscountCode;
  setSelectedDiscount: (selectedDiscount: ISelectedDiscountCode) => void;
  error?: boolean;
  setError?: (error: boolean) => void;
  recipientsAmount?: number;
}

const SelectDiscountCodes = ({
  disabled,
  selectedDiscount,
  setSelectedDiscount,
  error,
  setError,
  recipientsAmount = 0,
}: SelectDiscountCodesProps) => {
  const navigate = useNavigate();
  const { getAllDiscountCodes } = useDiscountCodesService();
  const [type, setType] = useState<CouponType>('multiUseCouponId');
  const isMulti = type === 'multiUseCouponId';

  const {
    data: discountData,
    isFetching,
    isFetched,
  } = useQuery({
    queryFn: () => getAllDiscountCodes(false),
    queryKey: ['getAllDiscountCodes'],
  });

  const { multiUseCoupon = [], singleUseCouponList = [] } = discountData?.payload || {};
  const couponList: ISingleUseList[] = isMulti ? multiUseCoupon : singleUseCouponList;
  const couponLabel = `Coupon ${isMulti ? 'Name' : 'List (Available)'}`;
  const wrapperBoxProps = {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 2,
    px: 1,
    pb: 1.5,
    border: `1px solid ${error ? 'red' : '#E4E6F1'}`,
    sx: { backgroundColor: '#FFF' },
    width: 'fit-content',
  };

  useEffect(() => {
    if (isFetched) {
      if (selectedDiscount.couponListId) setType('couponListId');
      else if (selectedDiscount.multiUseCouponId) setType('multiUseCouponId');
      else setType(!multiUseCoupon.length && !!singleUseCouponList.length ? 'couponListId' : 'multiUseCouponId');
    }
  }, [isFetched, multiUseCoupon.length, selectedDiscount, singleUseCouponList.length]);

  const handleDiscountChange = (value: number) => {
    if (error && setError) setError(false);
    setSelectedDiscount({ couponListId: isMulti ? null : value, multiUseCouponId: isMulti ? value : null });
  };

  const renderCouponOption = (coupon: ISingleUseList) => {
    const notEnoughCoupons = type === 'couponListId' && recipientsAmount > coupon.availableCoupons;
    return (
      <MenuItem value={coupon.id} disabled={notEnoughCoupons}>
        {coupon.name}
        {isMulti ? '' : ` (${coupon.availableCoupons})`}
      </MenuItem>
    );
  };

  if (isFetching) {
    return (
      <Box {...wrapperBoxProps} pt={1.5}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  if (!multiUseCoupon.length && !singleUseCouponList.length) {
    return (
      <Box {...wrapperBoxProps} pt={1.5} whiteSpace={'nowrap'}>
        <Typography variant="caption" fontWeight={'bold'} ml={1}>
          Please{' '}
          <Link color={colors.primary.main} onClick={() => navigate('/discount-codes')} sx={{ cursor: 'pointer' }}>
            add discounts to your profile
          </Link>{' '}
          before using a template that needs them.
        </Typography>
      </Box>
    );
  }

  return (
    <Box {...wrapperBoxProps}>
      <FormControl sx={{ width: 200 }}>
        <RadioGroup value={type} onChange={(evt) => setType(evt.target.value as CouponType)}>
          <FormControlLabel
            value="multiUseCouponId"
            label="Multi Use Coupons"
            control={<Radio size="small" />}
            disabled={!multiUseCoupon.length || disabled}
            sx={{ height: 26, '& span.MuiFormControlLabel-label.Mui-disabled': { color: 'gray' } }}
          />
          <FormControlLabel
            value="couponListId"
            label="Single Use Coupons"
            control={<Radio size="small" />}
            disabled={!singleUseCouponList.length || disabled}
            sx={{ height: 26, '& span.MuiFormControlLabel-label.Mui-disabled': { color: 'gray' } }}
          />
        </RadioGroup>
      </FormControl>
      <Box width={200} mb={-2.5}>
        <FormControl fullWidth>
          <InputLabel htmlFor="discount-select" shrink>
            {couponLabel}
          </InputLabel>
          <Select
            displayEmpty
            size="small"
            id="discount-select"
            label={couponLabel}
            value={selectedDiscount?.[type] || undefined}
            disabled={disabled}
            onChange={(evt) => handleDiscountChange(evt.target.value as number)}
          >
            <MenuItem value={undefined} disabled>
              Select an option
            </MenuItem>
            {couponList.map(renderCouponOption)}
          </Select>
          <FormikErrorMessage meta={{ touched: error ?? false, error: 'Required' }} />
        </FormControl>
      </Box>
    </Box>
  );
};

export default SelectDiscountCodes;
