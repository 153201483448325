import Retool from "react-retool";
import MDBox from "material-ui/components/MDBox";

function SuperAdminDiscounts() {
    return (
        <MDBox style={{ height: "90vh" }}>
            <Retool url={"https://lettrlabs.retool.com/p/manage-discounts"} />
        </MDBox>
    );
}

export default SuperAdminDiscounts;