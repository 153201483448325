export function formatCamelCase(text: string) {
  const words = text.toLowerCase().split(' ');
  words.forEach((word, index) => {
    words[index] = `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
  });
  return words.join(' ');
}

export function formatPrice(price: number) {
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
}
