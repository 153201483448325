import React, {useEffect, useState} from "react";
import {
    Box,
    CircularProgress, Divider,
    Grid,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {RecipientSearchStatus} from "models/recipient-search/recipientSearchStatus";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import {
    GridCellModes,
    GridCellModesModel,
    GridColDef,
    useGridApiRef
} from "@mui/x-data-grid-pro";
import {useNavigate} from "react-router-dom";
import {RecipientSearch} from "models/recipient-search/recipientSearch";
import {GridRowId} from '@mui/x-data-grid';
import {formatNumber, formatPrice} from "helpers/formatters";
import MDBox from "material-ui/components/MDBox";
import {GridApiPro} from "@mui/x-data-grid-pro/models/gridApiPro";
import MDButton from "material-ui/components/MDButton";
import StyledDataGrid from "components/styled-data-grid";
import RecipientSearchService from "services/recipient-search";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import MailboxIcon from "assets/icons/mailbox";
import MDCard from "material-ui/components/MDCard";
import HomeAlt2Icon from "assets/icons/home-alt-2";
import HomeEditIcon from "assets/icons/home-edit";
import HomeDollarIcon from "assets/icons/home-dollar";
import HomeSearchIcon from "assets/icons/home-search";
import HomeShareIcon from "assets/icons/home-share";
import HomeHeartIcon from "assets/icons/home-heart";
import SingleRecipientIcon from "assets/icons/single-recipient";
import InfoIcon from "assets/icons/info";
import CreateOrderFlow from "components/create-order-flow";
import EditPencilIcon from "assets/icons/edit-pencil";
import MDInput from "material-ui/components/MDInput";
import CloseIcon from "assets/icons/close";
import CheckIcon from "assets/icons/check";
import TwoButtonWithOptionalFormFieldModal from "components/modal-with-two-buttons-and-textfield"
import DeleteIcon from "assets/icons/delete";
import InfoModal from "components/info-modal";
import StatusTracker from "components/status-tracker";
import {Circle, GoogleMap, LoadScript, OverlayView, Polygon as MapsPolygon} from "@react-google-maps/api";
import colors from "material-ui/theme/base/colors";
import {getCenterOfPolygon} from "helpers/google-map-helper";
import {Coordinate} from "models/coordinate";
import {formatDate} from "helpers/formatters";
import MDTypography from "material-ui/components/MDTypography";
import {OwnerOrRenterFilter, PropertyTypeFilter} from "models/radius-mailing/filters";
import MDIconButton from "material-ui/components/MDIconButton";
import PlusIcon from "assets/icons/plus";
import Info2Icon from "assets/icons/info-2";
import { useGlobal } from "context/global-context";

interface MasterDetailProps {
    row: RecipientSearch
    apiRef: GridApiPro
}

function MasterDetail({row, apiRef}: MasterDetailProps) {
    const {setShowLoader} = useGlobal()
    const navigate = useNavigate()

    const {postRecipientSearch, copyRecipientSearch} = RecipientSearchService()

    const [recipientSearchOriginal, setRecipientSearchOriginal] = useState<RecipientSearch>(JSON.parse(JSON.stringify(row)))
    const [recipientSearch, setRecipientSearch] = useState<RecipientSearch>(JSON.parse(JSON.stringify(row)))

    const [isEditingName, setIsEditingName] = useState<boolean>(false)
    const [radiusPinAddressEditId, setRadiusPinAddressEditId] = useState<number | null>(null)
    const [radiusPinStreetEditId, setRadiusPinStreetEditId] = useState<number | null>(null)

    const [showCopyOrderModal, setShowCopyOrderModal] = useState<boolean>(false)
    const [copyOrderName, setCopyOrderName] = useState<string>("")

    const [hasMapLoaded, setHasMapLoaded] = useState<boolean>(false)
    const [center, setCenter] = useState<Coordinate>()

    const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

    const mapOptions: google.maps.MapOptions = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeId: 'hybrid',
        disableDefaultUI: true
    }

    const mapContainerStyle = {
        width: '550px',
        height: '350px',
        borderRadius: "14px"
    }

    // Aggregating is only available for the Premium version of DataGrid, so I'm making an additional row and putting it at the end of the table
    // -1 is used to hide the edit buttons
    const totalsRow = {
        id: -1,
        name: "Total: ",
        radiusPinAddress: "",
        radiusPinStreet: "",
        desiredQuantity: recipientSearch.totalDesiredQuantity,
        price: recipientSearch.totalPrice
    }

    const columns: GridColDef[] = [
        {
            headerName: "Search",
            field: "name",
            flex: 1
        },
        {
            headerName: "Radius Pin Address",
            field: "radiusPinAddress",
            flex: 1,
            renderEditCell: (params) => {
                if(params.row.id === -1){
                    return null
                }

                return <Grid container alignItems={"center"} gap={1} px={1}>
                    <Grid item flex={1}>
                        <MDInput
                            fullWidth={true}
                            type={"text"}
                            value={[...recipientSearch["addressRadiusSearches"], ...recipientSearch["polygonSearches"]].find((s) => s.id === params.row.id)?.radiusPinAddress}
                            backgroundColor={"white"}
                            onChange={(e: any) => {
                                setRecipientSearch(prevState => {
                                    let copy = {...prevState}

                                    for(let s of [...copy.addressRadiusSearches, ...copy.polygonSearches]){
                                        if(s.id === params.row.id){
                                            s.radiusPinAddress = e.target.value
                                            break
                                        }
                                    }

                                    return copy
                                })
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <Grid container alignItems={"center"} gap={1}>
                            <Grid item>
                                <Tooltip title={"Save"}>
                                    <MDBox onClick={() => {
                                        updateRecipientSearch()

                                        setCellModesModel({[params.row.id]: {"radiusPinAddress": {mode: GridCellModes.View}}})
                                    }} display={"flex"}>
                                        <CheckIcon htmlColor={"secondary"}/>
                                    </MDBox>
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                <Tooltip title={"Cancel"}>
                                    <MDBox onClick={() => {
                                        setCellModesModel({[params.row.id]: {"radiusPinAddress": {mode: GridCellModes.View}}})

                                        setRecipientSearch(prevState => {
                                            let copy = {...prevState}

                                            for(let s of [...copy.addressRadiusSearches, ...copy.polygonSearches]){
                                                if(s.id === params.row.id){
                                                    s.radiusPinAddress = params.value
                                                    break
                                                }
                                            }

                                            return copy
                                        })
                                    }} display={"flex"}>
                                        <CloseIcon htmlColor={"secondary"}/>
                                    </MDBox>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            },
            renderCell: (params) => {
                if(params.row.id === -1){
                    return null
                }

                if(params.row.id === -1){
                    return null
                }

                return <Grid container alignItems={"center"} gap={1}>
                    <Grid item flex={1}>
                        {params.value}
                    </Grid>

                    <Grid item>
                        <Tooltip title={"Edit"}>
                            <MDBox onClick={() => {
                                setCellModesModel({[params.row.id]: {"radiusPinAddress": {mode: GridCellModes.Edit}}})
                            }} display={"flex"}>
                                <EditPencilIcon htmlColor={"#A2A2A2"}/>
                            </MDBox>
                        </Tooltip>
                    </Grid>
                </Grid>
            }
        },
        {
            headerName: "Radius Pin Street",
            field: "radiusPinStreet",
            flex: 1,
            width: 200,
            renderEditCell: (params) => {
                if(params.row.id === -1){
                    return null
                }

                return <Grid container alignItems={"center"} gap={1} px={1}>
                    <Grid item flex={1}>
                        <MDInput
                            fullWidth={true}
                            type={"text"}
                            value={[...recipientSearch["addressRadiusSearches"], ...recipientSearch["polygonSearches"]].find((s) => s.id === params.row.id)?.radiusPinStreet}
                            backgroundColor={"white"}
                            onChange={(e: any) => {
                                setRecipientSearch(prevState => {
                                    let copy = {...prevState}

                                    for(let s of [...copy.addressRadiusSearches, ...copy.polygonSearches]){
                                        if(s.id === params.row.id){
                                            s.radiusPinStreet = e.target.value
                                            break
                                        }
                                    }

                                    return copy
                                })
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <Grid container alignItems={"center"} gap={1}>
                            <Grid item>
                                <Tooltip title={"Save"}>
                                    <MDBox onClick={() => {
                                        updateRecipientSearch()

                                        setCellModesModel({[params.row.id]: {"radiusPinStreet": {mode: GridCellModes.View}}})
                                    }} display={"flex"}>
                                        <CheckIcon htmlColor={"secondary"}/>
                                    </MDBox>
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                <Tooltip title={"Cancel"}>
                                    <MDBox onClick={() => {
                                        setCellModesModel({[params.row.id]: {"radiusPinStreet": {mode: GridCellModes.View}}})

                                        setRecipientSearch(prevState => {
                                            let copy = {...prevState}

                                            for(let s of [...copy.addressRadiusSearches, ...copy.polygonSearches]){
                                                if(s.id === params.row.id){
                                                    s.radiusPinStreet = params.value
                                                    break
                                                }
                                            }

                                            return copy
                                        })
                                    }} display={"flex"}>
                                        <CloseIcon htmlColor={"secondary"}/>
                                    </MDBox>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            },
            renderCell: (params) => {
                if(params.row.id === -1){
                    return null
                }

                return <Grid container alignItems={"center"} gap={1}>
                    <Grid item flex={1}>
                        {params.value}
                    </Grid>

                    <Grid item>
                        <Tooltip title={"Edit"}>
                            <MDBox onClick={() => {
                                setCellModesModel({[params.row.id]: {"radiusPinStreet": {mode: GridCellModes.Edit}}})
                            }} display={"flex"}>
                                <EditPencilIcon htmlColor={"#A2A2A2"}/>
                            </MDBox>
                        </Tooltip>
                    </Grid>
                </Grid>
            }
        },
        {
            headerName: "Quantity",
            field: "desiredQuantity",
            align: "right",
            headerAlign: "right",
            valueFormatter: (params) => {
                return formatNumber(params.value)
            },
        },
        {
            headerName: "Each",
            field: "",
            align: "right",
            headerAlign: "right",
            valueFormatter: (params) => {
                if(params.id === -1){
                    return null
                }

                return recipientSearch.multiUse ? '$0.11' : '$0.10'
            }
        },
        {
            headerName: "Amount",
            field: "price",
            align: "right",
            headerAlign: "right",
            width: 150,
            valueFormatter: (params) => {
                return formatPrice(params.value)
            },
        }
    ]

    useEffect(() => {
        if (!radiusPinStreetEditId || !radiusPinAddressEditId || !isEditingName) {
            setRecipientSearch(JSON.parse(JSON.stringify(recipientSearchOriginal)))
        }
    }, [recipientSearchOriginal, radiusPinAddressEditId, radiusPinStreetEditId, isEditingName]);

    useEffect(() => {
        if (radiusPinAddressEditId) {
            setRadiusPinStreetEditId(null)
        }
    }, [radiusPinAddressEditId]);

    useEffect(() => {
        if (radiusPinStreetEditId) {
            setRadiusPinAddressEditId(null)
        }
    }, [radiusPinStreetEditId]);

    function updateRecipientSearch() {
        setShowLoader(true)

        postRecipientSearch(recipientSearch).then((result) => {
            setRadiusPinAddressEditId(null)
            setRadiusPinStreetEditId(null)
            setIsEditingName(false)

            if (!result.hasErrors) {
                setRecipientSearchOriginal(result.payload)
                apiRef.updateRows([{id: recipientSearch.id, name: recipientSearch.name}]);
            }

            setShowLoader(false)
        })
    }

    function copyOrder(){
        setShowLoader(true)

        copyRecipientSearch(recipientSearch.id, copyOrderName).then((result) => {
            setShowLoader(false)
            setShowCopyOrderModal(false)

            navigate(`/recipient-search/order/${result.payload.id}`)
        })
    }

    function onMapLoaded() {
        setHasMapLoaded(true)

        // Sets the center of the map to be the center of the first recipient search entry
        if(recipientSearch.addressRadiusSearches.length){
            let centerObject = recipientSearch.addressRadiusSearches[0]

            setCenter({lat: centerObject.payload.coordinate.lat, lng: centerObject.payload.coordinate.lng})
        }
        else{
            if(recipientSearch.polygonSearches.length){
                let centerObject = recipientSearch.polygonSearches[0]

                setCenter(getCenterOfPolygon(centerObject.payload.coordinates))
            }
        }
    }

    return <MDBox>
        <MDBox p={3}>
            <MDCard border={true} borderColor={"dark"} boxShadow={false} borderRadiusSize={"xl"}>
                <MDBox p={2}>
                    <Grid container alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item flex={1}>
                            <Grid container alignItems={"center"} gap={"10px"}>
                                <Grid item>
                                    <Typography fontWeight={"bold"}>Name:</Typography>
                                </Grid>

                                <Grid item>
                                    <Grid container alignItems={"center"}>
                                        <Grid item>
                                            {isEditingName ?
                                                <TextField
                                                    type={"text"}
                                                    value={recipientSearch.name}
                                                    size={"small"}
                                                    onChange={(e) => setRecipientSearch(prevState => {
                                                        return {...prevState, name: e.target.value}
                                                    })}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                :
                                                <Typography fontWeight={"bold"}>{recipientSearchOriginal.name}</Typography>
                                            }
                                        </Grid>

                                        <Grid item>
                                            {isEditingName ?
                                                <>
                                                    <Tooltip title={"Save Name"}>
                                                        <IconButton onClick={updateRecipientSearch}>
                                                            <Icon fontSize={"small"}>check</Icon>
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title={"Cancel"}>
                                                        <IconButton onClick={() => setIsEditingName(false)}>
                                                            <Icon fontSize={"small"}>close</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                                :
                                                <Tooltip title={"Edit Name"}>
                                                    <IconButton onClick={() => setIsEditingName(true)}>
                                                        <Icon fontSize={"small"}>edit</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems={"center"} gap={1}>
                                <Grid item>
                                    <MDButton size={"small"} color="primary" onClick={() => {
                                        setShowCopyOrderModal(true)
                                    }}>
                                        <Grid container alignItems={"center"} gap={1}>
                                            <Grid item display={"flex"}>
                                                <SingleRecipientIcon/>
                                            </Grid>
                                            <Grid item mt={"3px"}>Add More Recipients</Grid>
                                        </Grid>
                                    </MDButton>
                                </Grid>

                                <Grid item>
                                    <MDButton size={"small"} color="light" onClick={() => {
                                        navigate(`/recipient-search/order/${recipientSearchOriginal.id}`)
                                    }}>
                                        <Grid container alignItems={"center"} gap={1}>
                                            <Grid item display={"flex"}>
                                                <InfoIcon color={"white"}/>
                                            </Grid>
                                            <Grid item mt={"3px"}>Details</Grid>
                                        </Grid>
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider light={true}/>

                    <Grid container gap={6}>
                        <Grid item>
                            <MDBox mt={2}>
                                <Grid container gap={"5px"} alignItems={"center"}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HomeAlt2Icon/>
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{fontSize: 16}} fontWeight={"bold"}>Home Owner: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{fontSize: 16}} fontWeight={"normal"}>
                                            {OwnerOrRenterFilter[recipientSearchOriginal.recipientSearchFilter.ownerOrRenterFilter] ?? "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HomeEditIcon/>
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{fontSize: 16}} fontWeight={"bold"}>Property Type: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{fontSize: 16}} fontWeight={"normal"}>
                                            {PropertyTypeFilter[recipientSearchOriginal.recipientSearchFilter.propertyTypeFilter] ?? "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HomeDollarIcon/>
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{fontSize: 16}} fontWeight={"bold"}>Household Income: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{fontSize: 16}} fontWeight={"normal"}>
                                            {
                                                recipientSearchOriginal.recipientSearchFilter.houseHoldIncomeFilter !== undefined && recipientSearchOriginal.recipientSearchFilter.houseHoldIncomeFilter !== null ?
                                                    `${formatPrice(recipientSearchOriginal.recipientSearchFilter.houseHoldIncomeFilter.min ?? 0)} - ${recipientSearchOriginal.recipientSearchFilter.houseHoldIncomeFilter.max ? formatPrice(recipientSearchOriginal.recipientSearchFilter.houseHoldIncomeFilter.max) : "$250,000.00+"}`
                                                    :
                                                    "N/A"
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HomeSearchIcon/>
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{fontSize: 16}} fontWeight={"bold"}>Length of Residency: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{fontSize: 16}} fontWeight={"normal"}>
                                            {
                                                recipientSearchOriginal.recipientSearchFilter.lengthOfResidenceFilter !== undefined && recipientSearchOriginal.recipientSearchFilter.lengthOfResidenceFilter !== null?
                                                    `${recipientSearchOriginal.recipientSearchFilter.lengthOfResidenceFilter.min ?? 0} - ${recipientSearchOriginal.recipientSearchFilter.lengthOfResidenceFilter.max ?? "15+"}`
                                                    :
                                                    "N/A"
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HomeShareIcon/>
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{fontSize: 16}} fontWeight={"bold"}>Year Built: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{fontSize: 16}} fontWeight={"normal"}>
                                            {
                                                recipientSearchOriginal.recipientSearchFilter.yearBuiltFilter ?
                                                    `${recipientSearchOriginal.recipientSearchFilter.yearBuiltFilter.min ?? "< 1900"} - ${recipientSearchOriginal.recipientSearchFilter.yearBuiltFilter.max ?? "> 2010"}`
                                                    :
                                                    "N/A"
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HomeHeartIcon/>
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{fontSize: 16}} fontWeight={"bold"}>Multi-Use: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{fontSize: 16}} fontWeight={"normal"}>
                                            {recipientSearchOriginal.multiUse? "Yes" : "No"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </Grid>

                        <Grid item>
                            <LoadScript googleMapsApiKey="AIzaSyAnZBcPxYjFTJ2p5VykxLnOcqM0gY4uIY0" onLoad={onMapLoaded}>
                                {hasMapLoaded &&
                                    <GoogleMap
                                        mapContainerStyle={mapContainerStyle}
                                        options={mapOptions}
                                        center={center}
                                        zoom={10}
                                    >
                                        {recipientSearch.addressRadiusSearches.filter((a) => a.payload.coordinate.lat && a.payload.coordinate.lng).map((address, index) => {
                                            return <MDBox key={index}>
                                                <OverlayView
                                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                    getPixelPositionOffset={(width, height) => ({
                                                        x: -(width / 2),
                                                        y: -(height / 2),
                                                    })}
                                                    position={{
                                                        lat: address.payload.coordinate.lat,
                                                        lng: address.payload.coordinate.lng
                                                    }}
                                                >
                                                    <Typography
                                                        whiteSpace={"nowrap"}
                                                        fontWeight={"bold"}
                                                        textAlign={"center"}
                                                        color={colors.white.main}
                                                        sx={{textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"}}>
                                                        {address.address}
                                                    </Typography>
                                                </OverlayView>

                                                <Circle
                                                    center={{
                                                        lat: address.payload.coordinate.lat,
                                                        lng: address.payload.coordinate.lng
                                                    }}
                                                    radius={address.payload.radius * 1609.34}
                                                    options={{
                                                        fillColor: colors.primary.main,
                                                        fillOpacity: 0.30,
                                                        strokeColor: colors.primary.main,
                                                        strokeOpacity: 0.8
                                                    }}
                                                />
                                            </MDBox>
                                        })}

                                        {recipientSearch.polygonSearches.map((polygon, index) => {
                                            return <MDBox key={index}>
                                                <OverlayView
                                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                    getPixelPositionOffset={(width, height) => ({
                                                        x: -(width / 2),
                                                        y: -(height / 2),
                                                    })}
                                                    position={getCenterOfPolygon(polygon.payload.coordinates)}
                                                >
                                                    <Typography
                                                        whiteSpace={"nowrap"}
                                                        fontWeight={"bold"}
                                                        textAlign={"center"}
                                                        color={colors.white.main}
                                                        sx={{textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"}}>
                                                        {polygon.name}
                                                    </Typography>
                                                </OverlayView>

                                                <MapsPolygon
                                                    key={polygon.id}
                                                    draggable={false}
                                                    editable={false}
                                                    path={polygon.payload.coordinates}
                                                    options={{
                                                        fillColor: colors.primary.main,
                                                        fillOpacity: 0.40,
                                                        strokeColor: colors.primary.main,
                                                        strokeOpacity: 1,
                                                        strokeWeight: 3
                                                    }}
                                                />
                                            </MDBox>
                                        })}
                                    </GoogleMap>
                                }
                            </LoadScript>
                        </Grid>
                    </Grid>

                    <MDBox mt={2}>
                        <StyledDataGrid
                            rows={[...recipientSearch.addressRadiusSearches?? [], ...recipientSearch.polygonSearches?? [], totalsRow]}
                            columns={columns}
                            disableRowSelectionOnClick={true}
                            pagination={false}
                            hideFooter={true}
                            noMinWidth={true}
                            cellModesModel={cellModesModel}
                            onCellModesModelChange={(model) => setCellModesModel(model)}
                        />
                    </MDBox>
                </MDBox>
            </MDCard>
        </MDBox>

        <Divider light={true}/>

        <TwoButtonWithOptionalFormFieldModal
            open={showCopyOrderModal}
            onClose={() => setShowCopyOrderModal(false)}
            primaryButtonOnClick={() => {
                copyOrder()
            }}
            primaryButtonText={"Okay"}
            secondaryButtonOnClick={() => {
                setShowCopyOrderModal(false)
            }}
            secondaryButtonText={"Cancel"}
            formFieldValue={copyOrderName}
            formFieldOnChange={value => {
                setCopyOrderName(value)
            }}
            formFieldLabel="Order Name"
            formFieldHelperText="Please name your newly copied order, this will help you identify it later"
        />
    </MDBox>
}

function RecipientSearchHistoryPage() {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const {setShowLoader} = useGlobal()

    const {getRecipientSearches, deleteRecipientSearch} = RecipientSearchService()

    let apiRef = useGridApiRef()

    const [showCreateOrderModal, setShowCreateOrderModal] = useState<boolean>(false)

    const [shouldFetch, setShouldFetch] = useState<boolean>(false)

    const [selectedRecipientSearchIdForOrderCreation, setSelectedRecipientSearchIdForOrderCreation] = useState<number | null>(null)

    const [expandedRowId, setExpandedRowId] = useState<GridRowId | null>(null)

    const [showDeleteOrderModal, setShowDeleteOrderModal] = useState<boolean>(false)
    const [recipientSearchToDelete, setRecipientSearchToDelete] = useState<RecipientSearch | null>(null)

    const getRecipientSearchesQuery = useQuery({
        queryFn: () => {
            return getRecipientSearches()
        },
        queryKey: ["recipientSearches"],
        refetchInterval: shouldFetch? 5000 : false,
        refetchOnWindowFocus: false
    })

    const columns: GridColDef[] = [
        {
            headerName: "Name",
            field: "name",
            flex: 1,
            minWidth: 200,
            maxWidth: 300
        },
        {
            headerName: "Status",
            field: "status",
            minWidth: 350,
            flex: 1,
            renderCell: function (params) {
                let statusForTracker = ""

                if(params.row.status === RecipientSearchStatus.New){
                    statusForTracker = "Draft"
                }
                else if(params.row.status === RecipientSearchStatus.ReadyForPayment){
                    statusForTracker = "Checkout"
                }
                else if(params.row.status === RecipientSearchStatus.ProcessingOrder){
                    statusForTracker = "Processing"
                }
                else if(params.row.status === RecipientSearchStatus.Complete){
                    statusForTracker = "Complete"
                }

                return <MDBox width={"100%"} maxWidth={"500px"}>
                    <StatusTracker statuses={["Draft", "Checkout", "Processing", "Complete"]} currentStatus={statusForTracker}/>
                </MDBox>
            }
        },
        {
            headerName: "Recipients",
            field: "totalDesiredQuantity",
            width: 180,
            renderCell: (params) => {
                let orderStatus = params.row.status

                if (orderStatus === RecipientSearchStatus.ProcessingCount) {
                    return <Tooltip title={"Fetching count..."}>
                        <CircularProgress size={"20px"} color="primary"/>
                    </Tooltip>
                }

                if (orderStatus === RecipientSearchStatus.ProcessingOrder) {
                    return <Tooltip title={"Fetching recipients..."}>
                        <CircularProgress size={"20px"} color="primary"/>
                    </Tooltip>
                }

                return params.value ? formatNumber(params.value) : null
            },
        },
        {
            headerName: "Updated Date",
            field: "updatedDate",
            width: 250,
            valueFormatter: (params) => {
                return formatDate(params.value)
            },
        },
        {
            type: "actions",
            field: "actions",
            align: "right",
            flex: 1,
            minWidth: 200,
            getActions: (params: any) => {
                let orderId = params.row.id

                let buttons = []

                if (params.row.status === RecipientSearchStatus.Complete || params.row.status === RecipientSearchStatus.Paid) {
                    buttons.push(
                        <Tooltip title="Create Order">
                            <MDIconButton onClick={() => {
                                setSelectedRecipientSearchIdForOrderCreation(orderId)
                                setShowCreateOrderModal(true)
                            }}>
                                    <MailboxIcon />
                            </MDIconButton>
                        </Tooltip>
                    )
                }
                buttons.push(
                    <Tooltip title={params.row.canDelete ? "Delete Recipient Search" : "Unable to delete Recipient Search"}>
                        <span>
                            <MDIconButton disabled={!params.row.canDelete} display={"flex"} onClick={() => {
                                setRecipientSearchToDelete(params.row)
                                setShowDeleteOrderModal(true)
                            }}>
                                <DeleteIcon color={"error"} />
                            </MDIconButton>
                            </span>
                    </Tooltip>
                )

                return buttons
            }
        }
    ]

    // If there's an order that is in status loading, continue refetching
    useEffect(function () {
        if(getRecipientSearchesQuery.data?.payload.length){
            for (let order of getRecipientSearchesQuery.data.payload) {
                if (order.status === RecipientSearchStatus.ProcessingOrder || order.status === RecipientSearchStatus.ProcessingCount) {
                    setShouldFetch(true)
                    break
                }
            }
        }

    }, [getRecipientSearchesQuery.data])

    function deleteOrder(){
        setShowLoader(true)

        deleteRecipientSearch(recipientSearchToDelete?.id!).then(() => {
            setShowDeleteOrderModal(false)

            setRecipientSearchToDelete(null)

            queryClient.invalidateQueries({queryKey: ["recipientSearches"]})

            setShowLoader(false)
        })
    }

    return <MDCard>
        <MDBox p={3} pl={4} pb={4}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="flex-end" gap={1}>
                    <MDTypography fontWeight={"bold"} sx={{fontSize: "26px"}}>Recipient Search</MDTypography>
                    <Tooltip
                        componentsProps={{ tooltip: { sx: { width: "300px", maxWidth: "unset" } } }} 
                        title={"Purchase recipients in an area and add your filters. Once purchased the data will end up in your address book and in the radius mail page, from there you can create mailings."}
                    >
                        <Box>
                            <Info2Icon color={"light2"}/>
                        </Box>
                    </Tooltip>
                </Box>
                <MDButton onClick={() => navigate("/recipient-search/order")}>
                    <PlusIcon style={{marginRight: 4}} /> New search
                </MDButton>
            </Box>

            <MDBox height={"80vh"} mt={3}>
                <StyledDataGrid
                    apiRef={apiRef}
                    disableBorders={true}
                    backgroundColor={"white"}
                    loading={getRecipientSearchesQuery.isLoading}
                    rows={getRecipientSearchesQuery.data?.payload ?? []}
                    columns={columns}
                    disableRowSelectionOnClick={true}
                    getDetailPanelHeight={() => "auto"}
                    detailPanelExpandedRowIds={expandedRowId? [expandedRowId] : []}
                    onDetailPanelExpandedRowIdsChange={(ids) => {
                        if(!ids){
                            setExpandedRowId(null)
                            return
                        }

                        // Only allow the user to expand one row at a time since the google api only allows 1 map shown at a time
                        setExpandedRowId(ids[ids.length - 1])
                    }}
                    getDetailPanelContent={(row) => {
                        return <MasterDetail row={row.row as RecipientSearch} apiRef={apiRef.current}/>
                    }}
                    pagination={false}
                    hideFooter={true}
                />
            </MDBox>
        </MDBox>

        <CreateOrderFlow
            show={showCreateOrderModal}
            setShow={setShowCreateOrderModal}
            recipientSearchId={selectedRecipientSearchIdForOrderCreation}
            showSelectFromPremadeDesignOption={true}
        />

        <InfoModal
            show={showDeleteOrderModal}
            setShow={setShowDeleteOrderModal}
            headerText={"Are you sure you want to delete this recipient search?"}
            showConfirmButton={true}
            showCancelButton={true}
            cancelButtonOnClick={() => {
                setShowDeleteOrderModal(false)
            }}
            confirmButtonOnClick={() => {
                deleteOrder()
            }}
        />
    </MDCard>
}

export default RecipientSearchHistoryPage