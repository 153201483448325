import { Autocomplete, Dialog, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import FormikErrorMessage from "components/formik-error-message";
import TemplateScenePreview from "components/template-scene-preview";
import { useGlobal } from "context/global-context";
import { Field, FieldProps, Form, Formik } from "formik";
import MDButton from "material-ui/components/MDButton";
import { copyTemplateValidation } from "models/modelValidations/copyTemplate";
import { IProfile } from "models/profile";
import { Template } from "models/template";
import {  useState } from "react";
import ProfileService from "services/profile";
import TemplateService from "services/template";

interface CopyTemplateParams {
  templateName: string,
  userIdToCopyTo: number | null
}

interface CopyTemplateModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  template: Template
}

const CopyTemplateModal = ({ open, setOpen, template }: CopyTemplateModalProps) => {
  const queryClient = useQueryClient()
  const { setShowLoader } = useGlobal()
  const { createTemplateFromOrder } = TemplateService()
  const { getLoggedInProfile } = ProfileService()
  const [userToCopyTo, setUserToCopyTo] = useState<IProfile | null>(null)

  const { data: profile } = useQuery({
    queryKey: ["loggedInProfile"],
    queryFn: getLoggedInProfile,
  })

  const { mutate: copyTemplate } = useMutation({
    mutationFn: ({ templateName, userIdToCopyTo }: CopyTemplateParams) => createTemplateFromOrder(template.id, templateName, userIdToCopyTo),
    onMutate: () => setShowLoader(true),
    onSuccess: () => {
      setOpen(false)
      setShowLoader(false)
      queryClient.invalidateQueries({ queryKey: ["templates"] })
    }
  })

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth={"md"} fullWidth>
      <DialogContent>
        <Formik
          initialValues={{ templateName: "", userIdToCopyTo: null }}
          validationSchema={copyTemplateValidation}
          onSubmit={(values) => copyTemplate(values)}
        >
          {({ setFieldValue }) => (
            <Form>
              <DialogTitle>Copy template</DialogTitle>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field name={"templateName"}>
                    {({ field, meta }: FieldProps) => (
                      <>
                        <TextField
                          {...field}
                          fullWidth
                          type="text"
                          label={"Template Name"}
                          InputLabelProps={{ shrink: true }}
                        />
                        <FormikErrorMessage meta={meta} />
                      </>
                    )}
                  </Field>
                </Grid>
                {profile?.isAdmin && !profile?.actingAsUserId && (
                  <Grid item xs={12} md={6}>
                    <Field name={"userIdToCopyTo"}>
                      {({ field }: FieldProps) => (
                        <Autocomplete
                          {...field}
                          fullWidth
                          blurOnSelect
                          options={profile.allProfiles}
                          getOptionLabel={(option) => typeof option === 'string' ? option : `${option.firstName} ${option.lastName} - ${option.email}`}
                          value={userToCopyTo}
                          onChange={(_, newValue) => {
                            const aux = newValue as IProfile
                            setUserToCopyTo(aux)
                            setFieldValue('userIdToCopyTo', aux?.id || null)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              label="User to copy template to "
                              placeholder="Myself"
                            />
                          )}
                        />
                      )}
                    </Field>
                  </Grid>
                )}
                <Grid item>
                  <TemplateScenePreview {...template} />
                </Grid>
                <Grid item container justifyContent="flex-end" gap={1}>
                  <MDButton circular color="light" onClick={() => setOpen(false)}>
                    Cancel
                  </MDButton>
                  <MDButton circular type="submit">
                    Create
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default CopyTemplateModal;