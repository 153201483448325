import { useRef } from 'react';
import { Box, Collapse } from '@mui/material';

import { useEditorContext } from '../contexts/useEditorContext';
import useCreateCardEditor from '../hooks/useCreateCardEditor';
import useBlockSelection from '../hooks/useBlockSelection';
import useLoadCardScene from '../hooks/useLoadCardScene';
import useStyledEditor from '../hooks/useStyledEditor';
import ReturnAddressPanel from './ReturnAddressPanel';
import LettrLabsPanel from './LettrLabsPanel';
import PanelWrapper from './PanelWrapper';
import ZoomButtons from './ZoomButtons';

export default function CardEditor() {
  const cesdkRef = useRef<HTMLDivElement>(null);

  const { engine, lettrLabsPanel, returnAddressPanel, setReturnAddressPanel } = useEditorContext();

  useCreateCardEditor(cesdkRef);
  useStyledEditor();
  useLoadCardScene();
  useBlockSelection();

  const handlePanelClose = () => {
    engine?.variable?.setString('returnAddress', 'false');
    setReturnAddressPanel(false);
  };

  return (
    <>
      <Box ref={cesdkRef} style={{ height: '85vh', flex: 1 }} />
      <Collapse in={lettrLabsPanel}>
        <LettrLabsPanel />
      </Collapse>
      <Collapse in={returnAddressPanel}>
        <PanelWrapper title="Return Address" onPanelClose={handlePanelClose}>
          <ReturnAddressPanel />
        </PanelWrapper>
      </Collapse>
      <ZoomButtons />
    </>
  );
}
