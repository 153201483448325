import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Dialog, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FieldMetaProps } from 'formik';

import TemplateScenePreview, { ITemplatePreviews } from 'components/template-scene-preview';
import FormikErrorMessage from 'components/formik-error-message';
import ArtifactService from 'services/artifact-service';
import MDButton from 'material-ui/components/MDButton';
import EditPencilIcon from 'assets/icons/edit-pencil';
import MDCard from 'material-ui/components/MDCard';
import { useGlobal } from 'context/global-context';
import DeleteIcon from 'assets/icons/delete';
import { ITemplate } from 'models/template';
import { ProductOptions } from 'newStandard/src/services/order/types';

interface IOrderDefault {
  template?: { id: number; hasDiscount?: boolean };
  templateId?: number;
  multiUseCouponId?: number | null;
  couponListId?: number | null;
}

interface SelectTemplateProps<T extends IOrderDefault> {
  order: T;
  meta?: FieldMetaProps<T>;
  template?: ITemplate;
  disabled?: boolean;
  disableEditOption?: boolean;
  shouldCheckNotSupported?: boolean;
  onDelete: () => void;
  onTemplateChange: (template: ITemplate) => void;
}

const SelectTemplate = <T extends IOrderDefault>({
  order,
  meta,
  template,
  disabled,
  disableEditOption,
  shouldCheckNotSupported,
  onDelete,
  onTemplateChange,
}: SelectTemplateProps<T>) => {
  const navigate = useNavigate();
  const { setTemplateFlow } = useGlobal();
  const { pathname, search } = useLocation();
  const { getOrderProofs } = ArtifactService();
  const [showTemplateZoom, setShowTemplateZoom] = useState<boolean>(false);
  const [templatePreviews, setTemplatePreviews] = useState<ITemplatePreviews>();
  const templateId = order?.templateId || order?.template?.id || '';
  const isBifold = template?.product === ProductOptions.HandwrittenBiFoldCard

  const { data: { payload: images } = {}, refetch } = useQuery({
    queryFn: () => getOrderProofs(template?.id!),
    queryKey: ['getOrderProofs'],
    enabled: false,
  });

  useEffect(() => {
    if (!template?.id) return;
    if (template.sceneFrontThumb) return setTemplatePreviews(template);
    if (!images?.length) refetch();
    else {
      setTemplatePreviews({
        sceneFrontThumb: images[0].blobUri,
        sceneBackThumb: images[1].blobUri,
        envelopeFull: images[2]?.blobUri,
      });
    }
  }, [images, refetch, template]);

  const handleTemplateChange = (isEdit?: boolean) => {
    setTemplateFlow({ onTemplateChange, order, showModal: !isEdit, shouldCheckNotSupported, originalRoute: `${pathname}${search}` });
    if (isEdit) navigate(`/template/${templateId}`);
  };

  if (templateId && template) {
    return (
      <Box>
        <MDCard border borderColor="dark" color="light" sx={{ width: 'fit-content', maxWidth: '500px' }}>
          <Box role="button" onClick={() => setShowTemplateZoom(true)} p={2} sx={{ cursor: 'pointer' }}>
            <TemplateScenePreview {...templatePreviews} isBifold={isBifold} />
          </Box>
          {!disabled && (
            <Box position="absolute" top="20px" right="20px">
              {!disableEditOption && (
                <IconButton onClick={() => handleTemplateChange(true)}>
                  <EditPencilIcon />
                </IconButton>
              )}
              <IconButton color="error" onClick={() => onDelete()}>
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
        </MDCard>
        <Dialog
          fullWidth
          open={showTemplateZoom}
          maxWidth={false}
          sx={{ maxWidth: 2000, marginX: 'auto' }}
          onClose={() => setShowTemplateZoom(false)}
        >
          <TemplateScenePreview {...templatePreviews} isBifold={isBifold} sx={{ padding: 2 }} />
        </Dialog>
      </Box>
    );
  }

  return (
    <>
      <MDButton
        disabled={disabled}
        onClick={() => handleTemplateChange()}
        sx={meta?.error && meta?.touched ? { border: '1px solid red' } : undefined}
      >
        Select Card Template
      </MDButton>
      {meta && <FormikErrorMessage meta={meta} />}
    </>
  );
};

export default SelectTemplate;
