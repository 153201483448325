import Retool from 'react-retool';
import { Box } from '@mui/material';

export default function AdminSoftwareAdoption() {
  return (
    <Box height={'90vh'}>
      <Retool url="https://lettrlabs.retool.com/p/software-adoption" />
    </Box>
  );
}
