import { useState } from 'react';
import { CreativeEngine } from '@cesdk/cesdk-js';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { ProductOptions } from 'newStandard/src/services/order/types';
import { OrderParameter } from 'models/orderParameter';
import { useGlobal } from 'context/global-context';
import OrderService from 'services/order';

import { KeepOutColor, setKeepOutBlockColor, setWhiteOutBlockColor } from '../utils/keepOutHelper';
import { checkHasEnvelope, checkIsPrintedCard } from '../utils/templateHelper';
import { ArtifactType, IArtifact } from '../services/artifact/types';
import { SceneArtifactType } from '../services/sceneService/types';
import { MailMergeMapType } from '../utils/mailMergeOptions';
import useSceneService from '../services/sceneService';
import useArtifactService from '../services/artifact';
import { BlockNames } from '../utils/sceneEnums';

interface UseSaveSceneProps {
  engine?: CreativeEngine;
  envelopeEngine?: CreativeEngine;
  mailMergeFields?: MailMergeMapType;
}

export function useSaveScene({ engine, envelopeEngine, mailMergeFields }: UseSaveSceneProps) {
  const queryClient = useQueryClient();
  const { setShowLoader } = useGlobal();
  const { uploadScene } = useSceneService();
  const { removeArtifact } = useArtifactService();
  const { postOrderParameters, getOrderParameters } = OrderService();
  const { template, setTemplate, nextStep, changeStep } = useWorkflowContext();

  const [parameters, setParameters] = useState<OrderParameter[]>([]);

  const { mutate: deleteArtifact } = useMutation({ mutationFn: (id: number) => removeArtifact(template.id, id) });

  const { data: { payload: orderParameters = [] } = {} } = useQuery({
    queryKey: ['orderParameters', template.id],
    queryFn: () => getOrderParameters(template.id),
  });

  const { mutate: saveMailMergeFields } = useMutation({
    mutationFn: (parameters: OrderParameter[]) => postOrderParameters(template.id, parameters),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orderRecipients'] });
      queryClient.invalidateQueries({ queryKey: ['orderParameters'] });
    },
  });

  const shouldSaveParameters = (auxParameters: OrderParameter[]) => {
    if (auxParameters.length !== orderParameters.length) return true;
    for (const ap of auxParameters) {
      const orderParameter = orderParameters.find((op) => op.parameterType === ap.parameterType);
      if (!orderParameter || orderParameter.parameterText !== ap.parameterText) return true;
    }
    return false;
  };

  const mailMergeToParameter = () => {
    const mailMergeArray = [...(mailMergeFields?.entries() ?? [])];
    const auxParameters = mailMergeArray.map((field) => ({ id: 0, parameterType: field[0], parameterText: field[1] }));
    setParameters(auxParameters);
  };

  const updateTemplateDetails = () => {
    setTemplate((prev) => {
      const auxTemplate = { ...prev };
      if (prev.templateId !== null) auxTemplate.templateId = null;
      if (prev.qrUrl && engine) {
        const qrCodeBlock = engine.block.findByName(BlockNames.QRCode)[0];
        auxTemplate.qrAngle = engine.block.getRotation(qrCodeBlock);
        auxTemplate.qrCodeX = engine.block.getPositionX(qrCodeBlock);
        auxTemplate.qrCodeY = engine.block.getPositionY(qrCodeBlock);
        auxTemplate.qrCodeHeight = engine.block.getHeight(qrCodeBlock);
        auxTemplate.qrCodeWidth = auxTemplate.qrCodeHeight;
      }
      return auxTemplate;
    });
  };

  const makeKeepOutWhite = () => {
    if (!engine) return;
    if (checkIsPrintedCard(template.product)) {
      setWhiteOutBlockColor(engine, KeepOutColor.WHITE);
    } else if (template.product === ProductOptions.HandwrittenPostCardA8) {
      setKeepOutBlockColor(engine, KeepOutColor.WHITE, BlockNames.HWPCKeepOutZone);
    } else if (template.product === ProductOptions.HandwrittenBiFoldCard) {
      setKeepOutBlockColor(engine, KeepOutColor.WHITE, BlockNames.OutsideKeepOutArea);
    }
  };

  const handleArtifactUpload = async (engineInstance: CreativeEngine, sceneType: SceneArtifactType) => {
    const formData = new FormData();
    formData.append('scene', await engineInstance.scene.saveToString());
    return uploadScene({ id: template.id, sceneType, formData });
  };

  const updateArtifacts = (artifacts: IArtifact[], newArtifact: IArtifact) => {
    const updatedArtifacts = [...artifacts];
    const index = updatedArtifacts.findIndex((artifact) => artifact.artifactType === newArtifact.artifactType);
    if (index >= 0) updatedArtifacts[index] = newArtifact;
    else updatedArtifacts.push(newArtifact);
    return updatedArtifacts;
  };

  const saveCurrentScene = async () => {
    if (!engine) return;
    setShowLoader(true);
    mailMergeToParameter();
    const rearArtifact = template.orderArtifacts.find((artifact) => artifact.artifactType === ArtifactType.RearImage);
    if (rearArtifact) deleteArtifact(rearArtifact.id);
    updateTemplateDetails();
    makeKeepOutWhite();
    
    let artifacts = [...template.orderArtifacts];
    const savedCardArtifact = await handleArtifactUpload(engine, ArtifactType.CreativeEditorScene);
    artifacts = updateArtifacts(artifacts, savedCardArtifact);
    if (checkHasEnvelope(template.product) && envelopeEngine) {
      const savedEnvelopeArtifact = await handleArtifactUpload(envelopeEngine, ArtifactType.EnvelopeScene);
      artifacts = updateArtifacts(artifacts, savedEnvelopeArtifact);
    }

    const hasDiscount = !!parameters.find((el) => el.parameterType === 'DISCOUNT CODE');
    changeStep(
      nextStep ?? 3,
      { ...template, hasDiscount, orderArtifacts: artifacts },
      shouldSaveParameters(parameters) ? () => saveMailMergeFields(parameters) : undefined
    );
    setShowLoader(false);
  };

  return { saveCurrentScene };
}
