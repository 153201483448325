import React, {useEffect, useState} from "react";
import {
    Box,
    Divider,
    Grid,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import {
    GridCellModes,
    GridCellModesModel,
    GridColDef,
    useGridApiRef
} from "@mui/x-data-grid-pro";
import {useNavigate} from "react-router-dom";
import {GridRowId} from '@mui/x-data-grid';
import {formatNumber, formatPrice} from "helpers/formatters";
import MDBox from "material-ui/components/MDBox";
import {GridApiPro} from "@mui/x-data-grid-pro/models/gridApiPro";
import StyledDataGrid from "components/styled-data-grid";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import MDCard from "material-ui/components/MDCard";
import HomeAlt2Icon from "assets/icons/home-alt-2";
import HomeEditIcon from "assets/icons/home-edit";
import HomeDollarIcon from "assets/icons/home-dollar";
import HomeSearchIcon from "assets/icons/home-search";
import HomeShareIcon from "assets/icons/home-share";
import EditPencilIcon from "assets/icons/edit-pencil";
import MDInput from "material-ui/components/MDInput";
import CloseIcon from "assets/icons/close";
import CheckIcon from "assets/icons/check";
import DeleteIcon from "assets/icons/delete";
import InfoModal from "components/info-modal";
import StatusTracker from "components/status-tracker";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import {Coordinate} from "models/coordinate";
import {formatDate} from "helpers/formatters";
import MDTypography from "material-ui/components/MDTypography";
import {OwnerOrRenterFilter, PropertyTypeFilter} from "models/radius-mailing/filters";
import RadiusMailService from "services/radius-mail";
import {RadiusMail} from "models/radius-mailing/radiusMail";
import { OrderStatus } from "models/enums/orderStatus";
import Info2Icon from "assets/icons/info-2";
import MDIconButton from "material-ui/components/MDIconButton";
import MDButton from "material-ui/components/MDButton";
import PlusIcon from "assets/icons/plus";
import { useGlobal } from "context/global-context";

interface MasterDetailProps {
    row: RadiusMail
    apiRef: GridApiPro
}

function MasterDetail({row, apiRef}: MasterDetailProps) {
    const {setShowLoader} = useGlobal()

    const {postRadiusMail} = RadiusMailService()

    const [radiusMailOriginal, setRadiusMailOriginal] = useState<RadiusMail>(JSON.parse(JSON.stringify(row)))
    const [radiusMail, setRadiusMail] = useState<RadiusMail>(JSON.parse(JSON.stringify(row)))

    const [isEditingName, setIsEditingName] = useState<boolean>(false)
    const [radiusPinAddressEditId, setRadiusPinAddressEditId] = useState<number | null>(null)
    const [radiusPinStreetEditId, setRadiusPinStreetEditId] = useState<number | null>(null)

    const [hasMapLoaded, setHasMapLoaded] = useState<boolean>(false)
    const [center, setCenter] = useState<Coordinate>()

    const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

    const mapOptions: google.maps.MapOptions = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeId: 'hybrid',
        disableDefaultUI: true
    }

    const mapContainerStyle = {
        width: '450px',
        height: '250px',
        borderRadius: "14px"
    }

    // Aggregating is only available for the Premium version of DataGrid, so I'm making an additional row and putting it at the end of the table
    // -1 is used to hide the edit buttons
    const totalsRow = {
        id: -1,
        address: "Total: ",
        radiusPinAddress: "",
        radiusPinStreet: "",
        structures: radiusMail.totalStructures,
        pricePerCard: radiusMail.pricePerCard,
        field: radiusMail.totalStructures * radiusMail.pricePerCard
    }

    const columns: GridColDef[] = [
        {
            headerName: "Search",
            field: "address",
            flex: 1
        },
        {
            renderHeader: () => {
                return <Grid container alignItems={"center"} gap={0.5}>
                    <Grid item>
                        <MDTypography color={"white"} sx={{fontSize: 16}} fontWeight={"bold"}>Radius Pin Address</MDTypography>
                    </Grid>

                    <Grid item>
                        <Tooltip title={`This is a mail merge field that uses the radius pin's address ex. "123 Main St."`}>
                            <MDBox display={"flex"}>
                                <Info2Icon color={"light"}/>
                            </MDBox>
                        </Tooltip>
                    </Grid>
                </Grid>
            },
            field: "radiusPinAddress",
            flex: 1,
            renderEditCell: (params) => {
                if(params.row.id === -1){
                    return null
                }

                return <Grid container alignItems={"center"} gap={1} px={1}>
                    <Grid item flex={1}>
                        <MDInput
                            fullWidth={true}
                            type={"text"}
                            value={radiusMail.addresses.find((s) => s.id === params.row.id)?.radiusPinAddress}
                            backgroundColor={"white"}
                            onChange={(e: any) => {
                                setRadiusMail(prevState => {
                                    let copy = {...prevState}

                                    for(let s of [...copy.addresses]){
                                        if(s.id === params.row.id){
                                            s.radiusPinAddress = e.target.value
                                            break
                                        }
                                    }

                                    return copy
                                })
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <Grid container alignItems={"center"} gap={1}>
                            <Grid item>
                                <Tooltip title={"Save"}>
                                    <MDBox onClick={() => {
                                        updateRadiusMail()

                                        setCellModesModel({[params.row.id]: {"radiusPinAddress": {mode: GridCellModes.View}}})
                                    }} display={"flex"}>
                                        <CheckIcon htmlColor={"secondary"}/>
                                    </MDBox>
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                <Tooltip title={"Cancel"}>
                                    <MDBox onClick={() => {
                                        setCellModesModel({[params.row.id]: {"radiusPinAddress": {mode: GridCellModes.View}}})

                                        setRadiusMail(prevState => {
                                            let copy = {...prevState}

                                            for(let s of copy.addresses){
                                                if(s.id === params.row.id){
                                                    s.radiusPinAddress = params.value
                                                    break
                                                }
                                            }

                                            return copy
                                        })
                                    }} display={"flex"}>
                                        <CloseIcon htmlColor={"secondary"}/>
                                    </MDBox>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            },
            renderCell: (params) => {
                if(params.row.id === -1){
                    return null
                }

                return <Grid container alignItems={"center"} gap={1}>
                    <Grid item flex={1}>
                        {params.value}
                    </Grid>

                    <Grid item>
                        {/*<Tooltip title={"Edit"}>
                            <MDBox onClick={() => {
                                setCellModesModel({[params.row.id]: {"radiusPinAddress": {mode: GridCellModes.Edit}}})
                            }} display={"flex"}>
                                <EditPencilIcon htmlColor={"#A2A2A2"}/>
                            </MDBox>
                        </Tooltip>*/}
                    </Grid>
                </Grid>
            }
        },
        {
            renderHeader: () => {
                return <Grid container alignItems={"center"} gap={0.5}>
                    <Grid item>
                        <MDTypography color={"white"} sx={{fontSize: 16}} fontWeight={"bold"}>Radius Pin Street</MDTypography>
                    </Grid>

                    <Grid item>
                        <Tooltip title={`This is a mail merge field that uses the radius pin's street ex. "Main St."`}>
                            <MDBox display={"flex"}>
                                <Info2Icon color={"light"}/>
                            </MDBox>
                        </Tooltip>
                    </Grid>
                </Grid>
            },
            field: "radiusPinStreet",
            flex: 1,
            width: 200,
            renderEditCell: (params) => {
                if(params.row.id === -1){
                    return null
                }

                return <Grid container alignItems={"center"} gap={1} px={1}>
                    <Grid item flex={1}>
                        <MDInput
                            fullWidth={true}
                            type={"text"}
                            value={radiusMail.addresses.find((s) => s.id === params.row.id)?.radiusPinStreet}
                            backgroundColor={"white"}
                            onChange={(e: any) => {
                                setRadiusMail(prevState => {
                                    let copy = {...prevState}

                                    for(let s of copy.addresses){
                                        if(s.id === params.row.id){
                                            s.radiusPinStreet = e.target.value
                                            break
                                        }
                                    }

                                    return copy
                                })
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <Grid container alignItems={"center"} gap={1}>
                            <Grid item>
                                <Tooltip title={"Save"}>
                                    <MDBox onClick={() => {
                                        updateRadiusMail()

                                        setCellModesModel({[params.row.id]: {"radiusPinStreet": {mode: GridCellModes.View}}})
                                    }} display={"flex"}>
                                        <CheckIcon htmlColor={"secondary"}/>
                                    </MDBox>
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                <Tooltip title={"Cancel"}>
                                    <MDBox onClick={() => {
                                        setCellModesModel({[params.row.id]: {"radiusPinStreet": {mode: GridCellModes.View}}})

                                        setRadiusMail(prevState => {
                                            let copy = {...prevState}

                                            for(let s of copy.addresses){
                                                if(s.id === params.row.id){
                                                    s.radiusPinStreet = params.value
                                                    break
                                                }
                                            }

                                            return copy
                                        })
                                    }} display={"flex"}>
                                        <CloseIcon htmlColor={"secondary"}/>
                                    </MDBox>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            },
            renderCell: (params) => {
                if(params.row.id === -1){
                    return null
                }

                return <Grid container alignItems={"center"} gap={1}>
                    <Grid item flex={1}>
                        {params.value}
                    </Grid>

                    <Grid item>
                        {/*<Tooltip title={"Edit"}>
                            <MDBox onClick={() => {
                                setCellModesModel({[params.row.id]: {"radiusPinStreet": {mode: GridCellModes.Edit}}})
                            }} display={"flex"}>
                                <EditPencilIcon htmlColor={"#A2A2A2"}/>
                            </MDBox>
                        </Tooltip>*/}
                    </Grid>
                </Grid>
            }
        },
        {
            headerName: "Quantity",
            field: "structures",
            align: "right",
            headerAlign: "right",
            valueFormatter: (params) => {
                return formatNumber(params.value)
            },
            valueGetter: (params) => {
                return params.value?? 0
            }
        },
        {
            headerName: "Each",
            field: "pricePerCard",
            align: "right",
            headerAlign: "right",
            valueFormatter: (params) => {
                return formatPrice(radiusMailOriginal.pricePerCard)
            }
        },
        {
            headerName: "Amount",
            field: "price",
            align: "right",
            headerAlign: "right",
            width: 150,
            valueFormatter: (params) => {
                return formatPrice(params.value)
            },
            valueGetter: (params) => {
                return (radiusMailOriginal.pricePerCard?? 0) * (params.row.structures?? 0)
            }
        }
    ]

    useEffect(() => {
        if (!radiusPinStreetEditId || !radiusPinAddressEditId || !isEditingName) {
            setRadiusMail(JSON.parse(JSON.stringify(radiusMailOriginal)))
        }
    }, [radiusMailOriginal, radiusPinAddressEditId, radiusPinStreetEditId, isEditingName]);

    useEffect(() => {
        if (radiusPinAddressEditId) {
            setRadiusPinStreetEditId(null)
        }
    }, [radiusPinAddressEditId]);

    useEffect(() => {
        if (radiusPinStreetEditId) {
            setRadiusPinAddressEditId(null)
        }
    }, [radiusPinStreetEditId]);

    function updateRadiusMail() {
        setShowLoader(true)

        postRadiusMail(radiusMail).then((result) => {
            setRadiusPinAddressEditId(null)
            setRadiusPinStreetEditId(null)
            setIsEditingName(false)

            if (!result.hasErrors) {
                setRadiusMailOriginal(result.payload)
                apiRef.updateRows([{id: radiusMail.id, name: radiusMail.name}]);
            }

            setShowLoader(false)
        })
    }

    function onMapLoaded() {
        setHasMapLoaded(true)

        // Sets the center of the map to be the center of the first recipient search entry
        if(radiusMail.addresses.length){
            let centerObject = radiusMail.addresses[0]

            setCenter({lat: centerObject.coordinate.lat, lng: centerObject.coordinate.lng})
        }
    }

    return <MDBox>
        <MDBox p={3}>
            <MDCard border={true} borderColor={"dark"} boxShadow={false} borderRadiusSize={"xl"}>
                <MDBox p={2}>
                    <Grid container alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item flex={1}>
                            <Grid container alignItems={"center"} gap={"10px"}>
                                <Grid item>
                                    <Typography fontWeight={"bold"}>Name:</Typography>
                                </Grid>

                                <Grid item>
                                    <Grid container alignItems={"center"}>
                                        <Grid item>
                                            {isEditingName ?
                                                <TextField
                                                    type={"text"}
                                                    value={radiusMail.name}
                                                    size={"small"}
                                                    onChange={(e) => setRadiusMail(prevState => {
                                                        return {...prevState, name: e.target.value}
                                                    })}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                :
                                                <Typography fontWeight={"bold"}>{radiusMailOriginal.name}</Typography>
                                            }
                                        </Grid>

                                        <Grid item>
                                            {isEditingName ?
                                                <>
                                                    <Tooltip title={"Save Name"}>
                                                        <IconButton onClick={updateRadiusMail}>
                                                            <Icon fontSize={"small"}>check</Icon>
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title={"Cancel"}>
                                                        <IconButton onClick={() => setIsEditingName(false)}>
                                                            <Icon fontSize={"small"}>close</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                                :
                                                <Tooltip title={"Edit Name"}>
                                                    <IconButton onClick={() => setIsEditingName(true)}>
                                                        <Icon fontSize={"small"}>edit</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider light={true}/>

                    <Grid container gap={6}>
                        <Grid item>
                            <MDBox mt={2}>
                                <Grid container gap={"5px"} alignItems={"center"}>
                                    <Grid item>
                                        <Typography sx={{fontSize: 16}} fontWeight={"bold"}>Status: </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{fontSize: 16}} fontWeight={"normal"}>
                                            {radiusMailOriginal.status}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={0.5}>
                                    <Grid item>
                                        <Typography sx={{fontSize: 16}} fontWeight={"bold"}>Total Recipients: </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{fontSize: 16}} fontWeight={"normal"}>
                                            {radiusMailOriginal.totalStructures}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {(radiusMail.estimatedMailDate || radiusMail.mailedDate)?
                                    <Grid container gap={"5px"} alignItems={"center"} mt={0.5}>
                                        <Grid item>
                                            <Typography sx={{fontSize: 16}} fontWeight={"bold"}>{radiusMail.estimatedMailDate? "Est. Mail Date:" : "Mailed Date:"}</Typography>
                                        </Grid>

                                        <Grid item>
                                            <Typography sx={{fontSize: 16}} fontWeight={"normal"}>
                                                {radiusMail.estimatedMailDate?
                                                    formatDate(radiusMail.estimatedMailDate)
                                                    :
                                                    radiusMail.mailedDate?
                                                        formatDate(radiusMail.mailedDate)
                                                        :
                                                        null
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    :
                                    null
                                }

                                <Divider light={true}/>

                                <Grid container gap={"5px"} alignItems={"center"}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HomeAlt2Icon/>
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{fontSize: 16}} fontWeight={"bold"}>Home Owner: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{fontSize: 16}} fontWeight={"normal"}>
                                            {OwnerOrRenterFilter[radiusMailOriginal.radiusMailFilters.ownerOrRenterFilter] ?? "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HomeEditIcon/>
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{fontSize: 16}} fontWeight={"bold"}>Property Type: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{fontSize: 16}} fontWeight={"normal"}>
                                            {PropertyTypeFilter[radiusMailOriginal.radiusMailFilters.propertyTypeFilter] ?? "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HomeDollarIcon/>
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{fontSize: 16}} fontWeight={"bold"}>Household Income: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{fontSize: 16}} fontWeight={"normal"}>
                                            {
                                                radiusMailOriginal.radiusMailFilters.houseHoldIncomeFilter !== undefined && radiusMailOriginal.radiusMailFilters.houseHoldIncomeFilter !== null?
                                                    `${formatPrice(radiusMailOriginal.radiusMailFilters.houseHoldIncomeFilter.min ?? 0)} - ${radiusMailOriginal.radiusMailFilters.houseHoldIncomeFilter.max ? formatPrice(radiusMailOriginal.radiusMailFilters.houseHoldIncomeFilter.max) : "$250,000.00+"}`
                                                    :
                                                    "N/A"
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HomeSearchIcon/>
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{fontSize: 16}} fontWeight={"bold"}>Length of Residency: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{fontSize: 16}} fontWeight={"normal"}>
                                            {
                                                radiusMailOriginal.radiusMailFilters.lengthOfResidenceFilter !== undefined && radiusMailOriginal.radiusMailFilters.lengthOfResidenceFilter !== null?
                                                    `${radiusMailOriginal.radiusMailFilters.lengthOfResidenceFilter.min ?? 0} - ${radiusMailOriginal.radiusMailFilters.lengthOfResidenceFilter.max ?? "15+"}`
                                                    :
                                                    "N/A"
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container gap={"5px"} alignItems={"center"} mt={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} gap={0.5}>
                                            <Grid item display={"flex"}>
                                                <HomeShareIcon/>
                                            </Grid>

                                            <Grid item>
                                                <Typography sx={{fontSize: 16}} fontWeight={"bold"}>Year Built: </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography sx={{fontSize: 16}} fontWeight={"normal"}>
                                            {
                                                radiusMailOriginal.radiusMailFilters.yearBuiltFilter ?
                                                    `${radiusMailOriginal.radiusMailFilters.yearBuiltFilter.min ?? "< 1900"} - ${radiusMailOriginal.radiusMailFilters.yearBuiltFilter.max ?? "> 2010"}`
                                                    :
                                                    "N/A"
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </Grid>

                        <Grid item>
                            <LoadScript googleMapsApiKey="AIzaSyAnZBcPxYjFTJ2p5VykxLnOcqM0gY4uIY0" onLoad={onMapLoaded}>
                                {hasMapLoaded &&
                                    <GoogleMap
                                        mapContainerStyle={mapContainerStyle}
                                        options={mapOptions}
                                        center={center}
                                        zoom={10}
                                    >
                                        {radiusMail.addresses?.map((address, index) => {
                                            return <Marker
                                                key={index}
                                                position={{
                                                    lat: address.coordinate.lat,
                                                    lng: address.coordinate.lng
                                                }}
                                            />
                                        })}
                                    </GoogleMap>
                                }
                            </LoadScript>
                        </Grid>

                        <Grid item maxWidth={"650px"} flex={1}>
                            {radiusMailOriginal.template?
                                <Grid container gap={2} flexWrap={"nowrap"}>
                                    <Grid item flex={1}>
                                        <MDTypography mb={1} fontWeight={"bold"} color={"secondary"} sx={{fontSize: 18}}>Card Front</MDTypography>

                                        <MDCard borderRadiusSize={"md"} boxShadow={false} border={true}>
                                            <img alt={""} style={{width: "100%", height: "100%"}} src={radiusMailOriginal.template.sceneFrontFull}/>
                                        </MDCard>
                                    </Grid>

                                    <Grid item flex={1}>
                                        <MDTypography mb={1} fontWeight={"bold"} color={"secondary"} sx={{fontSize: 18}}>Card Rear</MDTypography>

                                        <MDCard borderRadiusSize={"md"} boxShadow={false} border={true}>
                                            <img alt={""} style={{width: "100%", height: "100%"}} src={radiusMailOriginal.template.sceneBackFull}/>
                                        </MDCard>
                                    </Grid>
                                </Grid>
                                :
                                null
                            }
                        </Grid>
                    </Grid>

                    <MDBox mt={2}>
                        <StyledDataGrid
                            rows={[...radiusMail.addresses?? [], totalsRow]}
                            columns={columns}
                            disableRowSelectionOnClick={true}
                            pagination={false}
                            hideFooter={true}
                            noMinWidth={true}
                            cellModesModel={cellModesModel}
                            onCellModesModelChange={(model) => setCellModesModel(model)}
                        />
                    </MDBox>
                </MDBox>
            </MDCard>
        </MDBox>
    </MDBox>
}

function RadiusMailHistoryPage() {
    const navigate = useNavigate()

    const queryClient = useQueryClient()
    const {setShowLoader} = useGlobal()

    const {getRadiusMailOrders, deleteRadiusMail} = RadiusMailService()

    let apiRef = useGridApiRef()

    const [shouldFetch] = useState<boolean>(false)

    const [expandedRowId, setExpandedRowId] = useState<GridRowId | null>(null)

    const [showDeleteOrderModal, setShowDeleteOrderModal] = useState<boolean>(false)
    const [radiusMailToDelete, setRadiusMailToDelete] = useState<RadiusMail | null>(null)

    const getRadiusMailOrdersQuery = useQuery({
        queryFn: () => {
            return getRadiusMailOrders()
        },
        queryKey: ["radiusMailOrders"],
        refetchInterval: shouldFetch? 5000 : false,
        refetchOnWindowFocus: false
    })

    const columns: GridColDef[] = [
        {
            headerName: "Name",
            field: "name",
            flex: 1,
            minWidth: 200,
            maxWidth: 300
        },
        {
            headerName: "Status",
            field: "status",
            minWidth: 350,
            flex: 1,
            renderCell: function (params) {
                let statusForTracker = ""

                let status = params.row.status

                if (status === OrderStatus.Draft || status === OrderStatus.EditsNeeded) {
                    statusForTracker = "Draft"
                }
                else if (status === OrderStatus.Paid || status === OrderStatus.PaymentNeeded || status === OrderStatus.PaymentNeeded || status === OrderStatus.ReadyForProduction || status === OrderStatus.InShipping) {
                    statusForTracker = "Processing"
                }
                else if (status === OrderStatus.Mailed) {
                    statusForTracker = "Mailed"
                }

                return <MDBox width={"100%"} maxWidth={"500px"}>
                    <StatusTracker statuses={["Draft", "Processing", "Mailed"]} currentStatus={statusForTracker}/>
                </MDBox>
            }
        },
        {
            headerName: "Recipients",
            field: "totalStructures",
            width: 180,
            renderCell: (params) => {
                return params.value ? formatNumber(params.value) : null
            },
        },
        {
            headerName: "Created Date",
            field: "createdDate",
            width: 250,
            valueFormatter: (params) => {
                return formatDate(params.value)
            },
        },
        {
            type: "actions",
            field: "actions",
            align: "right",
            flex: 1,
            minWidth: 200,
            getActions: (params: any) => {
                let buttons = []

                buttons.push(
                    <Tooltip title={"Details"}>
                        <MDBox display={"flex"} onClick={() => {
                            navigate(`/radius-mailing/order/${params.row.id}`)
                        }}>
                            <EditPencilIcon/>
                        </MDBox>
                    </Tooltip>
                )

                buttons.push(
                    <Tooltip title={"Delete Radius Mail"}>
                        <MDBox>
                            <MDIconButton display={"flex"} onClick={() => {
                                setRadiusMailToDelete(params.row)
                                setShowDeleteOrderModal(true)
                            }} disabled={!params.row.canDelete}>
                                <DeleteIcon color={"error"} />
                            </MDIconButton>
                        </MDBox>
                    </Tooltip>
                )

                return buttons
            }
        }
    ]

    // // If there's an order that is in status loading, continue refetching
    // useEffect(function () {
    //     if(getRadiusMailOrdersQuery.data?.payload.length){
    //         for (let order of getRadiusMailOrdersQuery.data.payload) {
    //             if (order.status === RadiusMailStatus.ProcessingOrder || order.status === RadiusMailStatus.ProcessingCount) {
    //                 setShouldFetch(true)
    //                 break
    //             }
    //         }
    //     }

    // }, [getRadiusMailOrdersQuery.data])

    function deleteOrder(){
        setShowLoader(true)

        deleteRadiusMail(radiusMailToDelete?.id!).then(() => {
            setShowDeleteOrderModal(false)

            setRadiusMailToDelete(null)

            queryClient.invalidateQueries({queryKey: ["radiusMailOrders"]})

            setShowLoader(false)
        })
    }

    return <MDCard>
        <MDBox p={3} pl={4} pb={4}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="flex-end" gap={1}>
                    <MDTypography fontWeight={"bold"} sx={{fontSize: "26px"}}>Radius Mail</MDTypography>
                    <Tooltip
                        componentsProps={{ tooltip: { sx: { width: "300px", maxWidth: "unset" } } }} 
                        title={"Purchase recipients around addresses and add your filters. Either add addresses one by one or upload a list."}
                    >
                        <Box>
                            <Info2Icon color={"light2"}/>
                        </Box>
                    </Tooltip>
                </Box>
                <MDButton onClick={() => navigate("/radius-mailing/order")}>
                    <PlusIcon style={{marginRight: 4}} /> New radius mail
                </MDButton>
            </Box>

            <MDBox height={"80vh"} mt={3}>
                <StyledDataGrid
                    apiRef={apiRef}
                    disableBorders={false}
                    backgroundColor={"white"}
                    loading={getRadiusMailOrdersQuery.isFetching}
                    rows={getRadiusMailOrdersQuery.data?.payload ?? []}
                    columns={columns}
                    disableRowSelectionOnClick={true}
                    getDetailPanelHeight={() => "auto"}
                    detailPanelExpandedRowIds={expandedRowId? [expandedRowId] : []}
                    onDetailPanelExpandedRowIdsChange={(ids) => {
                        if(!ids){
                            setExpandedRowId(null)
                            return
                        }

                        // Only allow the user to expand one row at a time since the google api only allows 1 map shown at a time
                        setExpandedRowId(ids[ids.length - 1])
                    }}
                    getDetailPanelContent={(row) => {
                        return <MasterDetail row={row.row as RadiusMail} apiRef={apiRef.current}/>
                    }}
                    pagination={false}
                    hideFooter={true}
                />
            </MDBox>
        </MDBox>

        <InfoModal
            show={showDeleteOrderModal}
            setShow={setShowDeleteOrderModal}
            headerText={"Are you sure you want to delete this recipient search?"}
            showConfirmButton={true}
            showCancelButton={true}
            cancelButtonOnClick={() => {
                setShowDeleteOrderModal(false)
            }}
            confirmButtonOnClick={() => {
                deleteOrder()
            }}
        />
    </MDCard>
}

export default RadiusMailHistoryPage