import { Coordinate } from 'models/coordinate';
import { IntegrationOrderStatus } from 'models/enums/IntegrationOrderStatus';
import { PostageTypes } from 'models/enums/PostageTypes';
import { ITemplate } from 'models/template';

export interface ISmartAutomation {
  id: number;
  templateId: number;
  name: string;
  product: string;
  postageType: PostageTypes;
  status: string;
  expectedMailDate: string;
  estimatedDeliveryDateMin: string;
  estimatedDeliveryDateMax: string;
  statusDate: string;
  paidDate: string;
  mailedDate: string;
  recipientCount: number;
  queueCount: number;
  processedCount: number;
  inProductionCount: number;
  mailedCount: number;
  orderType: string;
  friendlyId: string;
  updatedDate: string;
  sceneFrontThumb: string;
  sceneBackThumb: string;
  sceneFrontFull: string;
  sceneBackFull: string;
  envelopeFull: string;
  canDelete: boolean;
  platform: string;
  payload: string;
}

export interface ISmartAutomationsResponse {
  payload: { integrationOrders: ISmartAutomation[] };
  hasErrors: boolean;
}

export interface ISmartAutomationPayload {
  id?: number;
  name?: string;
  dataSourceId?: number;
  templateId?: number;
  payload: string;
  canEdit?: boolean;
  status?: IntegrationOrderStatus;
  paymentMethodId?: string;
}

interface IFieldSourceSpecific  { item1: string, item2: string }

export interface ISourceViewModel {
  filterOptions: IDataSourceFilter[]
  isFilterRequired?: boolean
  requiresConfirmation?: boolean
  // Klaviyo specific
  segmentDropdownValues?: IFieldSourceSpecific[]
  // Shopify specific
  storeDropdownValues?: IFieldSourceSpecific[],
}

export interface ISmartAutomationSchema {
  template: ITemplate;
  segmentId: string;
  profileDataProviderId: string;
  userCreatedFilters: { integrationOrderFilters: IUserCreatedFilter[] }[];
  multiUseCouponId?: number | null;
  couponListId?: number | null;
}

export interface IMoversSchema extends Omit<IMoverAutomation, 'templateId'> {
  template?: ITemplate;
}

// types regarding payload SmartAutomation Type

export interface IOperator {
  id: number;
  operator: string;
  showUi: boolean;
}

export interface IDataSourceFilter {
  id: number;
  name: string;
  shortName: string;
  valueType: 'String' | 'Number' | 'Date';
  operatorOptions: IOperator[];
}

export interface IUserCreatedFilter {
  dataSourceFilter?: IDataSourceFilter;
  operator?: IOperator;
  value: string;
}

export interface IPayloadSmartAutomationType {
  segmentId: number;
  postageType: PostageTypeEnum;
  userCreatedFilters: { integrationOrderFilters: IUserCreatedFilter[] }[];
}

// types regarding payload Movers Type

export interface IPolygonSearch {
  name: string;
  desiredQuantity?: number;
  availableQuantity?: number;
  status?: string;
  polygonCriteria: { coordinates: Coordinate[] };
}

export interface IAddressRadiusSearch {
  name: string;
  desiredQuantity?: number;
  availableQuantity?: number;
  status?: string;
  areaCriteria: { coordinate: Coordinate; radius: number };
}

export enum PostageTypeEnum {
  Standard = 1,
  FirstClass = 2,
}

export interface IMoverAutomation {
  polygonSearches: IPolygonSearch[];
  addressRadiusSearches: IAddressRadiusSearch[];
  filter: {
    ownerOrRenterFilter?: 0 | 1;
    propertyTypeFilter: 0 | 1 | 2;
    lookBackMonths?: 1 | 2 | 3 | 4 | 5 | 6;
  };
  sendingOptions: {
    maxNumberCardsSentPerMonth: number;
    postageType: PostageTypeEnum;
  };
  templateId: number;
  recipientSearchId: number;
  isCalculatingTargets?: boolean;
}
