import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CancelOutlined, Delete, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';

import Table from 'components/table';
import { formatNumber } from 'helpers/formatters';
import MDCard from 'material-ui/components/MDCard';
import MDButton from 'material-ui/components/MDButton';
import useDiscountCodesService from 'services/discount-codes';
import { defaultSearchParams } from 'components/table/table.utils';
import useOrderRecipientService from 'newStandard/src/services/orderRecipient';
import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import useRecipientsStepColumns from 'newStandard/src/hooks/useRecipiensStepColumns';
import { GridSearchParams, OrderRecipientsSearchParamsType } from 'models/gridSearchParams';
import SelectDiscountCodes, { ISelectedDiscountCode } from 'components/select-discount-codes';
import {
  IOrderRecipientErrors,
  IOrderRecipientInvalid,
  IOrderRecipientWarnings,
  OrderRecipientError,
  OrderRecipientInvalid,
  OrderRecipientWarning,
} from 'newStandard/src/services/orderRecipient/types';

import UpsertRecipientModal from './UpsertRecipientModal';
import AddRecipientsButton from './AddRecipientsButton';
import HelpGuide from './HelpGuide';

const alertOptions: { [key: string]: string } = {
  [OrderRecipientInvalid.DoNotMail]: 'Do Not Mail',
  [OrderRecipientInvalid.Duplicated]: 'Duplicated',
  [OrderRecipientWarning.PotentiallyUndeliverable]: 'Potentially Undeliverable',
  [OrderRecipientError.InvalidCharacter]: 'Invalid Character',
  [OrderRecipientError.MissingMailMergeField]: 'Missing Mail Merge Text',
  [OrderRecipientError.MissingAddressFields]: 'Missing Address',
  [OrderRecipientError.MissingNameFields]: 'Missing Name Fields',
  [OrderRecipientError.NonUsAddress]: 'Non US Address',
};

export default function RecipientsStep() {
  const params = useParams();
  const orderId = parseInt(params.id || '');
  const queryClient = useQueryClient();

  const { assignCouponToOrder } = useDiscountCodesService();
  const { getOrderRecipients, deleteOrderRecipientsFromAnOrder, getProcessingStatus } = useOrderRecipientService();
  const { template, setTemplate, setMaxStepAllowed, setSaveSteps, changeStep, step } = useWorkflowContext();
  const { columns, editRecipient, setEditRecipient } = useRecipientsStepColumns();

  const [couponError, setCouponError] = useState<string>('');
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [showNextModal, setShowNextModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [hasDiscountError, setHasDiscountError] = useState<boolean>(false);
  const [selectedDiscount, setSelectedDiscount] = useState<ISelectedDiscountCode>(template);
  const [searchParams, setSearchParams] = useState<OrderRecipientsSearchParamsType>({
    ...defaultSearchParams,
    pageSize: 10,
  });

  const { mutate: assignCoupon, isPending: isAssigning } = useMutation({
    mutationFn: assignCouponToOrder,
    onSuccess: (response) => (response?.hasErrors ? setCouponError(response.errors?.[0] ?? '') : changeStep(4)),
  });

  const { mutate: deleteRecipients, isPending: isDeleting } = useMutation({
    mutationFn: () => deleteOrderRecipientsFromAnOrder(orderId, selectedRows, searchParams, selectedAll),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orderRecipients', orderId] });
      setShowDeleteModal(false);
      setSelectedRows([]);
      if (selectedAll) {
        setSelectedAll(false);
        setSearchParams((prev) => {
          const aux: OrderRecipientsSearchParamsType = { ...defaultSearchParams, pageSize: 10 };
          Object.keys(prev).forEach((param) => {
            if (typeof prev[param] === 'boolean') aux[param] = false;
          });
          return aux;
        });
      }
    },
  });

  const processRecipients = () => {
    setIsProcessing(true);
    getProcess();
    if (totalFiltered === 1) {
      setSearchParams((prev) => {
        const aux: OrderRecipientsSearchParamsType = { ...defaultSearchParams, pageSize: 10 };
        Object.keys(prev).forEach((param) => {
          if (typeof prev[param] === 'boolean') aux[param] = false;
        });
        return aux;
      });
    }
  };

  const { data: process, refetch: getProcess } = useQuery({
    queryKey: ['ProcessingStatus', orderId],
    queryFn: () => getProcessingStatus(orderId),
    enabled: false,
  });

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ['orderRecipients', orderId, searchParams],
    queryFn: () => getOrderRecipients(orderId, searchParams),
  });

  const {
    recipients = [],
    usedColumns = [],
    totalRecords = 0,
    totalDeliverable = 0,
    totalFiltered = 0,
    recipientWarningDetails = {} as IOrderRecipientWarnings,
    recipientErrorDetails = {} as IOrderRecipientErrors,
    recipientInvalidDetails = {} as IOrderRecipientInvalid,
  } = data?.payload || {};
  const errorsCount = Object.values(recipientErrorDetails).reduce((vl, acc) => vl + acc, 0);
  const warningsCount = Object.values(recipientWarningDetails).reduce((vl, acc) => vl + acc, 0);
  const invalidsCount = Object.values(recipientInvalidDetails).reduce((vl, acc) => vl + acc, 0);

  useEffect(() => {
    if (step !== 3) return;
    setIsProcessing(true);
    getProcess();
  }, [step, getProcess]);

  useEffect(() => {
    if (!recipients.length) return;
    setMaxStepAllowed((prev) => (recipients.length && !errorsCount ? 4 : prev < 4 ? prev : 3));
  }, [recipients.length, errorsCount, setMaxStepAllowed]);

  useEffect(() => {
    setSaveSteps((prev) => ({ ...prev, 3: handleNextClick }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSaveSteps]);

  useEffect(() => {
    if (isProcessing) {
      const intervalId = setInterval(() => {
        getProcess();
      }, 8000);
      if (!process?.payload?.isProcessingOrderRecipients) {
        setIsProcessing(false);
        clearInterval(intervalId);
        queryClient.invalidateQueries({ queryKey: ['orderRecipients', orderId] });
      }
      return () => clearInterval(intervalId);
    } else if (process?.payload?.isProcessingOrderRecipients) setIsProcessing(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProcess, isProcessing, process?.payload?.isProcessingOrderRecipients]);

  const selectedCount = selectedRows?.length ?? 0;
  const tooltipDeleteTitle = selectedCount
    ? `Remove ${selectedAll ? 'All' : selectedCount} Recipient${selectedCount ? (selectedCount > 1 ? 's' : '') : 's'}`
    : 'Select an address to remove from the list';

  const handleNextClick = () => {
    if (!template.hasDiscount) return invalidsCount ? setShowNextModal(true) : changeStep(4);
    const { couponListId, multiUseCouponId } = selectedDiscount;
    if (!couponListId && !multiUseCouponId) return setHasDiscountError(true);
    if (invalidsCount) return setShowNextModal(true);
    setTemplate((prev) => ({ ...prev, multiUseCouponId, couponListId }));
    assignCoupon({ ...selectedDiscount, orderId });
  };

  const handleProceedClick = () => {
    setShowNextModal(false);
    if (!template.hasDiscount) return changeStep(4);
    const { couponListId, multiUseCouponId } = selectedDiscount;
    setTemplate((prev) => ({ ...prev, multiUseCouponId, couponListId }));
    assignCoupon({ ...selectedDiscount, orderId });
  };

  const renderChipLabel = (type: string, amount: number) => {
    const show = searchParams[type];
    const Icon = show ? VisibilityOffOutlined : VisibilityOutlined;
    return (
      <Box display={'flex'} gap={1} alignItems={'center'}>
        {alertOptions[type]}: {amount}
        <IconButton
          size="small"
          color="secondary"
          onClick={() => setSearchParams((prev) => ({ ...prev, [type]: !prev[type] }))}
        >
          <Icon sx={{ height: 18 }} />
        </IconButton>
      </Box>
    );
  };

  return (
    <MDCard sx={{ p: 2 }}>
      <Box mx={'auto'} maxWidth={1500} width={'100%'}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography mb={1}>
            Total Recipients: {totalRecords}
            <Box component={'span'} mx={1}>
              |
            </Box>
            Total Deliverable: {totalDeliverable}
          </Typography>
          <Typography fontSize={14} px={2} py={0.5} sx={{ backgroundColor: '#FFD994' }}>
            Fallback
          </Typography>
        </Box>
        <Grid container spacing={1} mb={1}>
          {!!warningsCount && (
            <Grid item xs={12} xl={6}>
              <Alert severity="warning" sx={{ py: 0, alignItems: 'center', borderRadius: '12px' }}>
                <Box display={'flex'} gap={1} alignItems={'center'} fontWeight={'bold'}>
                  Warnings:
                  {Object.entries(recipientWarningDetails).map(([warning, amount]) =>
                    amount ? <Chip label={renderChipLabel(warning, amount)} /> : null
                  )}
                </Box>
              </Alert>
            </Grid>
          )}
          {!!errorsCount && (
            <Grid item xs={12} xl={6}>
              <Alert severity="error" sx={{ py: 0, alignItems: 'center', borderRadius: '12px' }}>
                <Box display={'flex'} gap={1} alignItems={'center'} fontWeight={'bold'}>
                  Errors:
                  {Object.entries(recipientErrorDetails).map(([error, amount]) =>
                    amount ? <Chip label={renderChipLabel(error, amount)} /> : null
                  )}
                </Box>
              </Alert>
            </Grid>
          )}
          {!!invalidsCount && (
            <Grid item xs={12} xl={6}>
              <Alert
                icon={<CancelOutlined sx={{ color: 'gray' }} />}
                sx={{
                  py: 0,
                  alignItems: 'center',
                  borderRadius: '12px',
                  backgroundColor: '#e4e4e4',
                  color: '#404040',
                }}
              >
                <Box display={'flex'} gap={1} alignItems={'center'} fontWeight={'bold'}>
                  To be automatically removed:
                  {Object.entries(recipientInvalidDetails).map(([invalid, amount]) =>
                    amount ? <Chip label={renderChipLabel(invalid, amount)} /> : null
                  )}
                </Box>
              </Alert>
            </Grid>
          )}
        </Grid>
        <Table
          serverDriven
          disableFilter
          rows={recipients}
          columns={columns.filter(({ field }) => ['actions', ...usedColumns].includes(field.toLowerCase()))}
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          autosizeOptions={{ columns: ['firstName'], includeOutliers: true, outliersFactor: 1.5, expand: true }}
          isLoading={isLoading || isRefetching}
          totalRowCount={totalFiltered ?? 0}
          selectedRows={selectedRows}
          allSelected={selectedAll}
          setAllSelected={setSelectedAll}
          setSearchParams={setSearchParams as React.Dispatch<React.SetStateAction<GridSearchParams>>}
          setSelectedRows={setSelectedRows}
          renderToolbarFilterLeft={() => (
            <Grid item display={'flex'} alignItems={'center'} gap={0.5} flex={1}>
              <AddRecipientsButton onRecipientsSubmit={processRecipients} />
              <HelpGuide />
              <Box mr="auto" />
              {template.hasDiscount && (
                <SelectDiscountCodes
                  error={hasDiscountError}
                  setError={setHasDiscountError}
                  selectedDiscount={selectedDiscount}
                  setSelectedDiscount={setSelectedDiscount}
                  recipientsAmount={totalRecords}
                />
              )}
              <Tooltip title={tooltipDeleteTitle}>
                <Box ml="auto">
                  <IconButton color="error" disabled={!selectedCount} onClick={() => setShowDeleteModal(true)}>
                    <Delete />
                  </IconButton>
                </Box>
              </Tooltip>
            </Grid>
          )}
        />
        <Box display={'flex'} justifyContent={'space-between'} mt={2}>
          <MDButton color="light" onClick={() => changeStep(2)}>
            Back
          </MDButton>
          <MDButton
            disabled={!recipients.length || isAssigning || isRefetching || !!errorsCount}
            onClick={handleNextClick}
          >
            {isAssigning ? <CircularProgress size={18} color="inherit" /> : 'Next'}
          </MDButton>
        </Box>
      </Box>

      <UpsertRecipientModal
        key={editRecipient?.id}
        show={!!editRecipient}
        recipient={editRecipient}
        onClose={() => setEditRecipient(undefined)}
        onSubmit={() => processRecipients()}
      />

      <Dialog open={showDeleteModal} onClose={() => !isDeleting && setShowDeleteModal(false)} maxWidth="xs">
        <DialogContent>
          <DialogTitle p={0} whiteSpace={'pre-wrap'}>
            Are you sure you want to remove {selectedAll ? 'all' : formatNumber(selectedRows.length)} recipients from
            your list?
          </DialogTitle>
          <Box display="flex" gap={1} mt={2}>
            <MDButton circular fullWidth color="light" disabled={isDeleting} onClick={() => setShowDeleteModal(false)}>
              Cancel
            </MDButton>
            <MDButton circular fullWidth disabled={isDeleting} onClick={() => deleteRecipients()}>
              {isDeleting ? <CircularProgress size={18} color="inherit" /> : "Yes, I'm sure"}
            </MDButton>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={isProcessing} maxWidth={false}>
        <DialogContent>
          <DialogTitle>Your recipients are being processed and it might take a while. Please wait</DialogTitle>
        </DialogContent>
      </Dialog>
      <Dialog open={showNextModal}>
        <DialogContent>
          <DialogTitle sx={{ mb: 1 }}>Notice:</DialogTitle>
          <Typography fontSize={16}>
            Proceeding to the next page will automatically remove {totalRecords - totalDeliverable} recipients marked as
            duplicates or "Do Not Mail".
          </Typography>
          <Typography fontSize={16} fontWeight={'bold'}>
            {totalDeliverable} total recipients will be mailed.
          </Typography>
          <Box display="flex" gap={1} mt={2}>
            <MDButton circular fullWidth color="light" disabled={isDeleting} onClick={() => setShowNextModal(false)}>
              Cancel
            </MDButton>
            <MDButton circular fullWidth disabled={isDeleting} onClick={() => handleProceedClick()}>
              {isAssigning ? <CircularProgress size={18} color="inherit" /> : "I'm aware, proceed!"}
            </MDButton>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={!!couponError}
        autoHideDuration={6000}
        onClose={() => setCouponError('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setCouponError('')} severity="error" variant="standard" sx={{ py: 0 }}>
          {couponError}
        </Alert>
      </Snackbar>
    </MDCard>
  );
}
