import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ProfileEnrichmentAutoMergeSettings from "components/profile-enrichment-auto-merge-settings";

interface ProfileEnrichmentAutoMergeSettingsModalProps {
    show: boolean,
    onSave: Function,
    onCancel: Function
}

export default function ProfileEnrichmentAutoMergeSettingsModal(props: ProfileEnrichmentAutoMergeSettingsModalProps) {
    return <>
        <Dialog open={props.show} onClose={() => { props.onCancel() }} maxWidth={false}>
            <DialogContent sx={{ width: "1000px" }}>
                <DialogTitle>
                    Profile Enrichment Automatic Merge Settings
                </DialogTitle>
                <ProfileEnrichmentAutoMergeSettings onSave={props.onSave} onCancel={props.onCancel} />
            </DialogContent>
        </Dialog>
    </>
}