import { Grid, Link, Typography } from '@mui/material';

export default function Footer() {
  const footerItems = [
    { href: 'https://www.lettrlabs.com', label: 'LettrLabs' },
    { href: '/assets/PurchaseOrderGeneralTermsAndConditions.pdf', label: 'Terms & Conditions' },
    {
      href: 'https://lettrlabsappstorage.blob.core.windows.net/file-share/website-resources/LettrLabs_LLC_Privacy-Policy.pdf',
      label: 'Privacy Policy',
    },
    { href: 'https://www.lettrlabs.com/company/about-us', label: 'About Us' },
    { href: 'https://www.lettrlabs.com/company/blog', label: 'Blog' },
    { href: 'https://www.lettrlabs.com/hand-written-letter-direct-mail-products', label: 'Products' },
  ];

  return (
    <Grid container alignItems={'center'} justifyContent={'space-between'} pb={1}>
      <Grid item>
        <Typography fontSize={14} fontWeight={'regular'}>
          ©2023 by LettrLabs
        </Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems={'center'} gap={2}>
          {footerItems.map((item) => (
            <Grid item key={item.label}>
              <Link href={item.href} target="_blank" fontSize={14} fontWeight={'regular'}>
                {item.label}
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
