import MDTypography from "material-ui/components/MDTypography"
import {useState} from "react"
import {Dialog, DialogContent, Grid, Icon, IconButton, Tooltip} from "@mui/material"
import {
    GridColDef,
    useGridApiRef,
} from "@mui/x-data-grid-pro"
import InfoModal from "components/info-modal"
import {IProfileTag} from "models/profileTag"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import ProfileTagService from "services/profile-tag";
import TwoButtonWithOptionalFormFieldModal from "components/modal-with-two-buttons-and-textfield";
import StyledDataGrid from "components/styled-data-grid";
import { useGlobal } from "context/global-context"

interface ManageTagsModalProps {
    show: boolean
    setShow: Function
}

function ManageTagsModal({show, setShow}: ManageTagsModalProps) {
    const {setShowLoader} = useGlobal()
    const queryClient = useQueryClient()
    const {getProfileTags, deleteProfileTag, postProfileTag} = ProfileTagService()

    let apiRef = useGridApiRef()

    let [tagToBeDeleted, setTagToBeDeleted] = useState<IProfileTag | null>(null)
    let [tagToBeEdited, setTagToBeEdited] = useState<IProfileTag | null>(null)

    let [showNewTagModal, setShowNewTagModal] = useState<boolean>(false)
    let [showEditTagModal, setShowEditTagModal] = useState<boolean>(false)

    let [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false)

    const [newTagName, setNewTagName] = useState<string>("")

    const getProfileTagsQuery = useQuery({
        queryKey: ["profileTags"],
        queryFn: getProfileTags
    })

    const deleteTagMutation = useMutation({
        mutationFn: () => {
            return deleteProfileTag(tagToBeDeleted?.id!)
        },
        onMutate: function () {
            setShowLoader(true)
        },
        onSuccess: (data: any, variables: any, context: any) => {
            queryClient.invalidateQueries({queryKey: ["profileTags"]})
            setShow(false)
            setShowLoader(false)
        }
    })

    const createTagMutation = useMutation({
        mutationFn: () => {
            let newTag: IProfileTag = {
                id: 0,
                tagName: newTagName
            }

            return postProfileTag(newTag)
        },
        onMutate: function () {
            setShowLoader(true)
        },
        onSuccess: (data: any, variables: any, context: any) => {
            queryClient.invalidateQueries({queryKey: ["profileTags"]})
            setShowNewTagModal(false)
            setShowLoader(false)
        }
    })

    const updateTagMutation = useMutation({
        mutationFn: () => {
            return postProfileTag(tagToBeEdited!)
        },
        onMutate: function () {
            setShowLoader(true)
        },
        onSuccess: (data: any, variables: any, context: any) => {
            queryClient.invalidateQueries({queryKey: ["profileTags"]})
            setShowEditTagModal(false)
            setTagToBeEdited(null)
            setShowLoader(false)
        }
    })

    function openDeleteTagModal(tag: IProfileTag) {
        setTagToBeDeleted(tag)
        setShowDeleteConfirmationModal(true)
    }

    const columns: GridColDef[] = [
        {
            headerName: "Tag Name",
            field: "tagName",
            editable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Grid container alignItems={"center"}>
                        <Grid item>
                            <MDTypography>{params.value}</MDTypography>
                        </Grid>

                        <Grid item>
                            <Tooltip title={"Edit Tag"}>
                                <IconButton onClick={() => {
                                    setTagToBeEdited(params.row)
                                    setShowEditTagModal(true)
                                }}>
                                    <Icon fontSize="small">edit</Icon>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            type: "actions",
            field: "actions",
            align: "right",
            getActions: (params: any) => [
                <Tooltip title={"Delete Tag"}>
                    <IconButton onClick={() => openDeleteTagModal(params.row)}>
                        <Icon fontSize="medium">delete</Icon>
                    </IconButton>
                </Tooltip>,
            ],
        },
    ]

    return (
        <Dialog open={show} onClose={() => setShow(false)} maxWidth={false}>
            <DialogContent sx={{width: "700px"}}>
                <Grid
                    container
                    mb={"30px"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Grid item>
                        <MDTypography>Manage Tags</MDTypography>
                    </Grid>

                    <Grid item>
                        <Tooltip title={"New Tag"}>
                            <IconButton
                                sx={{padding: 0}}
                                color={"info"}
                                onClick={() => {
                                    setShowNewTagModal(true)
                                }}
                            >
                                <Icon fontSize="large" color={"primary"}>add_circle</Icon>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>

                <StyledDataGrid
                    apiRef={apiRef}
                    loading={getProfileTagsQuery.isLoading}
                    rows={getProfileTagsQuery.data ?? []}
                    columns={columns}
                    disableColumnMenu={true}
                    disableRowSelectionOnClick
                    hideFooter={true}
                />
            </DialogContent>

            <TwoButtonWithOptionalFormFieldModal
                open={showNewTagModal}
                onClose={() => setShowNewTagModal(false)}
                primaryButtonOnClick={() => {
                    createTagMutation.mutate({})
                }}
                secondaryButtonOnClick={() => {
                    setShowNewTagModal(false)
                }}
                primaryButtonText={"Add"}
                secondaryButtonText={"Cancel"}
                formFieldOnChange={(value) => {
                    setNewTagName(value)
                }}
                formFieldValue={newTagName}
                formFieldLabel={"Tag Name"}
                title={"Add search tag"}
            />

            <TwoButtonWithOptionalFormFieldModal
                open={showEditTagModal}
                onClose={() => setShowEditTagModal(false)}
                primaryButtonOnClick={() => {
                    updateTagMutation.mutate({})
                }}
                secondaryButtonOnClick={() => {
                    setShowEditTagModal(false)
                }}
                primaryButtonText={"Edit"}
                secondaryButtonText={"Cancel"}
                formFieldOnChange={(value) => {
                    setTagToBeEdited(prevState => {
                        return {...prevState, tagName: value}
                    })
                }}
                formFieldValue={tagToBeEdited?.tagName?? ""}
                formFieldLabel={"Tag Name"}
                title={"Edit tag name"}
            />

            <InfoModal
                show={showDeleteConfirmationModal}
                headerText={`Are you sure you want to delete ${tagToBeDeleted?.tagName}`}
                setShow={setShowDeleteConfirmationModal}
                showCancelButton={true}
                showConfirmButton={true}
                cancelButtonOnClick={() => {
                    setShowDeleteConfirmationModal(false)
                }}
                confirmButtonOnClick={() => {
                    deleteTagMutation.mutate({})
                }}
            />
        </Dialog>
    )
}

export default ManageTagsModal
