import useFetchWithMsal from 'hooks/useFetch';
import API_ENDPOINT, { IObjectResult } from 'services/endpoint';

import { OrderRecipientsSearchParamsType } from 'models/gridSearchParams';

import { IOrderRecipient, IOrderRecipientData } from './types';

const API_PATH = `${API_ENDPOINT}/orderRecipient`;
const API_PATH_V2 = `${API_ENDPOINT}/v2/order-recipients`;

export default function useOrderRecipientService() {
  const { execute } = useFetchWithMsal();

  const getOrderRecipients = (
    orderId: number,
    params: OrderRecipientsSearchParamsType
  ): Promise<IObjectResult<IOrderRecipientData>> => {
    let queryParams = '';
    Object.entries(params).forEach(([param, value]) => {
      if (value) {
        if (queryParams !== '') queryParams += '&';
        queryParams += `${param}=${typeof value === 'string' ? encodeURIComponent(value) : value}`;
      }
    });
    return execute({ endpoint: `${API_PATH_V2}/${orderId}?${queryParams}`, method: 'GET' });
  };

  const deleteOrderRecipientsFromAnOrder = (
    orderId: number,
    orderRecipientIds: number[],
    lastSearchParams: object,
    selectedAll: boolean
  ) => {
    return execute({
      endpoint: `${API_PATH_V2}/${orderId}:delete-selected`,
      method: 'DELETE',
      data: { orderRecipientIds, lastSearchParams, selectedAll },
    });
  };

  const getPostProcessStatus = (orderId: number) => {
    return execute({ endpoint: `${API_PATH}/GetPostProcessingStatus/${orderId}`, method: 'GET' });
  };

  const getProcessingStatus = (orderId: number): Promise<IObjectResult<{ isProcessingOrderRecipients: boolean }>> => {
    return execute({ endpoint: `${API_PATH_V2}/processing-status/${orderId}`, method: 'GET' });
  };

  const upsertSingleRecipient = (orderRecipient: IOrderRecipient) => {
    return execute({ endpoint: `${API_PATH_V2}:upsert-single/`, method: 'POST', data: orderRecipient });
  };

  const addFromMailingList = (orderId: number, recipientSearchId: (number | string)[]) => {
    return execute({
      endpoint: `${API_PATH_V2}/${orderId}:add-from-mailing-list/`,
      method: 'POST',
      data: recipientSearchId,
    });
  };

  const addFromAddressBook = (
    orderId: number,
    recipientIds: number[],
    lastSearchParams: object,
    selectedAll: boolean
  ) => {
    return execute({
      endpoint: `${API_PATH_V2}/${orderId}:add-from-address-book/`,
      method: 'POST',
      data: { recipientIds, lastSearchParams, selectedAll },
    });
  };

  return {
    getOrderRecipients,
    deleteOrderRecipientsFromAnOrder,
    getPostProcessStatus,
    upsertSingleRecipient,
    addFromMailingList,
    addFromAddressBook,
    getProcessingStatus,
  };
}
