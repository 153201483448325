export enum ConversionDataSourceType {
  CsvImport = 'CsvImport',
  ApiUpload = 'ApiUpload',
}

export interface IConversion {
  id: number;
  downloadLink: string;
  fileName: string;
  dataSourceType: ConversionDataSourceType;
  uploadDate: string;
  canDelete: boolean;
  status: string;
}
