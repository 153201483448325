import useFetchWithMsal from 'hooks/useFetch';
import API_ENDPOINT from 'services/endpoint';
import { IOrder } from './types';

export function useOrderService() {
  const { execute } = useFetchWithMsal();

  const API_PATH = `${API_ENDPOINT}/orders`;

  const getOrders = () => {
    return execute({ endpoint: API_PATH, method: 'GET' });
  };

  const getOrder = (id: number) => {
    return execute({ endpoint: `${API_PATH}/${id}`, method: 'GET' });
  };

  const postOrder = (order: IOrder) => {
    return execute({ endpoint: API_PATH, method: 'POST', data: order });
  };

  return { getOrder, getOrders, postOrder };
}
