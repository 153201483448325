import * as Yup from 'yup';

export const ReturnAddressValidation = Yup.object({
  name: Yup.string().required('Required'),
  firstName: Yup.string(),
  lastName: Yup.string(),
  toOrganization: Yup.string(),
  address1: Yup.string().required('Required'),
  address2: Yup.string(),
  city: Yup.string().required('Required'),
  zip: Yup.string()
    .length(5, 'Zip must be 5 digits long')
    .matches(/^\d{5}$/, 'Numbers only')
    .required('Required'),
  state: Yup.string().required('Required').nullable(),
  isDefault: Yup.boolean(),
});

const emailRegex = /[^\s@]+@[^\s@]+\.[^\s@]+/;

export const addressToReturnSchema = Yup.object({
  returnAddress1: Yup.string()
    .nullable()
    .required('Required')
    .test('no-email', 'Should not contain an email address', (value) => !emailRegex.test(value || '')),
  returnAddress2: Yup.string()
    .nullable()
    .test('no-email', 'Should not contain an email address', (value) => !emailRegex.test(value || '')),
  returnCity: Yup.string()
    .nullable()
    .required('Required')
    .test('no-email', 'Should not contain an email address', (value) => !emailRegex.test(value || '')),
  returnFirstName: Yup.string()
    .nullable()
    .test('no-email', 'Should not contain an email address', (value) => !emailRegex.test(value || '')),
  returnLastName: Yup.string()
    .nullable()
    .test('no-email', 'Should not contain an email address', (value) => !emailRegex.test(value || '')),
  returnOrganization: Yup.string()
    .nullable()
    .test('no-email', 'Should not contain an email address', (value) => !emailRegex.test(value || '')),
  returnState: Yup.string().nullable().required('Required'),
  returnZip: Yup.string()
    .nullable()
    .length(5, 'Zip must be 5 digits long')
    .matches(/^\d{5}$/, 'Numbers only')
    .required('Required'),
});
