import { IMailMergeField } from 'newStandard/src/features/Editor/utils/mailMergeOptions';
import { IArtifact } from '../../features/Editor/services/artifact/types';

export enum ProductOptions {
  HandwrittenPostCardA8 = 'Handwritten Post Card',
  LargeHandwrittenCardA8 = 'Handwritten Card + Envelope',
  HandwrittenBiFoldCard = 'Handwritten Bi-Fold Card',
  PrintedPostcard6x11 = 'Printed Postcard (6x11)',
  PrintedPostcard4x6 = 'Printed Postcard (4x6)',
  PrintedPostcard6x9 = 'Printed Postcard (6x9)',
}

export enum OrderStatus {
  Cancelled = 'Cancelled',
  Deleted = 'Deleted',
  Draft = 'Draft',
  EditsNeeded = 'Edits Needed',
  InProduction = 'In Production',
  InQa = 'In QA',
  InShipping = 'InShipping',
  Mailed = 'Mailed',
  Paid = 'Paid',
  PaymentNeeded = 'Payment Needed',
  ReadyForProduction = 'Ready For Production',
}

export enum ProductionSpeed {
  ExpeditedFaster = 'ExpeditedFaster',
  ExpeditedNormal = 'ExpeditedNormal',
  Normal = 'Normal',
}

export enum YesNo {
  Yes = "Yes",
  No = "No",
}

export interface IOrderTransaction {
  amount: string;
  description: string;
  id: number;
  type: string;
}

export interface IOrder {
  address1: string;
  address2: string;
  advancedFeaturesEnabled: string;
  // Balance - not in models/order.tsx
  campaignName: string;
  city: string;
  couponListId: number | null;
  // CreatedDate - not in models/order.tsx
  customerEmail: string;
  customerId: string;
  dataUrl: string;
  description: string;
  differentPayer: string;
  doubleSided: YesNo;
  // EndDate - not in models/order.tsx
  // estimatedDeliveryDateMax - not in models/order.tsx
  // estimatedDeliveryDateMin - not in models/order.tsx
  expectedMailDate: Date;
  executionDate: string;
  // expectedMailDate - in models/order.tsx
  firstName: string;
  font: string;
  fontSize: number;
  fontSizeReturnAddress: number;
  hasBeenProgrammaticallyFixed: boolean;
  hasBeenProgrammaticallyUpgraded: boolean;
  hasDiscount: boolean;
  holdUntilDate?: string;
  id: number;
  // IntegrationOrderId - not in models/order.tsx
  // IsActive - not in models/order.tsx
  isIntegrationOrderTemplate: boolean;
  isNew: boolean;
  isTemplate: boolean;
  lastName: string;
  // LegacyProduct - not in models/order.tsx
  mailedDate: Date;
  mailToMe: string;
  multiUseCouponId: number | null;
  name: string;
  noFrontLogo: string;
  // orderRecipientsProcessedCount - not in models/order.tsx
  // orderRecipientsProcessing - not in models/order.tsx
  // orderRecipientsProcessingCount - not in models/order.tsx
  orderArtifacts: IArtifact[];
  orderParameters: IMailMergeField[];
  orderStatus: OrderStatus;
  orderTransactions: IOrderTransaction[];
  // orderSubStatus - not in models/order.tsx
  orderVerified: boolean;
  orderVolume: number;
  // orientation - not in models/order.tsx
  paidDate: Date;
  payeeEmail: string;
  payeeFirstName: string;
  payeeLastName: string;
  postageType: string;
  product: ProductOptions;
  productionSpeed: ProductionSpeed;
  profileId: number;
  qrAngle?: number | null;
  qrCodeHeight?: number | null;
  qrCodeSide?: 'Rear' | 'Front' | null;
  qrCodeWidth?: number | null;
  qrCodeX?: number | null;
  qrCodeY?: number | null;
  qrUrl?: string | null;
  recipientsCsvBoxUrl: string;
  recipientsUploaded: boolean;
  returnAddress1: string;
  returnAddress2: string;
  returnCity: string;
  returnFirstName: string;
  returnLastName: string;
  returnOrganization: string;
  returnState: string;
  returnTitle: string;
  returnZip: string;
  // SalesPersonId - not in models/order.tsx
  // SceneStatus - not in models/order.tsx
  sceneUrl: string;
  state: string;
  submittedDate: Date;
  templateId: string | null;
  text: string;
  text2: string;
  trackingPhoneNumber: string;
  updatedDate: Date;
  useQr: boolean;
  zip: string;
}
