import { Stack, Typography } from '@mui/material';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { ProductOptions, YesNo } from 'newStandard/src/services/order/types';
import MDButton from 'material-ui/components/MDButton';

import { BlockNames, DefaultArtifacts } from '../../utils/sceneEnums';
import { useEditorContext } from '../../contexts/useEditorContext';
import { checkHasFrontLogo } from '../../utils/templateHelper';
import { createImage } from '../../utils/imageHelper';

export default function CardFormatting() {
  const { engine } = useEditorContext();
  const { template, setTemplate } = useWorkflowContext();

  const handleLogoToggle = () => {
    const show = template.noFrontLogo === YesNo.Yes;
    setTemplate((prev) => ({ ...prev, noFrontLogo: show ? YesNo.No : YesNo.Yes }));
    if(!engine) return;
    const frontLogos = engine.block.findByName(BlockNames.FrontLogo);
    if (!frontLogos.length && show) {
      const isPostCard = template.product === ProductOptions.HandwrittenPostCardA8;
      const newBlock = createImage(engine, {
        name: BlockNames.FrontLogo,
        height: isPostCard ? 0.800000011920929 : 0.8500000238418579,
        width: isPostCard ? 3 : 6.5,
        positionX: isPostCard ? 2.4000000953674316 : 0.5,
        positionY: isPostCard ? 0.15000000596046448 : 0.15000000596046448,
        parent: engine.scene.getPages()[0],
        uri: DefaultArtifacts.Front,
      });
      engine.block.setPlaceholderEnabled(newBlock, true);
      engine.block.setPlaceholderBehaviorEnabled(engine.block.getFill(newBlock), true);
      engine.block.setPlaceholderControlsButtonEnabled(newBlock, true);
      engine.block.setPlaceholderControlsOverlayEnabled(newBlock, true);
      engine.block.setContentFillMode(newBlock, 'Contain');
    } else {
      frontLogos.forEach((block) => {
        engine.block.setScopeEnabled(block, 'layer/visibility', true);
        engine.block.setVisible(block, show);
      });
    }

    if (template.product !== ProductOptions.LargeHandwrittenCardA8) return;
    engine.block.findByName(BlockNames.HandwrittenText).forEach((block) => {
      engine.block.setScopeEnabled(block, 'layer/move', true);
      engine.block.setScopeEnabled(block, 'layer/resize', true);
      engine.block.setPositionY(block, show ? 1 : 0.35);
      engine.block.setHeight(block, show ? 4.2 : 4.85);
      engine.block.setScopeEnabled(block, 'layer/move', false);
      engine.block.setScopeEnabled(block, 'layer/resize', false);
    });
  };

  if (!checkHasFrontLogo(template.product)) return null;
  return (
    <Stack spacing={1}>
      <Typography variant="body2" fontWeight={'bold'}>
        Card Logo
      </Typography>
      <MDButton fullWidth size={'medium'} color={'light'} onClick={handleLogoToggle}>
        {template.noFrontLogo === 'Yes' ? 'Add Logo' : 'Remove Logo'}
      </MDButton>
    </Stack>
  );
}
