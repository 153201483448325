import { Box, Paper, SxProps, Theme } from '@mui/material';
import CopyToClipboardButton from 'newStandard/src/components/CopyToClipboardButton';

interface TerminalCodeBlockProps {
  code: string;
  sx?: SxProps<Theme>;
}

const TerminalCodeBlock = ({ code, sx }: TerminalCodeBlockProps) => {
  return (
    <Paper variant="outlined" sx={sx}>
      <Box position={'relative'} p={1}>
        <pre style={{ whiteSpace: 'pre-wrap', fontSize: 12 }}>
          <code>{code}</code>
        </pre>
        <Box position={'absolute'} top={0} right={0}>
          <CopyToClipboardButton text={code} />
        </Box>
      </Box>
    </Paper>
  );
};

export default TerminalCodeBlock;
