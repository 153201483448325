import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { IconButton, Tooltip } from '@mui/material';
import { GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { CancelOutlined, CheckCircleOutline, Edit, Report, Warning } from '@mui/icons-material';

import { IOrderRecipient } from 'newStandard/src/services/orderRecipient/types';
import { OrderParameter } from 'models/orderParameter';
import OrderService from 'services/order';

import { useWorkflowContext } from '../contexts/useWorkflowContext';

export default function useRecipientsStepColumns() {
  const { template } = useWorkflowContext();
  const { getOrderParameters } = OrderService();
  const [editRecipient, setEditRecipient] = useState<IOrderRecipient>();
  const [mailMergeFields, setMailMergeFields] = useState<OrderParameter[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  const { data: { payload: orderParameters } = {} } = useQuery({
    queryKey: ['orderParameters', template.id],
    queryFn: () => getOrderParameters(template.id),
  });

  useEffect(() => {
    const formatTypeCamelCase = (type: string) => {
      return type
        .toLowerCase()
        .split(' ')
        .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
        .join('');
    };

    if (!orderParameters) return;
    const auxMailMergeFields = orderParameters.map((p) => ({
      ...p,
      parameterType: formatTypeCamelCase(p.parameterType.toLowerCase()),
    }));
    setMailMergeFields(auxMailMergeFields);
  }, [orderParameters]);

  useEffect(() => {
    const renderStatusTooltip = (row: IOrderRecipient) => {
      if (row.isDuplicated && row.doNotMail) return 'Duplicated; Do Not Mail';
      if (row.isDuplicated) return 'Duplicated';
      if (row.doNotMail) return 'Do Not Mail';
      if (row.errors && row.warnings) return `${row.errors}; ${row.warnings}`;
      if (row.errors) return row.errors;
      if (row.warnings) return row.warnings;
      return 'Good to go!';
    };

    const renderStatusIcon = (row: IOrderRecipient) => {
      if (row.isDuplicated || row.doNotMail) return <CancelOutlined color="disabled" />;
      if (row.errors?.length) return <Report color="error" />;
      if (row.warnings?.length) return <Warning color="warning" />;
      return <CheckCircleOutline color="success" />;
    };

    const formatAddress = (params: GridValueGetterParams) => {
      return `${params.value || '-'}${params.row.address2 ? `, ${params.row.address2}` : ''}`;
    };

    const formatValue = (field: string, value: string) => {
      if (value) return value;
      const fieldWithFallback = mailMergeFields.find((m) => m.parameterType === field && m.parameterText);
      if (fieldWithFallback) return fieldWithFallback.parameterText;
      return '-';
    };

    const formatCell = (params: GridCellParams) => {
      const hasFallback = mailMergeFields.find((m) => m.parameterType === params.field && m.parameterText);
      if (!params.value && hasFallback) return 'fallbackCell';
      return '';
    };

    const columnDef = (field: string, minWidth: number): Partial<GridColDef> => {
      return {
        valueFormatter: ({ value }) => formatValue(field, value),
        cellClassName: formatCell,
        headerAlign: 'center',
        align: 'center',
        minWidth,
      };
    };

    setColumns([
      {
        type: 'actions',
        field: 'actions',
        width: 86,
        getActions: (params) => [
          <Tooltip title={'Edit'}>
            <IconButton onClick={() => setEditRecipient(params.row)}>
              <Edit />
            </IconButton>
          </Tooltip>,
          <Tooltip title={renderStatusTooltip(params.row)}>{renderStatusIcon(params.row)}</Tooltip>,
        ],
      },
      { headerName: 'First Name', field: 'firstName', ...columnDef('firstName', 120) },
      { headerName: 'Last Name', field: 'lastName', ...columnDef('lastName', 150) },
      { headerName: 'Salutation', field: 'salutation', ...columnDef('salutation', 120) },
      { headerName: 'To Organization', field: 'toOrganization', ...columnDef('toOrganization', 150) },
      { headerName: 'Address', field: 'address1', ...columnDef('address1', 260), flex: 1, valueGetter: formatAddress },
      { headerName: 'City', field: 'city', ...columnDef('city', 120) },
      { headerName: 'State', field: 'state', ...columnDef('state', 60) },
      { headerName: 'Zip Code', field: 'zipCode', ...columnDef('zipCode', 86) },
      { headerName: 'Custom 1', field: 'custom1', ...columnDef('custom1', 120) },
      { headerName: 'Custom 2', field: 'custom2', ...columnDef('custom2', 120) },
      { headerName: 'Custom 3', field: 'custom3', ...columnDef('custom3', 120) },
      { headerName: 'Custom 4', field: 'custom4', ...columnDef('custom4', 120) },
      { headerName: 'Custom 5', field: 'custom5', ...columnDef('custom5', 120) },
      { headerName: 'Custom 6', field: 'custom6', ...columnDef('custom6', 120) },
      { headerName: 'Radius Pin Address', field: 'radiusPinAddress', ...columnDef('radiusPinAddress', 150) },
      { headerName: 'Radius Pin Street', field: 'radiusPinStreet', ...columnDef('radiusPinStreet', 150) },
    ]);
  }, [mailMergeFields]);

  return { columns, editRecipient, setEditRecipient };
}
